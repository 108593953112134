import { onBeforeUnmount } from 'vue';

import { Timeout } from '@/types/common';

type PollerInterval = number | ((calls: number) => number);
type PollerArgs = {
  poller: (args: number) => Promise<boolean | undefined>;
  interval?: PollerInterval;
  shouldComplete?: (
    pollerResponse?: boolean | undefined,
    calls?: number,
  ) => Promise<boolean> | boolean;
  onError?: (e: unknown) => void;
  onComplete?: () => void;
  skipUnmount?: boolean;
};

type TimeoutOrNull = Timeout | null;
type Poller = () => Timeout;

const usePolling = ({
  poller,
  interval = 10000,
  shouldComplete,
  onError,
  onComplete,
  skipUnmount = false,
}: PollerArgs): Poller => {
  let timeout: TimeoutOrNull;

  function clearAndReset(t: TimeoutOrNull) {
    if (t) clearTimeout(t);
    return null;
  }

  if (!skipUnmount) {
    onBeforeUnmount(() => {
      if (timeout) clearTimeout(timeout);
      timeout = null;
    });
  }

  let calls = 0;
  async function timeoutCallback() {
    calls += 1;
    try {
      const response = await poller(calls);
      const complete = !shouldComplete
        ? false
        : await shouldComplete(response, calls);
      timeout = clearAndReset(timeout);
      const newInterval =
        typeof interval === 'function' ? interval(calls) : interval;
      if (!complete) timeout = setTimeout(timeoutCallback, newInterval);
      else if (onComplete) onComplete();
    } catch (err) {
      timeout = clearAndReset(timeout);
      if (onError) {
        onError(err);
      }
    }
  }

  const firstInterval =
    typeof interval === 'function' ? interval(calls) : interval;
  return function () {
    timeout = setTimeout(timeoutCallback, firstInterval);
    return timeout;
  };
};

export default usePolling;
