<template>
  <BittsLoading
    v-if="dataShare && partnerOrg?.name"
    :is-loading="loading"
    :fast-transition="true"
  >
    <template #default>
      <Notification :item="item">
        <template #logo>
          <component
            :is="avatarComponent"
            size="medium"
            class="partner-avatar"
            :org="partnerOrg"
            icon-slot-bg-color="bg-gradient-to-b from-primary-background-weak to-white"
            :is-icon="isOffline"
            :is-own="false"
            :is-user-main-avatar="false"
            :show-initials="true"
          >
            <template #icon>
              <FontAwesomeIcon
                v-if="isOffline"
                :icon="['fas', 'ghost']"
                class="text-primary-accent h-10 w-10"
              />
            </template>
          </component>
        </template>
        <template #message> Data Shared </template>
        <template #description>
          {{ descriptionText }}
        </template>
        <template #action>
          <BittsButton
            :text="notifInfo.buttonText"
            type="neutral"
            variant="outline"
            size="x-small"
            @click="notifInfo.buttonAction"
          />
        </template>
        <template #icon>
          <FontAwesomeIcon
            :icon="['fas', 'chart-pie-simple']"
            :style="{ height: '12px', width: '12px', color: 'currentColor' }"
            class="text-neutral-accent ml-4"
          />
        </template>
      </Notification>
    </template>
  </BittsLoading>
</template>
<script>
import {
  BittsAvatar,
  BittsAvatarPip,
  BittsButton,
  BittsLoading,
} from '@crossbeam/bitts';

import { mapActions, mapState } from 'pinia';

import Notification from '@/components/notifications/Notification.vue';

import { GREENFIELD_SHARING } from '@/constants/data_shares';
import { GREENFIELD_TYPE, SINGLE_OVERLAPS_TYPE } from '@/constants/reports';
import {
  useBillingStore,
  useDataSharesStore,
  useNotificationsStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

export default {
  name: 'DataShareNotification',
  components: {
    BittsAvatar,
    BittsButton,
    BittsLoading,
    BittsAvatarPip,
    Notification,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ['action-clicked'],
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState(useBillingStore, ['isFreeTier']),
    ...mapState(useDataSharesStore, ['greenfieldSharesLookup']),
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    dataShare() {
      const dataShareId =
        this.item.object_id?.data_share_id || this.item.object_id;
      return this.getDataShareById(dataShareId);
    },
    avatarComponent() {
      return this.isOffline ? BittsAvatarPip : BittsAvatar;
    },
    isOffline() {
      return !!this.partnerOrg?.offline_partner;
    },
    descriptionText() {
      if (this.isOffline)
        return `Your offline partner ${this.partnerOrg.name} has a new ${this.partnerPopulation?.name} Population`;
      return `${this.partnerOrg.name} has ${this.notifInfo.descText} from their ${this.partnerPopulation?.name} Population with you`;
    },
    partnerOrg() {
      if (!this.dataShare) return {};
      return this.getPartnerOrgById(this.dataShare.partner_org_id);
    },
    partnerPopulation() {
      if (!this.dataShare) return {};
      return this.getPartnerPopulationById(
        this.dataShare.partner_population_id,
      );
    },
    notifInfo() {
      if (this.isGreenfieldNotif && this.isFreeTier) {
        return {
          buttonText: 'View Sharing',
          descText: 'shared Greenfield accounts',
          buttonAction: this.routeToPartnerPage,
        };
      }
      if (this.isGreenfieldNotif) {
        return {
          buttonText: 'Create Report',
          descText: 'shared Greenfield accounts',
          buttonAction: this.routeToCreateReport,
        };
      }
      return {
        buttonText: 'View Overlaps',
        descText: 'shared data',
        buttonAction: this.compareWithShare,
      };
    },
    isGreenfieldNotif() {
      return this.item?.data?.current_visibility === GREENFIELD_SHARING;
    },
    isPartnerOrgInLookup() {
      return !!this.greenfieldSharesLookup[this.dataShare?.partner_org_id];
    },
  },
  async created() {
    if (!this.dataShare) await this.refreshDataSharesStore();
    this.loading = false;
  },
  methods: {
    ...mapActions(usePopulationsStore, ['getPartnerPopulationById']),
    ...mapActions(useDataSharesStore, [
      'getDataShareById',
      'refreshDataSharesStore',
    ]),
    ...mapActions(useNotificationsStore, ['markRead']),
    async compareWithShare() {
      this.$emit('action-clicked', 'View Overlaps');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'account_mapping.create',
        query: {
          type: SINGLE_OVERLAPS_TYPE,
          partner_id: this.dataShare.partner_org_id,
        },
      });
    },
    async routeToCreateReport() {
      this.$emit('action-clicked', 'Create Report');
      await this.markRead({ id: this.item.id, isRead: true });
      const query = { type: GREENFIELD_TYPE };
      if (this.isPartnerOrgInLookup)
        query.partner_id = this.dataShare.partner_org_id;
      await this.$router.push({
        name: 'account_mapping.create',
        query,
      });
    },
    async routeToPartnerPage() {
      this.$emit('action-clicked', 'View Sharing');
      await this.markRead({ id: this.item.id, isRead: true });
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.dataShare.partner_org_id },
        query: { tab: 'shared_with_you', from: 'partners' },
      });
    },
  },
};
</script>
