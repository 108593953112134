<template>
  <BittsLoading :is-loading="loading">
    <BittsLayout :is-page="true">
      <template #content>
        <div v-if="pageError">
          <div class="text-neutral-text-strong text-lg text-center mb-8">
            <FontAwesomeIcon icon="exclamation-triangle" />
            We encountered an error.
          </div>
          <div class="text-sm text-neutral-text-weak text-center pb-24">
            <p>We hit a snag loading this record. Please try again.</p>
            <p>
              If this problem persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com"
                >support@crossbeam.com</a
              >
              or send us a chat using using the bubble at the bottom right of
              your screen.
            </p>
          </div>
        </div>
      </template>
    </BittsLayout>
  </BittsLoading>
</template>

<script setup>
/* This page is merely used to determine whether a record is ours or theirs
when coming from a slack integration. It routes back to the normal record page
after determining that. */

import { BittsLayout, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';

const props = defineProps({
  context: {
    type: String,
    required: true,
  },
});

useHead({ titleTemplate: '%s - Crossbeam' });

const loading = ref(true);
const pageError = ref(null);
const { currentOrg } = useAuth();
const contextArray = computed(() =>
  props.context ? window.atob(props.context).split(',') : [],
);

const router = useRouter();
onMounted(() => {
  if (props.context == null || contextArray.value.length < 6) {
    pageError.value = 'Missing Parameter';
    loading.value = false;
  } else {
    const [
      orgId,
      sourceId,
      sourcePrimaryKey,
      partnerOrgId,
      partnerSourceId,
      partnerSourcePrimaryKey,
    ] = contextArray.value;
    switch (currentOrg.value.id.toString()) {
      case orgId:
        router.replace({
          name: 'individual_record',
          params: {
            source_id: sourceId,
            source_primary_key: sourcePrimaryKey,
          },
        });
        break;
      case partnerOrgId:
        router.replace({
          name: 'individual_record',
          params: {
            source_id: partnerSourceId,
            source_primary_key: partnerSourcePrimaryKey,
          },
        });
        break;
      default:
        loading.value = false;
        pageError.value = 'Invalid parameters';
    }
  }
});
</script>
