<template>
  <div class="mt-16">
    <BittsTree
      :checked-keys="checkedKeys"
      :tree-data="tree"
      :disable-expansion="disabled"
      :initial-expanded-keys="expandedKeys"
      class="c-population-tree"
      @check="boxChecked"
    />
  </div>
</template>
<script>
import { BittsTree } from '@crossbeam/bitts';

import {
  concat,
  difference,
  groupBy,
  intersection,
  map,
  sortBy,
  uniq,
  xor,
} from 'lodash';
import { mapState } from 'pinia';

import {
  CUSTOMERS_STANDARD_TYPE,
  OPEN_OPPS_STANDARD_TYPE,
} from '@/constants/standard_populations';
import { usePartnersStore, usePopulationsStore } from '@/stores';

const PARTNER_DATA_OPEN_OPPS = 'open_opportunities';
const PARTNER_DATA_CUSTOMERS = 'customers';
const PARTNER_DATA_PROSPECTS = 'prospects';

const DEFAULT_STANDARD_POPS = [
  PARTNER_DATA_PROSPECTS,
  PARTNER_DATA_OPEN_OPPS,
  PARTNER_DATA_CUSTOMERS,
];

const TOOLTIPS = {
  salesforce: 'not being pushed to Salesforce',
  widget: 'not showing in the SF Widget',
  clari: 'not being pushed to Clari',
  hubspot: 'not being pushed to HubSpot',
  crossbeam_copilot: 'not showing in Crossbeam Copilot',
};

export default {
  name: 'PopulationSelectionTree',
  components: {
    BittsTree,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    integrationProfileSettings: {
      type: Object,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    settingType: {
      type: String,
      default: null,
    },
    integrationProfileCrm: {
      type: String,
      default: null,
    },
    expandedKeys: {
      type: Array,
      default: () => [],
    },
    customPartnerPopFilter: {
      type: Function,
      default: () => {
        return () => true;
      },
    },
    standardPopsHavePrecedence: {
      /**
       * When true, checking the parent level standard pops will send ALL standard pop data.
       * Thus, we should uncheck the parent box when a user unchecks a partner specific standard pop
       * When false, the partner specific standard pops have precedence, so unchecking one will override the standard setting
       * We then show an eye icon with a tooltip about the partner specific override
       **/
      type: Boolean,
      default: false,
    },
  },
  emits: ['save-changes'],
  data() {
    return {
      checkedKeys: [],
    };
  },
  computed: {
    ...mapState(usePopulationsStore, ['populations', 'partnerPopulations']),
    ...mapState(usePartnersStore, ['partnerOrgs', 'getPartnerOrgById']),
    filteredMyPops() {
      return this.populations.filter((pop) =>
        pop.base_schema.startsWith(this.integrationProfileCrm || ''),
      );
    },
    filteredPartnerPopulations() {
      return this.partnerPopulations.filter(
        (partner) =>
          this.customPartnerPopFilter(partner) &&
          !!this.getPartnerOrgById(partner.organization_id),
      );
    },
    groupedPartnerPopulations() {
      const sortedPartnerPops = sortBy(
        this.filteredPartnerPopulations,
        (pop) => {
          if (pop.standard_type)
            return DEFAULT_STANDARD_POPS.indexOf(pop.standard_type);
        },
      );
      return groupBy(sortedPartnerPops, 'organization_id');
    },
    orderedMyPops() {
      return sortBy(this.filteredMyPops, (pop) => {
        if (pop.standard_type)
          return DEFAULT_STANDARD_POPS.indexOf(pop.standard_type);
      });
    },
    populationsGroupedByStandardType() {
      return groupBy(this.filteredPartnerPopulations, 'standard_type');
    },
    standardPopulationIds() {
      return {
        prospects: this.getPopulationIdsByStandardType(PARTNER_DATA_PROSPECTS),
        open_opportunities: this.getPopulationIdsByStandardType(
          PARTNER_DATA_OPEN_OPPS,
        ),
        customers: this.getPopulationIdsByStandardType(PARTNER_DATA_CUSTOMERS),
      };
    },
    includedStandardPopIds() {
      return {
        prospects: this.isInCheckedKeys(
          this.standardPopulationIds[PARTNER_DATA_PROSPECTS],
        ),
        open_opportunities: this.isInCheckedKeys(
          this.standardPopulationIds[PARTNER_DATA_OPEN_OPPS],
        ),
        customers: this.isInCheckedKeys(
          this.standardPopulationIds[PARTNER_DATA_CUSTOMERS],
        ),
      };
    },
    includedStandardPopTypes() {
      return this.checkedKeys.filter((k) => DEFAULT_STANDARD_POPS.includes(k));
    },
    includedStandardPops() {
      return this.includedStandardPopTypes.map((popId) =>
        this.buildStandardPopSetting(popId, true),
      );
    },

    excludedStandardPops() {
      return DEFAULT_STANDARD_POPS.filter(
        (popType) => !this.includedStandardPopTypes.includes(popType),
      ).map((popId) => this.buildStandardPopSetting(popId, false));
    },
    filteredPartnerOrgs() {
      return this.partnerOrgs.filter((partnerOrg) =>
        Object.prototype.hasOwnProperty.call(
          this.groupedPartnerPopulations,
          partnerOrg.id,
        ),
      );
    },
    treeifiedPartnerOrgs() {
      return sortBy(
        this.filteredPartnerOrgs.map((partnerOrg) => {
          const eyeCount = this.groupedPartnerPopulations[partnerOrg.id].reduce(
            (acc, pop) => {
              if (!this.checkedKeys.includes(pop.id)) acc += 1;
              return acc;
            },
            0,
          );
          return {
            title: partnerOrg.name,
            checkable: true,
            scopedSlots: {
              title: eyeCount ? 'visibility-eyes' : 'title',
              icon: 'avatar',
            },
            showEye: false,
            tooltipText: this.partnerTooltipText(eyeCount),
            eyeNum: eyeCount,
            domain: partnerOrg.domain,
            key: partnerOrg.domain,
            children: map(
              this.groupedPartnerPopulations[partnerOrg.id],
              this.buildChildrenPopulation,
            ),
          };
        }),
        [(partner) => partner.title?.toLowerCase()],
      );
    },
    // if unchecked, number of that standardPops of that type checked
    // if checked, number of standardPops of that type unchecked
    standardPopOverrides() {
      return {
        prospects: this.calculateStandardOverrides(PARTNER_DATA_PROSPECTS),
        open_opportunities: this.calculateStandardOverrides(
          PARTNER_DATA_OPEN_OPPS,
        ),
        customers: this.calculateStandardOverrides(PARTNER_DATA_CUSTOMERS),
      };
    },
    treeifiedPartnerPopulations() {
      return this.groupedPartnerPopulations;
    },
    treeifiedMyPopulations() {
      return map(this.orderedMyPops, this.buildChildrenPopulation);
    },
    myDataChildren() {
      return this.treeifiedMyPopulations;
    },
    myPopIds() {
      return this.filteredMyPops.map((pop) => pop.id);
    },
    includedPops() {
      return this.checkedKeys.filter((k) => Number.isInteger(k));
    },
    includedMyPopsIds() {
      return this.includedPops.filter((popId) => this.myPopIds.includes(popId));
    },
    includedMyPops() {
      return this.includedMyPopsIds.map((popId) =>
        this.buildPopSetting(popId, true, false),
      );
    },
    partnerPopIds() {
      return this.filteredPartnerPopulations.map((pop) => pop.id);
    },
    includedPartnerPopIds() {
      return this.includedPops.filter(
        (popId) => !this.myPopIds.includes(popId),
      );
    },
    includedPartnerPops() {
      return this.includedPartnerPopIds.map((popId) =>
        this.buildPopSetting(popId, true, true),
      );
    },
    excludedMyPops() {
      return this.myPopIds
        .filter((popId) => !this.includedMyPopsIds.includes(popId))
        .map((popId) => this.buildPopSetting(popId, false, false));
    },
    excludedPartnerPops() {
      return this.partnerPopIds.reduce((acc, currentPopId) => {
        if (!this.includedPartnerPopIds.includes(currentPopId)) {
          acc.push(this.buildPopSetting(currentPopId, false, true));
        }
        return acc;
      }, []);
    },
    myDataEyesCount() {
      return this.excludedMyPops.length;
    },
    partnerDataEyesCount() {
      return this.excludedPartnerPops.length;
    },
    partnerDataChildren() {
      const standardPops = this.includedPartnerStandardPops.map(
        this.buildStandardPopulation,
      );
      return concat(
        [
          ...standardPops,
          {
            title: 'Specific Partners',
            class: 'control-title-wrapper',
            key: 'specific-partners',
            scopedSlots: {
              title: 'title',
            },
            checkable: false,
          },
        ],
        this.treeifiedPartnerOrgs,
      );
    },
    tree() {
      return [
        {
          title: 'My Data',
          key: 'my-data',
          checkable: false,
          class: 'control-title-wrapper',
          scopedSlots: {
            title: this.myDataEyesCount ? 'visibility-eyes' : 'title',
          },
          showEye: false,
          tooltipText: this.myTooltipText(this.myDataEyesCount),
          eyeNum: this.myDataEyesCount,
          children: this.myDataChildren,
        },
        {
          title: 'Partner Data',
          key: 'partner-data',
          class: 'control-title-wrapper',
          checkable: false,
          scopedSlots: {
            title: this.partnerDataEyesCount ? 'visibility-eyes' : 'title',
          },
          showEye: false,
          tooltipText: this.partnerTooltipText(this.partnerDataEyesCount),
          eyeNum: this.partnerDataEyesCount,
          children: this.partnerDataChildren,
        },
      ];
    },
    tooltipAlertType() {
      return TOOLTIPS[this.settingType] || 'not being pushed to Salesforce';
    },
    includedPartnerStandardPops() {
      return this.settingType === 'clari'
        ? [CUSTOMERS_STANDARD_TYPE, OPEN_OPPS_STANDARD_TYPE]
        : DEFAULT_STANDARD_POPS;
    },
  },
  watch: {
    isSaving() {
      if (this.isSaving) {
        this.sendPayload();
      }
    },
  },
  async created() {
    // 1. By default all keys are checked
    // First we get current org pops, then partner pops then add standard pops
    this.checkedKeys = this.filteredMyPops
      .map((pop) => pop.id)
      .concat(this.filteredPartnerPopulations.map((pop) => pop.id))
      .concat(this.includedPartnerStandardPops);

    const excludedStandardPops =
      this.integrationProfileSettings?.standard_population_settings?.reduce(
        (acc, current) => {
          if (!current.is_included) {
            acc.push(current.standard_type);
          }
          return acc;
        },
        [],
      ) || [];

    const impliedExcludedPopIds = this.filteredPartnerPopulations
      .filter((pop) => {
        return excludedStandardPops.includes(pop.standard_type);
      })
      .map((pop) => pop.id);

    const excludedPopIds =
      this.integrationProfileSettings?.population_settings
        ?.filter((setting) => !setting.is_included)
        .map((pop) => pop.population_id) || [];

    // 2. remove excluded standard pops, populations
    // that are explicitly removed or implicitly removed pops
    this.checkedKeys = this.checkedKeys.filter((k) => {
      return !(
        excludedPopIds.includes(k) ||
        excludedStandardPops.includes(k) ||
        impliedExcludedPopIds.includes(k)
      );
    });

    const includedPopIds =
      this.integrationProfileSettings?.population_settings
        ?.filter((setting) => setting.is_included)
        .map((setting) => setting.population_id) || [];

    // 3. add back in explicitly added keys
    this.checkedKeys = uniq([...this.checkedKeys, ...includedPopIds]);
  },
  methods: {
    buildChildrenPopulation(population) {
      const treeifiedPopulation = {};
      treeifiedPopulation.title = population.name;
      treeifiedPopulation.scopedSlots = { title: 'title' };
      treeifiedPopulation.key = population.id;
      return treeifiedPopulation;
    },
    buildStandardPopulation(standardType) {
      const titles = {
        prospects: 'Prospects',
        open_opportunities: 'Open Opportunities',
        customers: 'Customers',
      };
      return {
        title: titles[standardType],
        key: standardType,
        scopedSlots: {
          title: this.standardPopOverrides[standardType]
            ? 'visibility-eyes'
            : 'title',
        },
        showEye: !this.checkedKeys.includes(standardType),
        eyeNum: this.standardPopOverrides[standardType],
        tooltipText: this.standardTooltipText(standardType),
      };
    },
    checkAssociatedStandardPops(standardPopMap, standardPopKey) {
      const newPops = standardPopMap[standardPopKey].filter(
        (k) => !this.checkedKeys.includes(k),
      );
      this.checkedKeys = [...newPops, ...this.checkedKeys, standardPopKey];
    },
    uncheckAssociatedStandardPops(standardPopMap, standardPopKey) {
      this.checkedKeys = this.checkedKeys.filter((k) => {
        const isInStandardPop = standardPopMap[standardPopKey].includes(k);
        if (isInStandardPop) return false;
        if (k === standardPopKey) return false;
        return true;
      });
    },
    boxChecked(payload) {
      /* Remove partner-level checkbox from checkedKeys array, because
        we're calculating this based on children */
      payload = payload.filter(
        (k) => Number.isInteger(k) || DEFAULT_STANDARD_POPS.includes(k),
      );

      const isAddingKeys = payload.length > this.checkedKeys.length;
      const differentKeys = xor(this.checkedKeys, payload);
      const unselectedKeys = difference(this.checkedKeys, payload);

      const firstChangedKey = differentKeys[0];
      const isModifyingStandardPops =
        differentKeys.length === 1 &&
        DEFAULT_STANDARD_POPS.includes(firstChangedKey);

      // when parent standard pops have precedence,
      // and a user unchecks a partner specific standard pop,
      // uncheck the parent standard pop
      // if we didn't, we would deceptively say that the partner specific settings have precedence!
      if (this.standardPopsHavePrecedence) {
        for (const partnerStandardPop of DEFAULT_STANDARD_POPS) {
          if (
            !isModifyingStandardPops &&
            this.includedStandardPopTypes.includes(partnerStandardPop) &&
            intersection(
              unselectedKeys,
              this.getPopulationIdsByStandardType(partnerStandardPop),
            ).length
          ) {
            payload = payload.filter((k) => k !== partnerStandardPop);
          }
        }
      }

      if (isModifyingStandardPops) {
        const standardPopsMap = {
          open_opportunities:
            this.standardPopulationIds[PARTNER_DATA_OPEN_OPPS],
          customers: this.standardPopulationIds[PARTNER_DATA_CUSTOMERS],
          prospects: this.standardPopulationIds[PARTNER_DATA_PROSPECTS],
        };

        isAddingKeys
          ? this.checkAssociatedStandardPops(
              standardPopsMap,
              firstChangedKey,
              differentKeys,
            )
          : this.uncheckAssociatedStandardPops(
              standardPopsMap,
              firstChangedKey,
            );
      } else {
        this.checkedKeys = payload;
      }
    },
    sendPayload() {
      const payload = this.savePopulationSettings();
      this.$emit('save-changes', payload);
    },
    buildPopSetting(populationId, isIncluded, isPartnerPopulation) {
      return {
        population_id: populationId,
        is_included: isIncluded,
        is_partner_population: isPartnerPopulation,
      };
    },
    buildStandardPopSetting(standardType, isIncluded) {
      return {
        standard_type: standardType,
        is_included: isIncluded,
      };
    },
    isInCheckedKeys(collection) {
      return this.checkedKeys.reduce((acc, k) => {
        if (collection.includes(k)) acc.push(k);
        return acc;
      }, []);
    },
    calculateStandardOverrides(standardType) {
      // if standard type is included, find how many standard pops are not included
      // else find how many standard type pops are included
      return this.checkedKeys.includes(standardType)
        ? this.standardPopulationIds[standardType].length -
            this.includedStandardPopIds[standardType].length
        : this.includedStandardPopIds[standardType].length;
    },
    getPopulationIdsByStandardType(standardType) {
      return this.populationsGroupedByStandardType[standardType]
        ? this.populationsGroupedByStandardType[standardType].map(
            (pop) => pop.id,
          )
        : [];
    },
    standardTooltipText(standardType) {
      return `${this.standardPopOverrides[standardType]} partner ${
        this.standardPopOverrides[standardType] === 1
          ? 'Population is'
          : 'Populations are'
      } overriding this setting`;
    },
    partnerTooltipText(count) {
      return `${count} of your partner Populations ${
        count === 1 ? ' is' : ' are'
      } ${this.tooltipAlertType}`;
    },
    myTooltipText(count) {
      return `${count} of your Populations ${
        count === 1 ? ' is' : ' are'
      } ${this.tooltipAlertType}`;
    },
    savePopulationSettings() {
      return {
        population_settings: this.includedMyPops
          .concat(this.includedPartnerPops)
          .concat(this.excludedMyPops)
          .concat(this.excludedPartnerPops)
          .map((val) => ({ ...val, config_type: this.settingType })),
        standard_population_settings: this.includedStandardPops
          .concat(this.excludedStandardPops)
          .map((val) => ({ ...val, config_type: this.settingType })),
      };
    },
  },
};
</script>
<style lang="pcss">
.c-population-tree .control-title-wrapper {
  > .ant-tree-node-content-wrapper {
    @apply text-neutral-500 text-sm leading-6;
  }
}
</style>
