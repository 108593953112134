<template>
  <template v-if="partnered">
    <div class="c-timeline-content">
      <div class="flex">
        <BittsAvatarPip
          v-bind="imageSrcProp(actingUser)"
          :user="actingUser"
          :org="actingOrg"
          :show-initials="true"
          :include-hover-text="true"
          :is-own="isOwnOrg(actingOrg)"
          class="mr-16"
        />
        <div class="flex-1">
          <div class="flex items-center justify-between">
            <p class="text-neutral-600 leading-4 text-sm flex-1">
              Posted to
              <router-link
                :to="{ name: 'sunset-threads' }"
                class="text-brand-blue"
              >
                {{ threadName }}
              </router-link>
            </p>
            <BittsTooltip
              :show-tooltip="!timelineInfo.privateNote ? false : null"
            >
              <div
                class="text-neutral-text flex items-center text-xs mr-8 cursor-default"
                data-testid="thread-action"
              >
                <FontAwesomeIcon
                  :icon="
                    timelineInfo.privateNote
                      ? ['far', 'lock']
                      : ['far', 'inbox']
                  "
                  class="w-16 h-16 text-neutral-accent mr-4"
                />
                {{ actionText }}
              </div>
              <template #title> Only visible to your team </template>
            </BittsTooltip>
            <BittsAvatar
              v-bind="imageSrcProp(actingUser)"
              :include-hover-text="true"
              :user="actingUser"
              :is-own="isOwnOrg(actingOrg)"
              :show-initials="true"
              size="x-small"
              class="mr-4"
            />
            <BittsAvatar
              v-bind="imageSrcProp(receivingUser)"
              :include-hover-text="true"
              :user="receivingUser"
              :is-own="isOwnOrg(receivingOrg)"
              :show-initials="true"
              size="x-small"
            />
          </div>
          <div class="c-timeline-content__main-content">
            <p v-if="hasActionText" :class="actionTextStyle">
              {{ contentText }}
            </p>
            <TimelineActivitySimpleThreadConvertMarkdown
              v-if="hasMarkupText"
              :content="contentText"
            />
            <ThreadEventChangedOwners
              v-if="eventType === 'thread_owner_changed'"
              :changed-users="changedEventUsers"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { BittsAvatar, BittsAvatarPip, BittsTooltip } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import { DIRECTIONALITY_OPTIONS, REVOKED_USER } from '@/composables/useThreads';
import { usePartnersStore } from '@/stores';

import ThreadEventChangedOwners from './ThreadEventChangedOwners.vue';
import TimelineActivitySimpleThreadConvertMarkdown from './TimelineActivitySimpleThreadConvertMarkdown.vue';

export default {
  name: 'TimelineEventSimpleThread',
  components: {
    ThreadEventChangedOwners,
    BittsAvatarPip,
    BittsAvatar,
    BittsTooltip,
    TimelineActivitySimpleThreadConvertMarkdown,
  },
  props: {
    fullThreadInfo: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    allUsers: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    kafkaEvent: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  data() {
    return {
      thread: {},
      partnered: false,
      timelineId: null,
    };
  },
  computed: {
    ...mapState(usePartnersStore, ['partnerOrgs', 'getPartnerOrgByUuid']),
    timelineInfo() {
      return {
        type: this.eventType,
        directionality: this.thread.directionality,
        privateNote: this.kafkaEvent.data.is_private,
      };
    },
    actionText() {
      if (this.timelineInfo.privateNote) return 'PRIVATE NOTE';
      return {
        discussion: 'DISCUSSION',
        request: 'REQUEST',
        assist: 'ASSIST',
      }[this.timelineInfo.directionality];
    },
    eventType() {
      return this.kafkaEvent.type;
    },
    actingOrgId() {
      return this.kafkaEvent.data.user_organization_uuid;
    },
    isPrivate() {
      return this.kafkaEvent.data.is_private;
    },
    actingOrg() {
      return this.getOrganization(this.actingOrgId);
    },
    receivingOrg() {
      const {
        partner_organization_uuid: partnerOrgUuid,
        organization_uuid: orgUuid,
      } = this.kafkaEvent;
      const receivingOrgUuid =
        this.actingOrgId === orgUuid ? partnerOrgUuid : orgUuid;
      return this.getOrganization(receivingOrgUuid);
    },
    otherOrgInThread() {
      return this.getPartnerOrgByUuid(
        this.kafkaEvent.partner_organization_uuid,
      );
    },
    receivingUser() {
      const actingId = this.kafkaEvent.data.user_id;
      const { owner_id: ownerId, partner_owner_id: partnerOwnerId } =
        this.thread;
      const receivingOrgId = ownerId === actingId ? partnerOwnerId : ownerId;
      return this.allUsers[receivingOrgId] || REVOKED_USER;
    },
    partnerDetailsLink() {
      return {
        name: 'partner_details',
        params: { partner_org_id: this.otherOrgInThread.id },
      };
    },
    actingUser() {
      return this.allUsers[this.kafkaEvent.data.user_id] || REVOKED_USER;
    },
    contentText() {
      if (
        this.eventType === 'thread_message' ||
        this.eventType === 'thread_created'
      ) {
        return this.fullThreadInfo.message.content;
      }
      if (this.eventType === 'thread_closed') {
        return `${this.actingUser.first_name} ${this.actingUser.last_name} closed this Thread`;
      }
      if (this.eventType === 'thread_reopened') {
        return `${this.actingUser.first_name} ${this.actingUser.last_name} re-opened this Thread`;
      }
      return '';
    },
    actionTextStyle() {
      const actionTypes = {
        thread_closed: true,
        thread_owner_changed: true,
        thread_reopened: true,
      };
      return actionTypes[this.eventType] && 'text-neutral-500 italic';
    },
    hasActionText() {
      return (
        this.eventType === 'thread_closed' ||
        this.eventType === 'thread_reopened'
      );
    },
    hasMarkupText() {
      return (
        this.eventType === 'thread_message' ||
        this.eventType === 'thread_created'
      );
    },
    threadName() {
      return this.thread.title ?? this.defaultTitle;
    },
    defaultTitle() {
      return DIRECTIONALITY_OPTIONS.find(
        (option) => option.value === this.thread.directionality,
      ).defaultTitle({
        sendingOrg: this.actingOrg,
        receivingOrg: this.receivingOrg,
        currentOrg: this.currentOrg,
      });
    },
    changedEventUsers() {
      if (this.eventType !== 'thread_owner_changed') {
        return;
      }
      const {
        owner_organization_uuid: organizationUuid,
        user_id: actingUserId,
        prev_owner_id: previousOwnerId,
        new_owner_id: newOwnerId,
      } = this.kafkaEvent.data;
      const eventData = {
        organization: this.getOrganization(organizationUuid),
        actingUser: this.allUsers[actingUserId] || REVOKED_USER,
        previousOwner: this.allUsers[previousOwnerId] || REVOKED_USER,
        newOwner: this.allUsers[newOwnerId] || REVOKED_USER,
      };
      return eventData;
    },
  },
  created() {
    if (!this.fullThreadInfo) return;
    const { thread, timeline } = this.fullThreadInfo;
    if (timeline.event_type === 'message_deleted') return;
    this.thread = thread;
    this.timelineId = timeline.id;
    this.partnered = this.arePartners(
      this.kafkaEvent.partner_organization_uuid,
    );
  },
  methods: {
    isOwnOrg(org) {
      return org.id === this.currentOrg.id;
    },
    userName(user) {
      return user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.first_name;
    },
    imageSrcProp(user) {
      if (user.email === 'revoked@crossbeam.com') {
        return {
          imageSource: user.logo_url,
        };
      }
    },
    arePartners(puuid) {
      const partnerIdx = this.partnerOrgs.findIndex(
        (partner) => partner.uuid === puuid,
      );
      return partnerIdx > -1;
    },
    getOrganization(organizationId) {
      return organizationId !== this.currentOrg.uuid
        ? this.getPartnerOrgByUuid(organizationId)
        : this.currentOrg;
    },
  },
};
</script>

<style lang="pcss">
.c-timeline-content {
  @apply rounded-lg p-12 mr-16 mt-8 bg-neutral-100;

  &__main-content {
    @apply text-neutral-800 leading-6;
  }
}
</style>
