<template>
  <BittsLoading :is-loading="!allStoresReady">
    <BittsCard :no-padding="false" class="c-connector-upgrade-card">
      <div class="card-content">
        <div class="flex justify-between items-center">
          <h3 class="title"> Connector </h3>
          <BittsLink
            :show-arrow="true"
            text="View all features"
            url="https://www.crossbeam.com/pricing/"
          />
        </div>
        <p class="description">
          {{ V4_BILLING_COPY.connectorUpgrade.upgradeCard.description }}
        </p>
      </div>
      <ul class="feature-list">
        <div class="c-connector-upgrade-card__cta">
          <FontAwesomeIcon
            :icon="['fad', 'magic-wand-sparkles']"
            class="c-connector-upgrade-card__cta__icon"
          />
          {{ ctaDescription }}
        </div>
        <li
          v-for="(feature, i) in V4_BILLING_COPY.connectorUpgrade.upgradeCard
            .features"
          :key="`feature__${i}`"
          class="feature-list__item"
        >
          <FontAwesomeIcon
            :icon="['far', 'check']"
            class="feature-list__check"
          />
          <component
            :is="feature.tooltip ? BittsTooltip : 'span'"
            placement="right"
          >
            <span
              :class="{
                'cursor-pointer': feature.tooltip,
              }"
              class="feature-list__text"
              v-html="
                injectStrings(feature.text, {
                  values: [feature.underline],
                  underline: true,
                })
              "
            />
            <template #title>
              {{ feature.tooltip }}
            </template>
          </component>
        </li>
      </ul>
    </BittsCard>
  </BittsLoading>
</template>
<script setup>
import {
  BittsCard,
  BittsLink,
  BittsLoading,
  BittsTooltip,
} from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRoute } from 'vue-router';

import useBilling from '@/composables/useBilling';
import { BILLING_CTAS } from '@/constants/analytics';
import { V4_BILLING_COPY } from '@/constants/billing';
import { injectStrings } from '@/utils';

const { allStoresReady } = useBilling();

const route = useRoute();

const ctaMessages = V4_BILLING_COPY.connectorUpgrade.upgradeCard.cta_messages;
const ctaDescription = computed(() => {
  let cta = BILLING_CTAS.BILLING;
  if (route.query?.cta && route.query?.cta !== BILLING_CTAS.REPORTS)
    cta = route.query.cta;
  if (route.query?.cta_2) cta = route.query.cta_2;

  cta = decodeURI(cta);
  return ctaMessages[cta] || ctaMessages[BILLING_CTAS.BILLING];
});
</script>
<style lang="pcss">
.c-connector-upgrade-card {
  @apply p-24 mb-24;
  .title {
    @apply text-neutral-text-strong font-bold text-m mb-4;
  }
  .description {
    @apply text-neutral-text max-w-[90%];
  }
  .feature-list {
    @apply grid gap-8 mt-24 items-start;

    .feature-list__item {
      @apply flex items-center gap-4 justify-start;
    }
    .feature-list__check {
      @apply text-primary-background-medium w-16 h-16 mr-2;
    }
    .feature-list__text {
      @apply text-neutral-text;
    }
  }
  .c-connector-upgrade-card__cta {
    background: var(
      --background-upsell-gradient-weak,
      linear-gradient(
        92deg,
        theme(colors.upsell.background-weak) 0%,
        theme(colors.upsell.background-weak / 0.2) 100%
      )
    );
    @apply mb-16 p-8 rounded-8 text-upsell-text text-sm;
    .c-connector-upgrade-card__cta__icon {
      @apply text-upsell-accent w-16 h-16 mr-2 ml-6;
    }
  }
}
</style>
