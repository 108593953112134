import { MDM_PROPERTIES, MDM_TYPES } from './mdm';

export const ATTRIBUTION_TYPES = {
  influenced: 'influenced',
  sourced: 'sourced',
};

export function ATTRIBUTION_COLORS(type: string) {
  return {
    [ATTRIBUTION_TYPES.influenced]: 'info',
    [ATTRIBUTION_TYPES.sourced]: 'upsell',
  }[type ? type.toLowerCase() : ''];
}

export const ATTRIBUTION_PAGE_LIMIT = 20;

export const ATTRIBUTION_AMOUNT_FILTER_MAX = 1000000;

const TOTAL_SOURCED = 'total_sourced';
const TOTAL_INFLUENCED = 'total_influenced';
const TOTAL_ATTRIBUTED = 'total_attributed';
const TOTAL_UNATTRIBUTED = 'total_unattributed';
const WIN_RATE_WITH_PARTNERS = 'win_rate_with_partners';
const WIN_RATE_WITHOUT_PARTNERS = 'win_rate_without_partners';
const DEAL_SIZE_WITH_PARTNERS = 'deal_size_with_partners';
const DEAL_SIZE_WITHOUT_PARTNERS = 'deal_size_without_partners';
const TIME_TO_CLOSE_WITH_PARTNERS = 'time_to_close_with_partners';
const TIME_TO_CLOSE_WITHOUT_PARTNERS = 'time_to_close_without_partners';
const TOTAL_REVENUE_WITH_PARTNERS = 'total_revenue_with_partners';
const TOTAL_REVENUE_WITHOUT_PARTNERS = 'total_revenue_without_partners';
const TOTAL_REVENUE_WITH_PARTNER = 'total_revenue_with_partner';
const WIN_RATE_WITH_PARTNER = 'win_rate_with_partner';
const DEAL_SIZE_WITH_PARTNER = 'deal_size_with_partner';
const TIME_TO_CLOSE_WITH_PARTNER = 'time_to_close_with_partner';
const TOTAL_ATTRIBUTED_COUNT = 'total_attributed_count';
const TOTAL_UNATTRIBUTED_COUNT = 'total_unattributed_count';

export const ATTRIBUTION_METRIC_TYPES = {
  TOTAL_SOURCED,
  TOTAL_INFLUENCED,
  TOTAL_UNATTRIBUTED,
  TOTAL_ATTRIBUTED,
  WIN_RATE_WITH_PARTNERS,
  WIN_RATE_WITHOUT_PARTNERS,
  DEAL_SIZE_WITH_PARTNERS,
  DEAL_SIZE_WITHOUT_PARTNERS,
  TIME_TO_CLOSE_WITH_PARTNERS,
  TIME_TO_CLOSE_WITHOUT_PARTNERS,
  TOTAL_REVENUE_WITH_PARTNERS,
  TOTAL_REVENUE_WITHOUT_PARTNERS,
  TOTAL_REVENUE_WITH_PARTNER,
  WIN_RATE_WITH_PARTNER,
  DEAL_SIZE_WITH_PARTNER,
  TIME_TO_CLOSE_WITH_PARTNER,
  TOTAL_ATTRIBUTED_COUNT,
  TOTAL_UNATTRIBUTED_COUNT,
};

export const ATTRIBUTION_SETTINGS = {
  [TOTAL_SOURCED]: {
    label: 'Total Sourced',
    value: 'total_sourced',
  },
  [TOTAL_INFLUENCED]: {
    label: 'Total Influenced',
    value: 'total_influenced',
  },
  [TOTAL_UNATTRIBUTED]: {
    label: 'Total Unattributed',
    value: 'total_unattributed',
  },
  [WIN_RATE_WITH_PARTNERS]: {
    label: 'Win Rate with Partners',
    dropDownLabel: 'Win Rate',
    value: 'win_rate_with_partners',
  },
  [WIN_RATE_WITHOUT_PARTNERS]: {
    label: 'Win Rate Without Partners',
    value: 'win_rate_without_partners',
  },
  [DEAL_SIZE_WITH_PARTNERS]: {
    label: 'Opp Size with Partners',
    dropDownLabel: 'Opp Size',
    value: 'deal_size_with_partners',
  },
  [DEAL_SIZE_WITHOUT_PARTNERS]: {
    label: 'Deal Size Without Partners',
    value: 'deal_size_without_partners',
  },
  [TIME_TO_CLOSE_WITH_PARTNERS]: {
    label: 'Time to Close with Partners',
    dropDownLabel: 'Time to Close',
    value: 'time_to_close_with_partners',
  },
  [TIME_TO_CLOSE_WITHOUT_PARTNERS]: {
    label: 'Time to Close Without Partners',
    value: 'time_to_close_without_partners',
  },
  [TOTAL_REVENUE_WITH_PARTNERS]: {
    label: 'Total Revenue with Partners',
    value: 'total_revenue_with_partners',
  },
  [TOTAL_REVENUE_WITHOUT_PARTNERS]: {
    label: 'Total Revenue Without Partners',
    value: 'total_revenue_without_partners',
  },
};

export const ATTRIBUTION_SETTINGS_WITH_PARTNER_METRICS = {
  [TIME_TO_CLOSE_WITH_PARTNERS]: TIME_TO_CLOSE_WITHOUT_PARTNERS,
  [DEAL_SIZE_WITH_PARTNERS]: DEAL_SIZE_WITHOUT_PARTNERS,
  [WIN_RATE_WITH_PARTNERS]: WIN_RATE_WITHOUT_PARTNERS,
  [TOTAL_REVENUE_WITH_PARTNERS]: TOTAL_REVENUE_WITHOUT_PARTNERS,
};

export const ATTRIBUTION_TYPE_EXPLANATION = {
  [TOTAL_SOURCED]: 'The amount of partner-sourced revenue',
  [TOTAL_INFLUENCED]: 'The amount of partner-influenced revenue',
  [TOTAL_UNATTRIBUTED]:
    'The amount of potential revenue that is left unattributed to your partners',
  [WIN_RATE_WITH_PARTNERS]:
    'The percentage of final stage prospects that closed and became customers',
  [DEAL_SIZE_WITH_PARTNERS]:
    'The size of your opportunity when a partner is present',
  [TIME_TO_CLOSE_WITH_PARTNERS]:
    'The duration it took to win your opportunities',
};

export const ATTRIBUTION_SETTINGS_DEFAULTS = [
  TOTAL_SOURCED,
  TOTAL_INFLUENCED,
  TOTAL_UNATTRIBUTED,
  TIME_TO_CLOSE_WITH_PARTNERS,
];

export const ATTRIBUTION_UI_PARAMS = {
  VIEW: 'view',
  SCROLL: 'scroll',
  FROM_CAROUSEL: 'fromCarousel',
};

export const ATTRIBUTION_FILTER_QUERY_PARAMS = {
  ATTRIBUTION_EXISTS: 'attribution_exists',
  CLOSE_DATE_GTE: 'close_date[gte]',
  CLOSE_DATE_LTE: 'close_date[lte]',
  DISMISSED: 'dismissed',
  IS_CLOSED: 'is_closed',
  IS_WON: 'is_won',
  OPPORTUNITY_AMOUNT_GTE: 'opportunity_amount[gte]',
  OPPORTUNITY_AMOUNT_LTE: 'opportunity_amount[lte]',
  MASTER_UUID: 'master_uuid',
  SALES_STAGE: 'sales_stage',
  ORDER: 'order',
  SORT: 'sort',
  PARTNER_UUIDS: 'partner_uuids',
  PAGE: 'page',
  LIMIT: 'limit',
} as const;

export const ATTRIBUTION_SOURCE_TYPES = {
  GONG: 'gong',
  SALESEDGE: 'salesedge',
  CROSSBEAM_CORE: 'crossbeam-core',
  PARTNERSTACK: 'partnerstack',
};

export const ATTRIBUTION_EVENT_TYPES = {
  PARTNER_MENTION: 'partner_mention',
  REQUEST_SENT: 'request_sent',
  REQUEST_RECEIVED: 'request_received',
  POPULATION_EXIT: 'population_exit',
  POPULATION_ENTRY: 'population_entry',
  OVERLAP_ENTRY: 'overlap_entry',
  OVERLAP_EXIT: 'overlap_exit',
  PARTNERSTACK_LEAD_CREATED: 'partnerstack_lead_created',
  ATTRIBUTION_CREATED: 'attribution_created',
  ATTRIBUTION_DELETED: 'attribution_deleted',
  ATTRIBUTION_UPDATED: 'attribution_updated',
  THREAD_CLOSED: 'thread_closed',
  THREAD_CREATED: 'thread_created',
  THREAD_REOPENED: 'thread_reopened',
  THREAD_MESSAGE: 'thread_message',
  THREAD_OWNER_CHANGED: 'thread_owner_changed',
};

export const ATTRIBUTION_EVENTS_PARTNER_AVATAR_LOOKUP = {
  [ATTRIBUTION_EVENT_TYPES.PARTNER_MENTION]: true,
  [ATTRIBUTION_EVENT_TYPES.REQUEST_SENT]: true,
  [ATTRIBUTION_EVENT_TYPES.REQUEST_RECEIVED]: true,
  [ATTRIBUTION_EVENT_TYPES.OVERLAP_ENTRY]: true,
  [ATTRIBUTION_EVENT_TYPES.OVERLAP_EXIT]: true,
  [ATTRIBUTION_EVENT_TYPES.THREAD_CLOSED]: true,
  [ATTRIBUTION_EVENT_TYPES.THREAD_CREATED]: true,
  [ATTRIBUTION_EVENT_TYPES.THREAD_REOPENED]: true,
  [ATTRIBUTION_EVENT_TYPES.THREAD_MESSAGE]: true,
  [ATTRIBUTION_EVENT_TYPES.THREAD_OWNER_CHANGED]: true,
};

export const FILTER_EMITS = {
  FILTER_CLEARED: 'filter:cleared',
  FILTER_SAVED: 'filter:saved',
};

export const ATTRIBUTED_REVENUE = 'Attributed Revenue';
export const UNATTRIBUTED_REVENUE = 'Unattributed Revenue';
export const NON_PARTNER_REVENUE = 'Non-Partner Revenue';

export const METRIC_RATINGS = {
  BEST: 'best',
  WORSE: 'worse',
  BETTER: 'better',
  SAME: 'same',
};

export const EI_MISSING_STANDARD_POPULATIONS =
  'EI_MISSING_STANDARD_POPULATIONS';
export const EI_MISSING_REQUIRED_FIELDS = 'EI_MISSING_REQUIRED_FIELDS';
export const EI_NO_CRM = 'EI_NO_CRM';
export const EI_UPGRADE = 'EI_UPGRADE';
export const EI_NO_PARTNERS = 'EI_NO_PARTNERS';

const { deal: DEAL, user: USER, account: ACCOUNT } = MDM_PROPERTIES;
export const EI_REPORT_COLUMNS = [
  {
    table: MDM_TYPES.ACCOUNT,
    values: [ACCOUNT.INDUSTRY, ACCOUNT.BILLING_COUNTRY, ACCOUNT.EMPLOYEES],
  },
  {
    table: MDM_TYPES.DEAL,
    values: [
      DEAL.STAGE,
      DEAL.AMOUNT,
      DEAL.NAME,
      DEAL.OPEN_DATE,
      DEAL.CLOSE_DATE,
    ],
  },
  {
    table: MDM_TYPES.USER,
    values: [USER.EMAIL, USER.FIRST_NAME, USER.LAST_NAME, USER.NAME],
  },
];

export const ECOSYSTEM_INSIGHTS_UNHAPPY_PATHS = {
  [EI_MISSING_STANDARD_POPULATIONS]: {
    type: EI_MISSING_STANDARD_POPULATIONS,
    buttonText: 'Check your populations',
    buildRoute: () => ({ name: 'populations', hash: '#subtitle' }),
    title: 'Create all your Standard Populations to see your pipeline',
    description:
      'Leverage your data to see how your ecosystem is performing and gain insights on your overall pipeline.',
  },
  [EI_MISSING_REQUIRED_FIELDS]: {
    type: EI_MISSING_REQUIRED_FIELDS,
    buttonText: 'Sync your CRM',
    buildRoute: ({ id, name }: { id: string; name: string }) => ({
      name,
      params: { id },
      query: { customizing: true },
    }),
    title: 'Sync your CRM to see your pipeline',
    description:
      'Connect your CRM to see how your ecosystem is performing and gain insights on your overall pipeline.',
  },
  [EI_NO_CRM]: {
    type: EI_NO_CRM,
    buttonText: 'Connect data source',
    buildRoute: () => ({ name: 'data-sources' }),
    title: 'Add a CRM data source to supercharge your ELG engine',
    description:
      'Get the most of your ELG engine and identify high-performing partnerships with Crossbeam',
  },
  [EI_UPGRADE]: {
    type: EI_UPGRADE,
    buttonText: 'Talk to sales',
    buildRoute: () => ({ name: 'billing' }),
    title: 'Upgrade to supercharge your ecosystem',
    description:
      'Leverage your ecosystem data to accelerate and generate your pipelines',
  },
  [EI_NO_PARTNERS]: {
    type: EI_NO_PARTNERS,
    buttonText: 'Invite partners',
    buildRoute: () => ({ name: 'create_proposal' }),
    title: 'Add a partner on Crossbeam',
    description:
      'You need a partner on Crossbeam to see how your ecosystem is impacting your pipeline',
  },
};
