import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default function useReferrer() {
  const route = useRoute();
  const referrer = computed(() => route.query?.referrer || document.referrer);
  const revealSite = 'reveal.co';
  const isFromReveal = computed(
    () => referrer.value?.includes(revealSite) || false,
  );

  return {
    isFromReveal,
  };
}
