<template>
  <div class="copilot-app">
    <div v-if="initializationFailed" data-testid="application-failed">
      The application failed to load. Please try again or contact
      <a href="mailto:support@crossbeam.com">support@crossbeam.com</a>.
    </div>
    <router-view v-else v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { useHead } from '@unhead/vue';
import { watch } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';

const router = useRouter();

const { initializationFailed, inMaintenanceMode } = useAuth();

/**
 * We are watching for maintenance mode state coming from statuspage. If we have a
 * valid event, we punt user to the maintenance mode page.
 *
 * We punt the user back home if the old maintenance event is in state and has been reset to null
 * because no in_progress events are coming through. This essentially means maintenance mode has
 * been marked as completed
 */
watch(inMaintenanceMode, (newVal, oldVal) => {
  if (newVal?.id) router.push({ name: 'maintenance_mode' });
  else if (oldVal?.id && newVal === null) router.push({ name: 'home' });
});

useHead({
  title: 'Crossbeam Copilot',
});
</script>

<style lang="pcss">
#app {
  display: flex;
  flex-direction: column;
}
.copilot-app {
  position: relative;
  min-height: 100vh;
  height: 100vh;
}
</style>
