<template>
  <p class="text-neutral-500 italic flex flex-wrap">
    <span class="mr-4">Changed</span>
    <BittsAvatar
      :org="changedUsers.organization"
      :is-own="isOwnOrg"
      :show-initials="true"
      class="inline-flex items-center mr-4"
      size="x-small"
    />
    <span class="mr-4">{{ changedUsers.organization.name }}</span>
    <span class="mr-4">Owner from</span>
    <BittsAvatar
      :user="changedUsers.previousOwner"
      :is-own="isOwnOrg"
      :show-initials="true"
      class="not-italic inline-flex items-center mr-4"
      size="x-small"
    />
    <span class="mr-4">
      {{ changedUsers.previousOwner.first_name }}
      {{ changedUsers.previousOwner.last_name }}
    </span>
    <span class="inline-flex items-center mr-4">
      <FontAwesomeIcon
        :icon="['fas', 'arrow-right']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-400"
      />
    </span>
    <BittsAvatar
      :user="changedUsers.newOwner"
      :is-own="isOwnOrg"
      :show-initials="true"
      class="not-italic inline-flex items-center mr-4"
      size="x-small"
    />
    <span class="mr-4">
      {{ changedUsers.newOwner.first_name }}
      {{ changedUsers.newOwner.last_name }}
    </span>
  </p>
</template>

<script>
import { BittsAvatar } from '@crossbeam/bitts';

import useAuth from '@/composables/useAuth';

export default {
  name: 'ThreadEventChangedOwners',
  components: {
    BittsAvatar,
  },
  props: {
    changedUsers: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
  },
  setup() {
    const { currentOrg } = useAuth();

    return { currentOrg };
  },
  computed: {
    isOwnOrg() {
      return this.changedUsers.organization.id === this.currentOrg.id;
    },
  },
};
</script>
