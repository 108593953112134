<template>
  <BittsLoading :is-loading="!ready">
    <div class="email-input">
      <BittsInput
        v-model="email"
        :form-label="labels ? 'Email Address' : ''"
        placeholder="Work Email Address"
        name="email-one-input"
        data-testid="email-one-input"
        :status="status"
        :danger-text="badEmailMessage"
        @update:model-value="emitEvents"
        @blur="checkEmail"
      />
      <BittsSelect
        v-model="role"
        class="min-w-[180px]"
        :form-label="labels ? 'Core Role' : ''"
        placeholder="Select Role"
        :options="roleOptions"
        @update:model-value="emitEvents"
      />
    </div>
  </BittsLoading>
</template>
<script setup>
import { BittsInput, BittsLoading, BittsSelect } from '@crossbeam/bitts';

import { email as emailVal, required } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import { useRolesStore } from '@/stores';

function isValidEmail(email) {
  return emailVal.$validator(email) && required.$validator(email);
}
const emit = defineEmits(['invite']);

const props = defineProps({
  labels: {
    type: Boolean,
    default: false,
  },
  number: {
    type: Number,
    default: 1,
  },
});

const rolesStore = useRolesStore();
const { ready, allRoles } = storeToRefs(rolesStore);

const roleOptions = computed(() =>
  allRoles.value.map((role) => ({ value: role.id, label: role.name })),
);

const email = ref('');
const emailValid = computed(() => isValidEmail(email.value));

const role = ref(null);
const roleValid = computed(() => role.value !== null);

function emitEvents() {
  emit('invite', {
    role: role.value,
    email: email.value,
    number: props.number,
    valid: emailValid.value && roleValid.value,
  });
  if (status.value === 'danger') checkEmail();
}

const status = ref('default');
const badEmailMessage = 'That email is not valid';
function checkEmail() {
  if (email.value && !isValidEmail(email.value)) status.value = 'danger';
  else status.value = 'default';
}
</script>

<style scoped lang="pcss">
.email-input {
  @apply flex items-start gap-16;
}
</style>
