<template>
  <BittsDropdown
    :disabled="!checkedReports.length"
    @visibility-changed="(viz) => (isMoveReportsOpen = viz)"
  >
    <template #prompt>
      <BittsButton
        :disabled="!checkedReports.length"
        size="small"
        type="primary"
        :active="isMoveReportsOpen"
        :variant="isMoveReportsOpen ? 'fill' : 'outline'"
        text="Move Reports"
      />
    </template>
    <template #content>
      <FolderDropdown
        :folders="dropdownFolders"
        :move-to-unfiled="moveToUnfiled"
        @item-selected="onFolderSelected"
      />
    </template>
  </BittsDropdown>
</template>

<script setup>
import { BittsButton, BittsDropdown } from '@crossbeam/bitts';

import axios from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

import FolderDropdown from '@/components/reports/FolderDropdown.vue';

import { useFlashesStore, useReportsStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  checkedReports: {
    type: Array,
    required: true,
  },
  moveToUnfiled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['reports-moved', 'show-new-folder-modal']);

const isMoveReportsOpen = ref(false);

const route = useRoute();

const currentFolderUuid = computed(() => route.params.folder_id);
const dropdownFolders = computed(() =>
  folders.value.filter((folder) => folder.id !== currentFolderUuid.value),
);

const reportsStore = useReportsStore();
const { folders } = storeToRefs(reportsStore);

const flashesStore = useFlashesStore();

async function onFolderSelected(folder) {
  if (folder.name === 'Move To Unfiled') {
    const currentFolder = folders.value.find(
      (folder) => folder.id === currentFolderUuid.value,
    );
    const filteredReports = currentFolder.reports.filter(
      (reportUuid) => !props.checkedReports.includes(reportUuid),
    );
    const payload = {
      ...currentFolder,
      reports: filteredReports,
    };
    try {
      await axios.patch(urls.reports.folders.all, payload);
      flashesStore.addSuccessFlash({
        message: 'Reports moved to Unfiled',
      });
    } catch (_err) {
      flashesStore.addErrorFlash({
        message: 'Something went wrong',
        description: 'Reports not moved',
      });
      return;
    }
    emit('reports-moved');
  } else if (folder.name === 'New Folder') {
    emit('show-new-folder-modal');
    // we don't want to refresh for this, so returning early
    return;
  } else {
    // folder.reports can be empty, but will never be null
    const payload = {
      ...folder,
      reports: [...folder.reports, ...props.checkedReports],
    };
    try {
      await axios.patch(urls.reports.folders.all, payload);
      flashesStore.addSuccessFlash({
        message: `Reports moved to ${folder.name}`,
      });
      emit('reports-moved');
    } catch (_err) {
      flashesStore.addErrorFlash({
        message: 'Something went wrong',
        description: 'Reports not moved',
      });
      return;
    }
  }
  await reportsStore.refreshReportsStore();
}
</script>
