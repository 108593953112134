import * as LDClient from 'launchdarkly-js-client-sdk';

import appConfig from '@/config';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import { useBillingStore } from '@/stores/BillingStore';

/**
 * Initialize the Launch Darkly SDK
 * @param {Object} store
 * @returns {Object}
 */
export const createLaunchDarklyClient = async ({
  currentUser,
  currentOrg,
  featureFlagStore,
}) => {
  if (import.meta.env.VITEST === 'true') return stubbedLdClient;

  const context = {
    kind: 'multi',
  };

  /* If user is logged in, use multi-context for LD client */
  if (currentUser?.id && currentOrg?.id) {
    const billingStore = useBillingStore();
    const tier = billingStore.billingTier;
    context.user = {
      kind: 'user',
      key: currentUser.id,
      id: currentUser.id,
    };

    /* To allow for developers to target their flags more easily */
    if (appConfig.env === 'dev' || appConfig.env === 'staging') {
      context.user.email = currentUser.email;

      /* Additional metadata */
      context.engineering = {
        kind: 'engineering',
        key: crypto.randomUUID(),
        branch: APP_BRANCH,
      };
    }

    context.organization = {
      kind: 'organization',
      key: currentOrg.id,
      id: currentOrg.id,
      name: currentOrg.name,
      tier,
      env: appConfig.env,
    };
  }

  /* Adds Device context regardless of login status */
  try {
    let uuid = ls.deviceUUID.get();
    if (!uuid) {
      uuid = crypto.randomUUID();
      ls.deviceUUID.set(uuid);
    }

    context.device = {
      kind: 'device',
      key: `device-${uuid}`,
      id: uuid,
    };
  } catch (err) {
    captureException(err);
  }

  const evaluatedSessionFlags = {};
  const fsInspector = {
    type: 'flag-used',
    name: 'fullstory-inspector',
    method: (allFlags, isSingleFlag = false) => {
      if (
        window.FS === undefined ||
        typeof window.FS.getCurrentSessionURL !== 'function'
      ) {
        return;
      }
      const url = window.FS.getCurrentSessionURL(true);
      if (url === undefined) {
        return;
      }
      if (isSingleFlag) {
        const flag = Object.keys(allFlags)[0];
        const currentValue = Object.values(allFlags)[0].current;
        evaluatedSessionFlags[url] = {
          ...evaluatedSessionFlags[url],
          [flag]: currentValue,
        };
      } else {
        for (const flag in allFlags) {
          const value = allFlags[flag];
          evaluatedSessionFlags[url] = {
            ...evaluatedSessionFlags[url],
            [flag]: value,
          };
        }
      }
      const arrayOfFlags = Object.keys(evaluatedSessionFlags[url]).map(
        (flagKey) =>
          `${flagKey}=${JSON.stringify(evaluatedSessionFlags[url][flagKey], null, 1)}`,
      );
      if (typeof window.FS.setVars === 'function') {
        window.FS.setVars(
          'page',
          { launch_darkly: arrayOfFlags },
          { integration: 'launchdarkly' },
        );
      }
    },
  };

  const options = {
    evaluationReasons: !!appConfig.launchDarkly.debug,
    inspectors: [fsInspector],
  };
  const launchDarklyClient = LDClient.initialize(
    appConfig.launchDarkly.clientID,
    context,
    options,
  );
  launchDarklyClient.on('initialized', () => {
    const allFlags = launchDarklyClient.allFlags();
    fsInspector.method(allFlags);
  });
  await launchDarklyClient.waitUntilReady();

  launchDarklyClient.on('change', (flag) => {
    fsInspector.method(flag, true);
  });

  const flagData = launchDarklyClient.allFlags();
  const flags = formatFeatureFlags(flagData);

  featureFlagStore.setFlags({ flags, override: false });

  const debuggedFlag = appConfig.launchDarkly.debug;
  if (debuggedFlag) {
    console.log(
      `The '${debuggedFlag}' flag was set to: `,
      launchDarklyClient.variationDetail(debuggedFlag).value,
    );
    console.log(
      'Reason: ',
      launchDarklyClient.variationDetail(debuggedFlag).reason,
    );
    console.log('The context was: ', context);
  }

  return launchDarklyClient;
};

/**
 * Convert Launch Darkly feature flags to pinia feature flag format
 * @param {Object} featureFlags
 * @returns {Array}
 */
export const formatFeatureFlags = (flagData) => {
  const allFlags = Object.entries(flagData).map(([name, variation]) => ({
    name,
    variation,
  }));
  return allFlags.map(({ name, variation }) => {
    if (typeof variation === 'string') {
      return {
        name,
        variation: variation?.toLowerCase() === 'control' ? false : variation,
      };
    } else if (typeof variation === 'object') {
      return {
        name,
        variation:
          variation?.name?.toLowerCase() === 'control' ? false : variation,
      };
    }
    return { name, variation };
  });
};

const stubbedLdClient = {
  track: () => {
    // do nothing
  },
};
