<template>
  <div class="c-indiv-record">
    <div
      v-if="!isEmpty"
      key="c-indiv-record__cards"
      class="c-indiv-record__cards fullstory-hide dd-privacy-mask"
    >
      <TransitionGroup name="fade">
        <ItemCard
          v-if="sourceFiltered.account"
          key="account_information_card"
          :source="sourceFiltered.account"
          :is-our-data-selected="isOurDataSelected"
          title="Account Information"
        />
        <ItemCard
          v-if="sourceFiltered.lead"
          key="person_information_card"
          :source="sourceFiltered.lead"
          :is-our-data-selected="isOurDataSelected"
          title="Person Information"
        />
        <AccountsCard
          v-if="sourceFiltered.accounts"
          key="accounts"
          :source="sourceFiltered.accounts"
          :is-our-data-selected="isOurDataSelected"
        />
        <LeadsCard
          v-if="sourceFiltered.leads"
          key="leads"
          :source="sourceFiltered.leads"
          :is-our-data-selected="isOurDataSelected"
        />
        <AccountOwnersCard
          v-if="sourceFiltered.account_owners"
          key="account_owners_card"
          :source="sourceFiltered.account_owners"
        />
        <ContactsCard
          v-if="sourceFiltered.contacts"
          key="contacts_card"
          :source="sourceFiltered.contacts"
          :entity-name="entityName"
          :mdm-type="source.mdm_type"
          :is-our-data-selected="isOurDataSelected"
        />
        <OpportunitiesCard
          v-if="sourceFiltered.opportunities"
          key="opportunities_card"
          :source="sourceFiltered.opportunities"
          :is-our-data-selected="isOurDataSelected"
        />
      </TransitionGroup>
    </div>
    <BittsEmptyState
      v-else
      title="Select at least one partner and data type to view information"
      svg-name="emptyStatePopulations"
    />
    <div class="c-indiv-record__sidebar">
      <ViewOptions
        :source="sourceWithDataShareIds"
        :source-id="sourceId"
        :source-primary-key="sourcePrimaryKey"
        :entity-name="entityName"
        @change="onViewOptionsChange"
      />
    </div>
  </div>
</template>

<script>
import { BittsEmptyState } from '@crossbeam/bitts';

import { cloneDeep, intersection, pick } from 'lodash';
import { mapActions } from 'pinia';

import { MDM_PROPERTIES } from '@/constants/mdm';
import { useDataSharesStore, useMatchIssuesStore } from '@/stores';

import AccountOwnersCard from './Card/AccountOwnersCard.vue';
import AccountsCard from './Card/AccountsCard.vue';
import ContactsCard from './Card/ContactsCard.vue';
import ItemCard from './Card/ItemCard.vue';
import LeadsCard from './Card/LeadsCard.vue';
import OpportunitiesCard from './Card/OpportunitiesCard.vue';
import ViewOptions from './ViewOptions.vue';

const SOURCE_ELEMENTS_WITH_ITEMS = [
  'accounts',
  'leads',
  'account_owners',
  'opportunities',
  'contacts',
];

export default {
  name: 'IndividualRecordCards',
  components: {
    AccountsCard,
    BittsEmptyState,
    LeadsCard,
    ItemCard,
    AccountOwnersCard,
    ContactsCard,
    OpportunitiesCard,
    ViewOptions,
  },
  props: {
    source: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    sourceId: {
      type: [Number, String],
      default: '',
    },
    sourcePrimaryKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    sourceWithDataShareIds() {
      const source = cloneDeep(this.source);
      return Object.keys(source.elements).reduce((accumulator, key) => {
        if (SOURCE_ELEMENTS_WITH_ITEMS.indexOf(key) > -1) {
          accumulator.elements[key].data_share_ids = this.aggregateDataShareIds(
            source.elements[key].items,
          );
        }
        return accumulator;
      }, source);
    },
    isOurDataSelected() {
      return (
        intersection(
          this.source.our_population_ids,
          Array.from(
            this.options.population_ids ||
              new Set(this.source.our_population_ids),
          ),
        ).length === this.source.our_population_ids.length
      );
    },
    entityName() {
      const element = this.source.elements.account || this.source.elements.lead;
      const mdmPropertyName = this.source.elements.account
        ? MDM_PROPERTIES.account.NAME
        : 'person_email';
      const field = element.fields.find(
        (field) => field.mdm_property === mdmPropertyName,
      );
      return field ? field.value : null;
    },
    isEmpty() {
      const numberOfVisibleSections = Object.keys(this.sourceFiltered)
        .filter((key) => this.sourceFiltered[key])
        .reduce((accumulator, key) => {
          if (
            this.sourceFiltered[key].fields &&
            this.sourceFiltered[key].fields.length > 0
          ) {
            return accumulator + 1;
          }
          if (
            this.sourceFiltered[key].items &&
            this.sourceFiltered[key].items.length > 0
          ) {
            return accumulator + 1;
          }
          return accumulator;
        }, 0);
      return numberOfVisibleSections === 0;
    },
    numberOfVisibleSections() {
      return Object.keys(this.sourceFiltered).length;
    },
    sourceFiltered() {
      const source = cloneDeep(this.sourceWithDataShareIds);
      const sourceKeys = new Set(Object.keys(source.elements));
      const sourceWithPopulationFilters = {};
      SOURCE_ELEMENTS_WITH_ITEMS.forEach((key) => {
        if (sourceKeys.has(key)) {
          sourceWithPopulationFilters[key] = this.applyOptionsToItems(
            source.elements[key],
          );
        }
      });
      if (source.elements.account) {
        const account = this.applyOptions(source.elements.account);
        if (account.fields.length > 0) {
          sourceWithPopulationFilters.account = account;
        }
      }
      if (source.elements.lead) {
        const lead = this.applyOptions(source.elements.lead);
        if (lead.fields.length > 0) {
          sourceWithPopulationFilters.lead = lead;
        }
      }
      if (!this.options.data_type_ids) {
        return sourceWithPopulationFilters;
      }
      return pick(
        sourceWithPopulationFilters,
        Array.from(this.options.data_type_ids),
      );
    },
  },
  async created() {
    await this.fetchMatchIssuesBySourceIdAndRecordId({
      sourceId: this.sourceId,
      recordId: this.sourcePrimaryKey,
    });
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getDataShareById']),
    ...mapActions(useMatchIssuesStore, [
      'fetchMatchIssuesBySourceIdAndRecordId',
    ]),
    aggregateDataShareIds(items) {
      return items.reduce(
        (accumulator, item) => accumulator.concat(item.data_share_ids),
        [],
      );
    },
    onViewOptionsChange(options) {
      this.options = Object.assign({}, options);
    },
    applyOptionsToItems(section) {
      section.items = section.items
        .map(this.applyOptions)
        .filter((item) => item.fields.length > 0);
      if (section.items.length === 0) {
        return null;
      }
      return section;
    },
    applyOptions(item) {
      item.fields = item.fields.filter((field) => {
        return (
          !this.options.population_ids ||
          (this.isOurDataSelected && field.is_ours) ||
          (field.data_share_id &&
            this.options.population_ids.has(
              this.getDataShareById(field.data_share_id).partner_population_id,
            ))
        );
      });
      item.data_share_ids = item.data_share_ids.filter((dataShareId) => {
        return (
          !this.options.population_ids ||
          this.options.population_ids.has(
            this.getDataShareById(dataShareId).partner_population_id,
          )
        );
      });
      return item;
    },
  },
};
</script>
<style lang="pcss">
.c-indiv-record {
  @apply text-brand-navy;
  display: grid;
  grid-template-columns: [cards] 70% [sidebar] 30%;
}

.c-indiv-record--empty-state {
  display: flex;
  align-items: center;
  width: 100%;
}

.c-indiv-record__cards {
  @apply flex flex-col w-full pb-68;
}

.c-indiv-record__sidebar {
  @apply text-brand-navy;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.c-indiv-record__blocks {
  display: grid;
  grid-row-gap: 24px;
}

.c-indiv-record__name {
  font-size: 32px;
}

.c-indiv-record__domain {
  color: var(--theme-cobalt);
}

.c-indiv-record__domain--link {
  color: var(--theme-cobalt);
  &:hover {
    color: var(--theme-cobalt);
  }
}

.c-indiv-record__pop {
  color: var(--theme-cobalt);
}

.c-indiv-record__pop__icon {
  padding-right: 5px;
}

.c-indiv-record__tabs {
  margin-bottom: -1px;
  /*  The tabs should be above the tab content so that the tabs lack of a bottom
  border can override the top border on the content */
  z-index: 2;
}

.c-indiv-record__match-data {
  width: 1136px;
  margin: 0 auto;
  padding: 30px 0;
  min-height: 100vh;
}
</style>
