import axios from 'axios';
import { defineStore } from 'pinia';

import COPILOT_SOURCES, {
  COPILOT_SOURCE_EXTENSION,
  COPILOT_SOURCE_GONG,
  COPILOT_SOURCE_HUBSPOT,
  COPILOT_SOURCE_OUTREACH,
  COPILOT_SOURCE_SALESFORCE,
} from '@copilot/constants/sources';
import urls from '@copilot/urls';

import { captureException } from '@/errors';

export const useCopilotStore = defineStore('CopilotStore', {
  state: () => {
    return {
      recordId: null,
      source: null,
      account: null,
    };
  },
  actions: {
    initCopilotStore() {
      const sourceParam = new URLSearchParams(window.location.search)
        .get('source')
        ?.toLowerCase();
      if (COPILOT_SOURCES.some((source) => source === sourceParam)) {
        this.source = sourceParam;
      }
    },
    async loadAccount(recordId) {
      this.recordId = recordId;
      try {
        const { data } = await axios.get(urls.records.account(this.recordId));
        this.account = data?.data[0];
        return this.account;
      } catch (error) {
        captureException(error);
      }
    },
  },
  getters: {
    usePartitionedCookie() {
      // Do not use a partitioned cookie for chrome, otherwise you login for every website.
      return this.source !== COPILOT_SOURCE_EXTENSION;
    },
    showHeader() {
      return this.source !== COPILOT_SOURCE_SALESFORCE;
    },
    showOrgSwitcher() {
      return [
        COPILOT_SOURCE_EXTENSION,
        COPILOT_SOURCE_GONG,
        COPILOT_SOURCE_OUTREACH,
        COPILOT_SOURCE_HUBSPOT,
      ].includes(this.source);
    },
    isExtension() {
      return this.source === COPILOT_SOURCE_EXTENSION;
    },
    requiresEnterprise() {
      return [COPILOT_SOURCE_GONG, COPILOT_SOURCE_OUTREACH].includes(
        this.source,
      );
    },
  },
});
