<template>
  <div class="c-population-sharing-visibility flex flex-1 flex-col text-sm">
    <div
      v-for="population in populationsWithDefaultSettings"
      :key="`population-visibility-${population.id}`"
      class="flex items-center justify-between flex-1 border-b-2 text-neutral-800 last:border-b-0 border-neutral-200 p-16"
    >
      <div class="w-180 break-words font-bold mr-8 text-base">
        {{ population.name }}
      </div>
      <PopulationSharingVisibilitySelector
        :partner-name
        :population
        :value="visibilityLevel(population)"
        @sharing-changed="(viz) => onSelect(population, viz)"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import PopulationSharingVisibilitySelector from '@/components/partners/PopulationSharingVisibilitySelector.vue';

import { HIDDEN, POPULATION_RULE, SHARING } from '@/constants/data_shares';
import { useDataSharesStore, usePopulationsStore } from '@/stores';

export default {
  name: 'PopulationSharingVisibility',
  components: { PopulationSharingVisibilitySelector },
  props: {
    partnerName: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['input'],
  data() {
    return {
      populationVisibilityLevels: this.value,
    };
  },
  computed: {
    ...mapState(usePopulationsStore, ['populations']),
    populationsWithDefaultSettings() {
      return this.populations.map((population) => {
        const sharingSetting = this.sharingSetting(population);
        const noSetting = { visibility: HIDDEN };
        return {
          ...population,
          sharingSetting: sharingSetting || noSetting,
          sharingSettingVisibility: sharingSetting
            ? sharingSetting.visibility
            : noSetting.visibility,
        };
      });
    },
  },
  watch: {
    value() {
      this.populationVisibilityLevels = this.value;
    },
  },
  methods: {
    ...mapActions(useDataSharesStore, ['getOutgoingSharingRules']),
    onSelect(population, visibilityLevel) {
      this.populationVisibilityLevels = this.populationVisibilityLevels
        .filter(
          (populationWithVisibility) =>
            populationWithVisibility.id !== population.id,
        )
        .concat({
          id: population.id,
          visibilityLevel,
        });
      this.$emit('input', [...this.populationVisibilityLevels]);
    },
    sharingSetting(population) {
      return this.getOutgoingSharingRules({ ruleType: POPULATION_RULE }).find(
        (setting) => setting.population_id === population.id,
      );
    },
    visibilityLevel(population) {
      const existing = this.populationVisibilityLevels.find(
        (populationWithVisibility) =>
          populationWithVisibility.id === population.id,
      );
      if (!existing) {
        return this.visibilityValue(population.sharingSettingVisibility);
      }
      if (existing.visibilityLevel.includes(SHARING)) return SHARING;
      return existing.visibilityLevel;
    },
    visibilityValue(visibility) {
      if (!visibility) return HIDDEN;
      if (visibility.includes(SHARING)) return SHARING;
      return visibility;
    },
  },
};
</script>
