<template>
  <BittsCollapse
    :key="partner.id"
    :show-underline="false"
    :is-default-open="isOpened"
    class="c-sharing-dashboard-partner-card__collapse"
    :class="{ 'bg-neutral-background-weak': applyBgColor }"
    @change="onChange"
  >
    <template #header>
      <div class="flex">
        <PartnerIcon :partner-uuid="partner.uuid" />
        <div class="ml-8">
          <p class="text-base font-bold" data-testid="partner-name">
            {{ partner.name }}
          </p>
          <p class="text-xs text-neutral-text font-normal">
            {{ sharingSettingsText }}
          </p>
        </div>
      </div>
    </template>
    <template #extra>
      <div class="flex items-center w-full justify-between">
        <div class="flex flex-col w-1/3">
          <p class="text-neutral-text-weak text-xs font-bold">
            Sharing Setting
          </p>
          <BittsTag
            :text="isUsingDefaults ? 'Default' : 'Custom'"
            :color="isUsingDefaults ? 'neutral' : 'accent'"
            data-testid="sharing-setting-tag"
            size="small"
            class="text-base font-normal"
            variant="rounded"
          />
        </div>
        <SharingDashboardPartnerTags :tags="partner.tags" class="w-1/3" />
        <BittsButton
          :text="sharingBtnText"
          class="mr-12"
          size="x-small"
          variant="ghost"
          @click="onEditSharingClicked"
        />
      </div>
    </template>
    <div class="m-16">
      <div class="c-sharing-dashboard-partner-card__detail-container">
        <SharingDashboardPopDetail
          :pops-to-display="ownPopsWithEmptyStates"
          :pops="partner.ownPops"
          :partner
          :has-manage-sharing
          @create-pop-clicked="onCreatePopClicked"
        />
      </div>
    </div>
    <div class="m-16">
      <div class="c-sharing-dashboard-partner-card__detail-container">
        <SharingDashboardPopDetail
          :is-own="false"
          :pops-to-display="partnerPopsWithEmptyStates"
          :pops="partner.partnerPops"
          :partner
          :has-manage-sharing
        />
      </div>
    </div>
  </BittsCollapse>
</template>
<script setup>
import { BittsButton, BittsCollapse, BittsTag } from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import PartnerIcon from '@/components/partners/PartnerIcon.vue';
import SharingDashboardPartnerTags from '@/components/partners/SharingSettingsDashboard/SharingDashboardPartnerTags.vue';
import SharingDashboardPopDetail from '@/components/partners/SharingSettingsDashboard/SharingDashboardPopDetail.vue';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import {
  OFFLINE_PARTNERS,
  OVERRIDES,
  POPULATIONS,
  SHARING_SETTING,
  STANDARD_TYPE,
} from '@/constants/sharing_dashboard';

const props = defineProps({
  partner: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  isUsingDefaults: {
    type: Boolean,
    default: false,
  },
  hasManageSharing: {
    type: Boolean,
    default: false,
  },
  activeFilters: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  isOpened: {
    type: Boolean,
    default: false,
  },
});

const { iteratively } = useIteratively();
const router = useRouter();

const applyBgColor = ref(false);

function onChange() {
  applyBgColor.value = !applyBgColor.value;
}

watch(
  () => props.isOpened,
  (newVal) => {
    applyBgColor.value = newVal;
  },
);

const sharingSettingsText = computed(() =>
  props.isUsingDefaults
    ? 'You are sharing fields from the default population sharing settings'
    : 'You have customized sharing settings with this partner',
);

const emptyStandardPops = [
  {
    name: 'Customers',
    id: 0,
    isEmptyDetail: true,
    standard_type: 'customers',
  },
  {
    name: 'Open Opportunities',
    id: 1,
    isEmptyDetail: true,
    standard_type: 'open_opportunities',
  },
  {
    name: 'Prospects',
    id: 2,
    isEmptyDetail: true,
    standard_type: 'prospects',
  },
];

const partnerPopsWithEmptyStates = computed(() => {
  const standardPops = props.partner.partnerPops.map(
    (partnerPops) => partnerPops.standard_type,
  );
  const emptyPopsWithoutIncluded = emptyStandardPops.filter(
    (emptyPop) => !standardPops.includes(emptyPop.standard_type),
  );
  return sortBy(
    [...emptyPopsWithoutIncluded, ...props.partner.partnerPops],
    [STANDARD_TYPE],
  );
});

const ownPopsWithEmptyStates = computed(() => {
  const { [OFFLINE_PARTNERS]: _, ...offlinePartnerFilterRemoved } =
    props.activeFilters;
  if (Object.values(offlinePartnerFilterRemoved).length)
    return filteredOwnPops.value;
  const standardPops = props.partner.ownPops.map(
    (ownPops) => ownPops.standard_type,
  );
  const emptyPopsWithoutIncluded = emptyStandardPops.filter(
    (emptyPop) => !standardPops.includes(emptyPop.standard_type),
  );
  return sortBy(
    [...emptyPopsWithoutIncluded, ...props.partner.ownPops],
    [STANDARD_TYPE],
  );
});

const filteredOwnPops = computed(() => {
  const filterGroups = Object.keys(props.activeFilters);
  return props.partner.ownPops
    .filter(
      (pop) =>
        !filterGroups.includes(POPULATIONS) ||
        props.activeFilters[POPULATIONS].includes(pop.name),
    )
    .filter(
      (pop) =>
        (!filterGroups.includes(SHARING_SETTING) ||
          props.activeFilters[SHARING_SETTING]?.includes(
            pop.dataShare?.visibility,
          )) &&
        (!filterGroups.includes(OVERRIDES) ||
          props.activeFilters[OVERRIDES][0] ===
            !!pop.populationToPopulationSettings.length),
    );
});

const sharingBtnText = computed(() =>
  props.hasManageSharing ? 'Edit' : 'View',
);

async function onEditSharingClicked() {
  iteratively.userClickedEdit({
    event_site: EVENT_SITES.SHARING_DASHBOARD_EDIT_PARTNER_SHARING,
    property_name: props.partner.name,
  });
  await router.push({
    name: 'partner_details',
    params: { partner_org_id: props.partner.id },
    query: { tab: 'sharing' },
  });
}

async function onCreatePopClicked(pop) {
  iteratively.userClickedCreatePopulation({
    event_site: EVENT_SITES.SHARING_DASHBOARD_CREATE_POPULATION,
    population_type: pop.standard_type,
  });
  await router.push({
    name: 'create_population',
    query: { standardType: pop.standard_type },
  });
}
</script>
<style lang="pcss">
.c-sharing-dashboard-partner-card__collapse {
  @apply flex flex-col rounded-2xl;
  .ant-collapse {
    @apply p-12;
    .ant-collapse-item
      .ant-collapse-header
      .ant-collapse-expand-icon
      .ant-collapse-arrow {
      @apply mr-16;
      svg {
        color: theme(colors.neutral.accent) !important;
        height: 16px !important;
        width: 16px !important;
      }
    }
  }

  .ant-collapse-header-text {
    @apply flex;
  }
  .ant-collapse-extra {
    @apply flex w-2/4;
  }

  .ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left {
    .ant-collapse-item .ant-collapse-header {
      @apply p-0 mb-0;
    }
  }
  .c-sharing-dashboard-partner-card__detail-container {
    @apply border border-solid border-neutral-border bg-white rounded-8;
  }
}
</style>
