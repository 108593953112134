import createClient from 'openapi-fetch';

import appConfig from '@/config';
import type { paths } from '@/types/api.d';

interface Config {
  baseUrl: string;
  headers: Record<string, string | null>;
  credentials?: RequestCredentials;
}

const isTestMode = import.meta.env.MODE === 'test';

const config: Config = {
  baseUrl: appConfig.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  credentials: 'include',
};

if (isTestMode) {
  delete config.credentials;
}

const crossbeamApi = createClient<paths>(config);

/* Primarily used to set our Xbeam-Organization header which is an org uuid */
const setCustomHeader = (headerName: string, headerValue: string | null) => {
  config.headers[headerName] = headerValue;
};

export { crossbeamApi, setCustomHeader };
