<template>
  <BittsContainer class="role-card">
    <div class="role-card__info">
      <div class="role-card__name-and-desc">
        <div class="flex items-center">
          <div class="role-card__name">
            {{ role.name }}
          </div>
          <div
            v-if="roleType"
            :class="{ 'role-card__type-custom': role.is_custom }"
            class="role-card__type"
          >
            {{ roleType }}
          </div>
        </div>
        <div class="role-card__desc">
          {{ role.description }}
        </div>
      </div>
      <div
        :class="{ 'role-card__members-num-none': isNoMembers }"
        class="role-card__members-num"
      >
        {{ numRoleMembersText }}
      </div>
      <div class="role-card__edit">
        <BittsButton
          v-if="isClickable"
          :text="editButtonText"
          type="neutral"
          size="x-small"
          @click="$emit('edit-role')"
        />
      </div>
    </div>
  </BittsContainer>
</template>

<script>
import { BittsButton, BittsContainer } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import useAuth from '@/composables/useAuth';
import { useBillingStore } from '@/stores';

const NO_MEMBERS = 'No members';

export default {
  name: 'RoleCard',
  components: { BittsButton, BittsContainer },
  props: {
    numRoleMembersText: {
      type: String,
      default: '',
    },
    role: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
  },
  emits: ['edit-role'],
  setup() {
    const { hasScope } = useAuth();

    return { hasScope };
  },
  computed: {
    ...mapState(useBillingStore, ['isEnterpriseTier']),
    isClickable() {
      return this.hasScope('write:roles') && this.role.is_editable;
    },
    isNoMembers() {
      return this.numRoleMembersText === NO_MEMBERS;
    },
    roleType() {
      if (!this.role.is_editable) return 'System';
      if (this.role.is_custom) return 'Custom';
      return null;
    },
    editButtonText() {
      return this.isEnterpriseTier ? 'Edit' : 'View';
    },
  },
};
</script>

<style scoped lang="pcss">
.role-card__desc {
  @apply text-neutral-text text-sm text-left max-w-[400px];
}
.role-card__edit {
  @apply flex items-center justify-end;
}
.role-card__info {
  @apply grid;
  grid-template-columns: 5fr 1fr 1fr;
}
.role-card__members-num {
  @apply text-neutral-text-weak text-sm flex items-center justify-start;
}
.role-card__members-num-none {
  @apply opacity-50;
}
.role-card__name {
  @apply text-neutral-text-strong text-m font-bold;
}
.role-card__name-and-desc {
  @apply flex flex-col items-start;
}
.role-card__type {
  @apply px-8 rounded-bts-md bg-neutral-background text-neutral-text text-sm ml-4;
}
.role-card__type-custom {
  @apply text-info-text bg-info-background-weak;
}
</style>
