import { useContactsStore } from '@copilot/stores/ContactsStore';
import { useCopilotStore } from '@copilot/stores/CopilotStore';
import { useExtensionStore } from '@copilot/stores/ExtensionStore';

export default function useCopilotTracking() {
  const extensionStore = useExtensionStore();
  const copilotStore = useCopilotStore();
  const contactsStore = useContactsStore();
  return {
    getTrackedFields: () => {
      return {
        extension_injection_website: copilotStore?.isExtension
          ? extensionStore.parentWebsite
          : '',
        overlap_company_name: contactsStore.account?.name,
        overlap_company_domain: contactsStore.account?.domain,
      };
    },
  };
}
