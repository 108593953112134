<template>
  <BittsModal
    title="Delete Column?"
    :content-text="content"
    confirm-type="danger"
    :width="400"
    save-text="Delete"
    :visible="true"
    @closed="onModalClosed"
    @saved="onConfirmDeleteButtonClicked"
  />
</template>
<script setup>
import { BittsModal } from '@crossbeam/bitts';

import axios from 'axios';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  listId: {
    type: String,
    default: '',
  },
  colInfo: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const emits = defineEmits(['closed', 'saved']);

const { iteratively } = useIteratively();

const flashesStore = useFlashesStore();

const content = `Are you sure you want to delete this column?
  Your collaborators will no longer be able to see these columns, and this action can not be undone.`;

function onModalClosed() {
  emits('closed');
}

async function onConfirmDeleteButtonClicked() {
  try {
    await axios.delete(urls.lists.columns(props.listId, props.colInfo.colId));
    iteratively.userActsOnListColumns({
      column_action: 'Delete Column',
      column_name: props.colInfo.colName,
      event_site: EVENT_SITES.SHARED_LIST_DELETE_COLS_MODAL,
    });
    flashesStore.addSuccessFlash({
      message: 'Your column was successfully deleted',
    });
    emits('saved');
  } catch (e) {
    emits('closed');
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
    captureException(e);
  }
}
</script>
