<template>
  <section class="welcome-back-copy">
    <h2 data-testid="features-title" class="features-title">
      Welcome back, we're so happy to have you :')
    </h2>
    <p class="welcome-back-copy__subtitle">
      Restore your Connector plan to keep access to all the benefits you know
      and love:
    </p>
    <div class="feature-list">
      <BittsCard :no-padding="false">
        <div class="feature-card-content">
          <div class="feature-card-content__icon bg-beta-background-weak">
            <FontAwesomeIcon
              :icon="['far', 'ghost']"
              class="text-beta-accent"
            />
          </div>
          <p
            ><b>{{ offlinePartners.length || 'Unlimited' }}</b> Offline
            {{ pluralize(offlinePartners.length, 'Partner') }}</p
          >
          <BittsAvatarStack
            :transparent="true"
            :org-list="offlinePartners"
            class="ml-auto"
          />
        </div>
      </BittsCard>
      <BittsCard :no-padding="false">
        <div class="feature-card-content">
          <div class="feature-card-content__icon bg-warning-background-weak">
            <FontAwesomeIcon
              :icon="['far', 'file-chart-column']"
              class="text-warning-accent"
            />
          </div>
          <p v-if="reports.length">
            <b>{{ reports.length }}</b> Saved
            {{ pluralize(reports.length, 'Report') }}
            <span class="text-neutral-text-weak"
              >(and any configured notifications!)</span
            >
          </p>
          <p v-else> <b>Create</b> Saved Reports for quick access </p>
        </div>
      </BittsCard>
      <BittsCard :no-padding="false">
        <div class="feature-card-content">
          <div class="feature-card-content__icon bg-accent-background-weak">
            <FontAwesomeIcon
              :icon="['far', 'ghost']"
              class="text-accent-accent"
            />
          </div>
          <p v-if="lists.length">
            <b>{{ lists.length }}</b> Shared
            {{ pluralize(lists.length, 'List') }}
          </p>
          <p v-else> <b>Collaborate</b> with partners on Shared Lists </p>
        </div>
      </BittsCard>
      <BittsCard v-if="salesOnlyUsers.length" :no-padding="false">
        <div class="feature-card-content">
          <div class="bg-success-background-weak feature-card-content__icon">
            <FontAwesomeIcon
              :icon="['far', 'file-chart-column']"
              class="text-success-accent"
            />
          </div>
          <p
            ><b>{{ salesOnlyUsers.length }}</b> Sales
            {{ pluralize(salesOnlyUsers.length, 'user') }}</p
          >
          <BittsUserStack
            :users="salesOnlyUsers.map((v) => v.user)"
            :transparent="true"
            size="small"
            class="ml-auto"
          />
        </div>
      </BittsCard>
      <BittsCard :no-padding="false">
        <div class="feature-card-content">
          <div class="bg-info-background-weak feature-card-content__icon">
            <FontAwesomeIcon
              :icon="['far', 'chart-pie-simple']"
              class="text-info-accent"
            />
          </div>
          <p v-if="customPopulations.length">
            <b>{{ customPopulations.length }}</b> Custom
            {{ pluralize(customPopulations.length, 'population') }}
          </p>
          <p v-else> <b>Segment</b> accounts with Custom Populations </p>
        </div>
      </BittsCard>
      <BittsCard :no-padding="false">
        <div class="feature-card-content">
          <div class="bg-upsell-background-weak feature-card-content__icon">
            <FontAwesomeIcon
              :icon="['far', 'chart-pie-simple']"
              class="text-upsell-accent"
            />
          </div>
          <p
            ><b>{{ coreUsers.length }}</b> Full Access
            {{ pluralize(coreUsers.length, 'user') }}</p
          >
          <BittsUserStack
            :users="coreUsers.map((v) => v.user)"
            :transparent="true"
            size="small"
            class="ml-auto"
          />
        </div>
      </BittsCard>
    </div>
  </section>
</template>

<script setup>
import { BittsAvatarStack, BittsCard, BittsUserStack } from '@crossbeam/bitts';

import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';

import {
  useCollaborateStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
  useTeamStore,
} from '@/stores';

const partnersStore = usePartnersStore();
const reportsStore = useReportsStore();
const populationsStore = usePopulationsStore();
const collaborateStore = useCollaborateStore();
const teamStore = useTeamStore();

const { offlinePartners } = storeToRefs(partnersStore);
const { reports } = storeToRefs(reportsStore);
const { lists } = storeToRefs(collaborateStore);
const { salesOnlyUsers, coreUsers } = storeToRefs(teamStore);
const { customPopulations } = storeToRefs(populationsStore);
</script>

<style lang="pcss" scoped>
.welcome-back-copy {
  @apply h-full px-40 py-32 xl:px-120 lg:pt-96;
  background: radial-gradient(
      57.15% 41.02% at 23.19% 83.74%,
      theme(colors.upsell.accent / 0.8) 0%,
      theme(colors.upsell.accent / 0) 100%
    ),
    radial-gradient(
      71.71% 50.59% at 87.36% -3.52%,
      theme(colors.info.accent / 0.8) 0%,
      theme(colors.info.accent / 0) 100%
    ),
    linear-gradient(
      180deg,
      theme(colors.brand-navy) 0%,
      theme(colors.primary.text-button) 100%
    );
}

.welcome-back-copy__header {
  @apply font-bold text-white text-m mb-16;
}

.welcome-back-copy__subtitle {
  @apply text-m text-white opacity-50 mb-24;
}

.features-title {
  background: linear-gradient(
    91.59deg,
    theme(colors.info.border) 0%,
    theme(colors.white) 100%
  );
  @apply text-xxl bg-clip-text font-bold mb-16;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
}

.feature-list {
  @apply flex flex-col gap-24;
}

.feature-card-content {
  @apply flex gap-16 items-center;

  .feature-card-content__icon {
    @apply w-40 h-40 flex items-center justify-center rounded-8;
    svg {
      @apply h-20 w-20;
    }
  }
}
</style>
<style lang="pcss">
.feature-list {
  .bitts-container {
    @apply p-8;
  }
}
</style>
