<template>
  <div class="c-autocomplete relative inline-block">
    <BittsInput
      v-model="query"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :model-value="initialValue"
      :status="errors.length ? 'danger' : 'default'"
      :danger-text="errors?.at(-1)?.$message || ''"
      @enter-pressed="onEnterPressed"
    />
    <div
      v-if="showDropdown"
      v-click-away="closeDropdown"
      class="o-dropdown-menu__content c-autocomplete__content"
    >
      <ul
        v-if="!loading && searchResults.length > 0"
        class="o-dropdown-menu__list c-autocomplete__results"
      >
        <DropdownMenuItem
          v-for="(searchResult, index) in searchResults"
          ref="searchResult"
          :key="`searchResult_${index}`"
          :is-active="currentIndex === index"
          class="c-autocomplete__results__item"
          @activate="onItemHighlighted(index)"
          @click="onItemSelected(searchResult)"
        >
          <slot :value="searchResult" name="item">
            {{ searchResult }}
          </slot>
        </DropdownMenuItem>
      </ul>
      <div v-if="loading" class="c-autocomplete__results--empty-state">
        <img
          class="c-autocomplete__searching-icon"
          src="/images/gifs/loading.gif"
        />
        Searching...
      </div>
    </div>
  </div>
</template>

<script>
import { BittsInput } from '@crossbeam/bitts';

import { get as lodashGet, set as lodashSet } from 'lodash';
import { mixin as VueClickAway } from 'vue3-click-away';

import DropdownMenuItem from '@/components/DropdownMenuItem.vue';

export default {
  name: 'AutoComplete',
  components: { DropdownMenuItem, BittsInput },
  mixins: [VueClickAway],
  props: {
    searchResults: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    lodashPath: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    initialValue: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['dropdown-opened', 'dropdown-closed', 'change', 'selected'],
  data() {
    return {
      showDropdown: false,
      currentIndex: -1,
      query: '',
    };
  },
  watch: {
    query(val) {
      this.onSearchChanged(val);
    },
  },
  methods: {
    openDropdown() {
      if (this.showDropdown) {
        return;
      }
      this.showDropdown = true;
      this.$emit('dropdown-opened');
    },
    closeDropdown() {
      if (!this.showDropdown) {
        return;
      }
      this.showDropdown = false;
      this.currentIndex = -1;
      this.$emit('dropdown-closed');
    },
    onUpPressed() {
      if (!this.showDropdown) return;
      const i = Math.max(this.currentIndex - 1, 0);
      this.currentIndex = i;
      this.scrollToIndex(i, 'start');
    },
    onDownPressed() {
      if (!this.showDropdown) return;
      const i = Math.min(this.currentIndex + 1, this.searchResults.length - 1);
      this.currentIndex = i;
      this.scrollToIndex(i, 'end');
    },
    onEnterPressed() {
      if (!this.showDropdown) return;
      if (this.searchResults.length === 0) return;
      if (this.currentIndex === -1) {
        this.onItemSelected(lodashSet({}, this.lodashPath, this.query));
      } else {
        this.$refs.searchResult[this.currentIndex].$el.click();
      }
    },
    scrollToIndex(index, block) {
      /* This is to ensure the element is visible. Seems like a lot of
      code for an unlikely edge case, but keeping for now */
      const element = this.$refs.searchResult?.[index];
      if (element) {
        element.$el.scrollIntoView({
          behavior: 'smooth',
          block,
          inline: 'center',
        });
      }
    },
    onItemHighlighted(index) {
      this.currentIndex = index;
    },
    onItemSelected(searchResult) {
      this.closeDropdown();
      this.$emit('selected', searchResult);
      if (this.lodashPath) {
        this.query = lodashGet(searchResult, this.lodashPath);
      } else {
        this.query = searchResult;
      }
    },
    onSearchChanged(query) {
      this.openDropdown();
      this.$emit('change', query);
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-autocomplete {
  @apply w-full;
}

.c-autocomplete__content {
  @apply w-full;
  max-height: 500px;
}

.c-autocomplete__searching-icon {
  @apply w-32;
}

.c-autocomplete__results--empty-state {
  @apply flex flex-col flex-1 items-center text-center justify-center;
  @apply text-brand-navy text-base min-h-96;
}

.c-autocomplete__results--empty-state:hover {
  cursor: pointer;
}

.c-autocomplete__results__item {
  @apply text-brand-navy;
  @apply text-base whitespace-nowrap items-center px-12 py-6;
}

.o-dropdown-menu__content {
  position: absolute;
  z-index: 2;
  border-radius: 4px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-top: 2px;
  overflow: auto;
}

.o-dropdown-menu__list {
  list-style: none;
  padding: 0;
}
</style>
