<template>
  <form class="c-configure-sharing-step">
    <div class="flex flex-1 flex-col w-full">
      <div class="flex flex-col w-full">
        <div class="flex flex-col">
          <div v-if="!hideTitle">
            <span
              v-if="!hasNoPopulations"
              class="text-neutral-900 font-bold mb-8 text-m"
            >
              Sharing Settings with {{ otherOrgName }}
            </span>
            <span v-else class="text-neutral-900 font-bold mb-8 text-m">
              Accept Partnership from {{ otherOrgName }}
            </span>
          </div>
        </div>
      </div>
      <div
        v-if="!hasNoPopulations"
        class="flex flex-1 flex-col w-full justify-center items-center"
      >
        <div class="flex flex-col flex-1 w-full">
          <PopulationSharingVisibility
            :value="populationVisibilityLevels"
            :populations="populations"
            :partner-name="otherOrgName"
            class="bg-white mb-16 border-2 border-neutral-200 rounded-bts-lg"
            @input="(opt) => (populationVisibilityLevels = opt)"
          />
        </div>
      </div>
    </div>
    <div v-if="!hideButtons" class="flex items-center flex-row-reverse gap-16">
      <BittsButton
        :text="submitText"
        class="flex-1"
        :is-loading="sending"
        @click="submit"
      />
      <BittsButton
        :text="cancelButtonText"
        :is-loading="sending"
        class="flex-1"
        type="neutral"
        @click="cancel"
      />
    </div>
  </form>
</template>

<script>
import { BittsButton } from '@crossbeam/bitts';

import { mapState } from 'pinia';

import PopulationSharingVisibility from '@/components/partners/PopulationSharingVisibility.vue';

import {
  OLAPS_ONLY,
  SHARING_LEVEL_OPTIONS_TEXT,
} from '@/constants/data_shares';
import { usePopulationsStore } from '@/stores';

export default {
  name: 'ConfigureSharingStep',
  components: {
    BittsButton,
    PopulationSharingVisibility,
  },
  props: {
    sending: {
      type: Boolean,
      required: true,
    },
    otherOrgName: {
      type: String,
      required: true,
    },
    hideButtons: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: 'Invite',
    },
    cancelButtonText: {
      type: String,
      default: 'Cancel',
    },
  },
  emits: ['cancel', 'submit'],
  data() {
    return {
      chosenPopIds: [],
      populationVisibilityLevels: [],
    };
  },
  computed: {
    ...mapState(usePopulationsStore, ['populations']),
    hasNoPopulations() {
      return !this.populations.length;
    },
    sharingLevelOptions() {
      return ['use_defaults', OLAPS_ONLY, 'set-per-pop'];
    },
    sharingLevelOptionsText() {
      return SHARING_LEVEL_OPTIONS_TEXT;
    },
    populationShares() {
      const payload = this.populations.map((population) => {
        let sharingLevel = 'use_defaults';
        const selected = this.populationVisibilityLevels.find(
          (populationWithVisibilityLevel) =>
            population.id === populationWithVisibilityLevel.id,
        );
        if (selected) {
          sharingLevel = selected.visibilityLevel;
        }
        return {
          population_id: population.id,
          sharing_level: sharingLevel,
        };
      });
      return payload;
    },
  },
  async created() {
    const populationsStore = usePopulationsStore();
    await populationsStore.readySync;
    this.selectAll();
  },
  methods: {
    setAllVisibilityLevels(visibilityLevel) {
      this.populationVisibilityLevels = this.populations.map((population) => ({
        id: population.id,
        visibilityLevel,
      }));
    },
    cancel() {
      this.$emit('cancel');
    },
    submit() {
      this.$emit('submit', {
        populationShares: this.populationShares,
        chosenPopIds: this.chosenPopIds,
      });
    },
    toggleMyPop(pop) {
      if (this.chosenPopIds.indexOf(pop.id) === -1) {
        this.addMyPop(pop);
      } else {
        this.removeMyPop(pop);
      }
    },
    addMyPop(pop) {
      this.chosenPopIds = this.chosenPopIds.concat(pop.id);
    },
    removeMyPop(pop) {
      this.chosenPopIds = this.chosenPopIds.filter(
        (myPopId) => myPopId !== pop.id,
      );
    },
    selectAll() {
      this.chosenPopIds = this.populations.map((pop) => pop.id);
    },
    selectNone() {
      this.chosenPopIds = [];
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-configure-sharing-step {
  @apply w-full;
}
</style>
