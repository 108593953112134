<template>
  <Notification :item>
    <template #logo>
      <FontAwesomeIcon
        :icon="['fas', 'file-export']"
        :style="{ height: '40px', width: '40px', color: 'currentColor' }"
        class="text-info-accent"
      />
    </template>
    <template #message> Record Export Limits </template>
    <template #description>
      {{ notificationDescription }}
    </template>
    <template #action>
      <BittsButton
        size="x-small"
        text="Talk to Sales"
        variant="outline"
        type="neutral"
        @click="
          handleBillingInteraction({
            cta: BILLING_CTAS.RECORD_EXPORT_LIMIT,
            talkToSalesReason: 'Need more record exports',
            event_site: EVENT_SITES.EXPORT_LIMIT_NOTIFICATION,
          })
        "
      />
    </template>
    <template #icon>
      <FontAwesomeIcon
        :icon="['fas', 'database']"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        class="text-neutral-accent ml-4"
      />
    </template>
  </Notification>
</template>
<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';

import Notification from '@/components/notifications/Notification.vue';

import useBilling from '@/composables/useBilling';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';

const props = defineProps({
  item: {
    type: Object,
    default: () => {
      // do nothing
    },
    required: true,
  },
});

const { handleBillingInteraction } = useBilling();

const notificationDescription = computed(() =>
  props.item.data.threshold === 75
    ? 'Your organization has exceeded 75% of its record export limit. Upgrade to increase the number of record exports.'
    : 'Your organization has exceeded 100% of its record export limit and you can no longer export any data. Upgrade to increase the number of record exports.',
);
</script>
