<template>
  <div class="c-forgot-password">
    <div class="c-forgot-password__content">
      <h1 class="c-forgot-password__title">
        Enter Your Account Email Address
      </h1>
      <span class="c-forgot-password__content__subtitle">
        We'll send you an email with a link you can use to reset your password
      </span>
      <div class="c-forgot-password__form-container">
        <BittsInput
          v-model="email"
          form-label="Email Address"
          placeholder="Email Address"
          name="user_email"
          class="mb-24"
          data-testid="forgot-password-email"
          :status="v$.email.$errors.length ? 'danger' : 'default'"
          :danger-text="v$.email.$errors?.at(-1)?.$message || ''"
          @enter-pressed="resetPassword"
        />
        <BittsButton
          class="c-forgot-password__reset-button"
          data-testid="forgot-password-reset-button"
          text="Reset Password"
          :loading="loading"
          type="primary"
          @click="resetPassword"
        />
        <BittsButton
          text="Cancel"
          variant="outline"
          class="w-full"
          @click="$router.push({ name: 'login' })"
        />
        <BittsAlert
          v-if="snackbarMessage"
          class="mt-12"
          :message="snackbarMessage"
          :color="error ? 'error' : 'success'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BittsAlert, BittsButton, BittsInput } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { useVuelidate } from '@vuelidate/core';
import { email as emailVal, helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { ref } from 'vue';

import urls from '@/urls';

export default {
  name: 'ForgotPassword',
  components: {
    BittsInput,
    BittsButton,
    BittsAlert,
  },

  setup() {
    useHead({
      title: 'Forgot Password - Crossbeam',
    });
    const email = ref(null);
    const rules = {
      email: {
        required: helpers.withMessage('Please enter your email', required),
        email: helpers.withMessage('Please enter a valid email', emailVal),
      },
    };

    const v$ = useVuelidate(rules, { email });

    return { email, v$ };
  },

  data() {
    return {
      loading: false,
      snackbarMessage: null,
      error: false,
    };
  },
  methods: {
    async resetPassword() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.loading = true;
      await axios
        .post(urls.forgotPassword.post, { email: this.email })
        .then(() => {
          this.snackbarMessage =
            "We've just sent you an email to reset your password.";
          this.error = null;
        })
        .catch((error) => {
          this.error = error;
          const apiMessage = error?.response?.data?.errors
            ? error.response.data.errors[0]
            : null;
          this.snackbarMessage =
            apiMessage ||
            error.message ||
            "We've had trouble resetting your password. Please try again.";
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-forgot-password {
  @apply flex flex-col items-center justify-center flex-1 px-24;
}

.c-forgot-password__title {
  font-weight: 600;
  @apply text-xl text-brand-navy text-center;
}

.c-forgot-password__reset-button {
  @apply w-full mb-12;
}

.c-forgot-password__content {
  @apply mt-24 md:mt-0 flex flex-col items-center text-center justify-center max-w-[420px] mb-16;
}

.c-forgot-password__content__subtitle {
  @apply text-neutral-400 mt-12 px-24;
}

.c-forgot-password__form-container {
  @apply flex flex-col items-center justify-center w-full mt-18;
}
</style>
