<template>
  <BittsModal
    title="Duplicate Report"
    save-text="Save a Copy"
    :visible="showDuplicateReportModal"
    :loading="loading"
    :disabled="v$.$invalid"
    :saving="isReportSaving"
    @saved="saveCopy"
    @closed="onModalClose"
  >
    <template #content>
      <p class="text-neutral-text-strong mb-8">
        Please specify a unique name for a copy of
        <strong>{{ report ? report.name : '' }}</strong>
      </p>
      <BittsInput
        v-model="name"
        name="report-name"
        placeholder="Report Name"
        :status="v$.name.$errors.length ? 'danger' : 'default'"
        :danger-text="v$.name.$errors?.at(-1)?.$message || ''"
        @update:model-value="v$.$touch()"
      />
    </template>
  </BittsModal>
</template>
<script>
import { BittsInput, BittsModal } from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import axios from 'axios';
import { mapActions } from 'pinia';
import { ref } from 'vue';

import { ECOSYSTEM } from '@/constants/reports';
import { BASE_ROUTES } from '@/constants/routes';
import { captureException } from '@/errors';
import { useReportsStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'DuplicateReportModal',
  components: { BittsInput, BittsModal },
  setup() {
    const name = ref(null);

    const rules = {
      name: {
        required: helpers.withMessage(
          'A name for the report is required',
          required,
        ),
        isUnique: helpers.withMessage(
          'This report must have a unique name',
          (value, _, vm) => {
            const report = vm.getByReportName(value || '');
            return !report;
          },
        ),
      },
    };

    const v$ = useVuelidate(rules, { name }, { $lazy: true });

    return { name, v$ };
  },
  data() {
    return {
      loading: true,
      showDuplicateReportModal: false,
      isReportSaving: false,
    };
  },
  computed: {
    closeRoute() {
      if (!this.folderId) return { name: BASE_ROUTES.REPORTS };
      return {
        name: BASE_ROUTES.REPORT_FOLDER,
        params: { folder_id: this.folderId },
      };
    },
    folderId() {
      return this.$route.params.folder_id;
    },
    report() {
      const currentReportId = this.$route.query.id;
      return this.getByReportId(currentReportId);
    },
    saveReportPayload() {
      if (!this.report.id) return {};
      const payload = {
        name: this.name,
        consolidated_report_type: this.report.consolidated_report_type,
        columns: this.removeNullColumnFields(this.report.columns),
        our_population_ids: this.report.our_population_ids,
        partner_standard_populations: this.report.partner_standard_populations,
        sort: this.report.sort,
        filters: this.report.filters.map((filter) => {
          const newFilter = {
            ...filter,
            filter_parts: filter.filter_parts.map((filterPart) => {
              const output = { ...filterPart };
              delete output.id;
              delete output.report_population_id;
              return output;
            }),
          };
          delete newFilter.report_id;
          delete newFilter.id;
          return newFilter;
        }),
      };
      const isAllPartnersType = payload.consolidated_report_type === ECOSYSTEM;
      if (this.report?.tag_id) payload.tag_id = this.report.tag_id;
      if (!isAllPartnersType)
        payload.partner_population_ids = this.report.partner_population_ids;
      else if (this.report.partner_impact_scores) {
        payload.partner_impact_scores = this.report.partner_impact_scores;
      }
      return payload;
    },
  },
  async created() {
    this.showDuplicateReportModal = true;
    await this.refreshReportsStore();
    this.setName();
    this.loading = false;
  },
  methods: {
    ...mapActions(useReportsStore, [
      'getByReportId',
      'getByReportName',
      'refreshReportsStore',
    ]),
    editLink(report) {
      return {
        name: 'edit_report',
        params: { report_id: report.id },
      };
    },
    onInput() {
      this.v$.$touch();
    },
    removeNullColumnFields(columns) {
      return columns.map((column) => {
        const validColumn = { ...column };

        const filteredColumn = Object.keys(column).reduce((acc, field) => {
          const { [field]: _, ...rest } = acc;
          return validColumn[field] === null ? rest : acc;
        }, validColumn);

        return filteredColumn;
      });
    },
    async saveCopy() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      try {
        this.isReportSaving = true;
        const payload = this.saveReportPayload;
        const response = await axios.post(urls.reports.create, payload);
        const newReport = response.data;
        await this.$router.push(this.editLink(newReport));
      } catch (err) {
        captureException(err);
      } finally {
        this.showDuplicateReportModal = false;
        this.isReportSaving = false;
      }
    },
    setName() {
      let name = `${this.report.name} (Copy)`;
      let report = this.getByReportName(name);
      if (!report) {
        this.name = name;
        return;
      }

      let copyNumber = 1;
      while (report) {
        name = `${this.report.name} (Copy ${copyNumber})`;
        report = this.getByReportName(name);
        copyNumber++;
      }
      this.name = name;
    },
    async onModalClose() {
      this.showDuplicateReportModal = false;
      await this.$router.push(this.closeRoute);
    },
  },
};
</script>
