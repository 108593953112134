<template>
  <div class="datasource-source-selector">
    <BittsInput
      v-model="searchQuery"
      type="search"
      class="m-16 w-auto"
      placeholder="Search"
      name="source-selector-search"
      size="small"
    />
    <BittsTabs
      :tabs="tabs"
      class="mb-24"
      :current-value="activeTab"
      @change:tab="(val) => (activeTab = val)"
    />
    <div v-if="activeTab === ALL_FIELDS">
      <BittsTree
        v-if="!fieldsPending"
        :checked-keys="selectedFields"
        :tree-data="selectedFieldsOnly"
        :show-checkboxes="true"
        :multi-select="true"
        :include-node-info="true"
        :show-line="false"
        :search-value="delayedSearchQuery"
        class="datasource-source-selector__tree"
        @check="onFieldSelected"
      />
      <div v-else class="flex flex-col items-center h-[150px] justify-center">
        <div class="flex items-center text-neutral-text-placeholder text-m">
          <FontAwesomeIcon
            :icon="['fas', 'arrows-rotate']"
            :style="{ height: '10px', width: '10px', color: 'currentColor' }"
            class="text-neutral-text-placeholder mr-4"
          />
          <span> Some fields are syncing. </span>
        </div>
        <div class="text-base text-neutral-text-placeholder flex flex-row">
          Check back later or
          <div
            class="text-info-text ml-3 cursor-pointer"
            @click="$emit('refreshFields')"
          >
            click here to refresh
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <BittsTree
        v-if="!fieldsPending"
        :checked-keys="selectedFields"
        :tree-data="allFields"
        :show-checkboxes="true"
        :multi-select="true"
        :include-node-info="true"
        :show-line="false"
        :search-value="delayedSearchQuery"
        class="datasource-source-selector__tree"
        @check="onFieldSelected"
      />
    </div>
  </div>
</template>

<script>
import { BittsInput, BittsTabs, BittsTree } from '@crossbeam/bitts';

import { debounce, orderBy } from 'lodash';
import { mapActions, mapState } from 'pinia';

import {
  ALL_FIELDS,
  CANNOT_UNSYNC,
  CANNOT_UNSYNC_PLURAL,
  LOOKUP_FIELD,
  SELECTED_FIELDS,
  SOURCE,
  SOURCE_FIELD,
  SYNCED,
  UNSYNCED,
  UNSYNCED_DISABLED,
  getFieldTooltipText,
  getKey,
} from '@/constants/data_sources';
import { useSourcesStore } from '@/stores';

export default {
  components: {
    BittsTabs,
    BittsInput,
    BittsTree,
  },
  props: {
    fieldsPending: {
      type: Boolean,
      default: false,
    },
    initialAllFields: {
      type: Array,
      default: () => [],
    },
    initialSelectedFieldsOnly: {
      type: Array,
      default: () => [],
    },
    requiredColumns: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    selectedFields: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['refreshFields', 'fields-changed'],
  data() {
    return {
      ALL_FIELDS,
      activeTab: SELECTED_FIELDS,
      allFields: [],
      selectedFieldsOnly: [],
      searchQuery: '',
      searchDebounce: null,
      delayedSearchQuery: '',
    };
  },
  computed: {
    ...mapState(useSourcesStore, ['readySync']),
    tabs() {
      return [
        {
          name: 'Selected Fields',
          value: ALL_FIELDS,
          disabled: this.fieldsPending,
        },
        {
          name: 'All Fields',
          value: SELECTED_FIELDS,
          disabled: this.fieldsPending,
        },
      ];
    },
  },
  watch: {
    initialAllFields() {
      this.allFields = this.initialAllFields;
    },
    initialSelectedFieldsOnly() {
      this.selectedFieldsOnly = this.initialSelectedFieldsOnly;
    },
    searchQuery(newVal) {
      this.searchDebounce(newVal);
      this.activeTab = ALL_FIELDS;
    },
  },
  async created() {
    this.searchDebounce = debounce((newSearchQuery) => {
      this.delayedSearchQuery = newSearchQuery;
    }, 500);
    await this.readySync;
    this.allFields = this.initialAllFields;
    this.selectedFieldsOnly = this.initialSelectedFieldsOnly;
  },
  methods: {
    ...mapActions(useSourcesStore, ['getSourceFieldById']),
    addDisabledClassToChild(fields, child) {
      const sourceIndex = fields.findIndex(
        (source) => source.id === child.source_id,
      );
      // remove the disabled class if required classes get unchecked in bulk
      fields[sourceIndex].children = fields[sourceIndex].children.map(
        (fieldChild) => {
          if (fieldChild.id === child.id) {
            fieldChild.class += ' datasource-source-selector__node-disabled';
          }
          return fieldChild;
        },
      );
      return fields;
    },
    addFieldToSelectedFieldsOnly(source, fieldId) {
      source.children = source.children.map((child) => {
        if (child.id === fieldId) {
          const newChild = { ...child };
          // reset the classes, which will remove hidden for the Selected tab
          newChild.class = newChild.class.replace('hidden', '');
          if (newChild.value.required) {
            newChild.class += ' datasource-source-selector__node-disabled';
            // addFieldToSelectedFieldsOnly only accounts for selectedFieldsOnly
            // we need to add the disabled class back to both allFields and selectedFieldsOnly
            this.allFields = this.addDisabledClassToChild(
              this.allFields,
              child,
            );
          }
          return newChild;
        }
        return child;
      });
    },
    changeTab(tabData) {
      const { newTab } = tabData;
      this.activeTab = newTab;
    },
    checkLookupField(value) {
      const {
        source_id: sourceId,
        copies_source_field_id: copyId,
        relationship_id: relId,
        id,
      } = value;
      const sourceIndex = this.selectedFieldsOnly.findIndex(
        (source) => source.id === sourceId,
      );
      const source = this.selectedFieldsOnly[sourceIndex];
      this.disableRequiredFields(source);
      const isParentLookupField = !copyId;
      const parentLookupFieldIndex = source.children.findIndex(
        (field) => field.relationship_id === relId && field.children,
      );
      const parentLookupField = source.children[parentLookupFieldIndex];
      const copiedFieldSourceId = source.relationships.find(
        (rel) => rel.id === relId,
      ).references_source_id;
      if (isParentLookupField) {
        parentLookupField.children.forEach((child) => {
          if (!child.value.requiresUncheckedField) {
            child.class = child.class.replace('hidden', '');
            this.disableCopiedSourceField(
              child.copies_source_field_id,
              copiedFieldSourceId,
            );
            const tooltipOptions = {
              lookupFieldId: child.id,
              relId,
              lookupSourceId: sourceId,
              copiedFieldId: child.copies_source_field_id,
              copiedSourceId: copiedFieldSourceId,
              messageTypeLookup: SYNCED,
              wasLookupFieldChecked: true,
            };
            this.updateTooltipText(tooltipOptions);
          }
        });
      } else {
        const tooltipOptions = {
          lookupFieldId: id,
          relId,
          lookupSourceId: sourceId,
          copiedFieldId: copyId,
          copiedSourceId: copiedFieldSourceId,
          messageTypeLookup: SYNCED,
          wasLookupFieldChecked: true,
        };
        const selectedLookupField = parentLookupField.children.find(
          (field) => field.id === id,
        );
        selectedLookupField.class = selectedLookupField.class.replace(
          'hidden',
          '',
        );
        this.disableCopiedSourceField(copyId, copiedFieldSourceId);
        this.updateTooltipText(tooltipOptions);
      }
    },
    checkSource(value) {
      const { id } = value;
      const source = this.selectedFieldsOnly.find((source) => source.id === id);
      // make every child visible! the entire source is selected
      source.children.forEach((child) => {
        child.class = child.class.replace('hidden', '');
        // this is only for lookup fields, they will have children as well
        if (child.children) {
          child.children.forEach((grandchild) => {
            grandchild.class = grandchild.class.replace('hidden', '');
          });
        }
        if (child.value && child.value.copyingFields) {
          child.value.copyingFields.forEach((copyingField) => {
            const { lookupFieldAll, lookupFieldSelected } =
              this.enableLookupField(copyingField);
            const fullField = this.getSourceFieldById(child.value.id);
            const tooltipText = getFieldTooltipText(fullField, null, UNSYNCED);
            lookupFieldAll.fieldTooltipText = tooltipText;
            lookupFieldSelected.fieldTooltipText = tooltipText;
          });
        }
        if (child.value && child.value.required) {
          this.allFields = this.addDisabledClassToChild(this.allFields, child);
          this.selectedFieldsOnly = this.addDisabledClassToChild(
            this.selectedFieldsOnly,
            child,
          );
        }
      });
    },
    checkSourceField(value) {
      const { source_id: sourceId, id: fieldId } = value;
      const source = this.selectedFieldsOnly.find(
        (source) => source.id === sourceId,
      );
      this.addFieldToSelectedFieldsOnly(source, fieldId);
      this.disableRequiredFields(source);
      if (value.copyingFields) {
        value.copyingFields.forEach((copyingField) => {
          const { lookupFieldAll, lookupFieldSelected } =
            this.enableLookupField(copyingField);
          const fullField = this.getSourceFieldById(value.id);
          const tooltipText = getFieldTooltipText(fullField, null, UNSYNCED);
          lookupFieldAll.fieldTooltipText = tooltipText;
          lookupFieldSelected.fieldTooltipText = tooltipText;
        });
      }
    },
    disableCopiedSourceField(copiedFieldId, copiedSourceId) {
      // eslint-disable-next-line no-unused-vars
      const { sourceFieldAll, sourceFieldSelected, sourceAll, sourceSelected } =
        this.findSourceFields({ copiedFieldId, copiedSourceId });
      // if the field is required for populations, we don't want to touch its classes
      if (!sourceFieldAll.value.required) {
        sourceFieldAll.class = sourceFieldAll.class.concat(
          ' datasource-source-selector__node-disabled',
        );
        sourceFieldSelected.class = sourceFieldSelected.class.concat(
          ' datasource-source-selector__node-disabled',
        );
      }
      sourceFieldAll.value.requiredForLookup = true;
      sourceFieldSelected.value.requiredForLookup = true;
      // add newly disabled field to checkedCopyingFields
      const keyVals = {
        type: SOURCE_FIELD,
        sourceId: copiedSourceId,
        fieldId: copiedFieldId,
      };
      const copiedFieldKey = getKey(keyVals);
      if (!sourceAll.value.checkedCopyingFields)
        sourceAll.value.checkedCopyingFields = [];
      const checkedCopyingFields = [
        ...sourceAll.value.checkedCopyingFields,
        copiedFieldKey,
      ];
      sourceAll.value = { ...sourceAll.value, checkedCopyingFields };
      sourceSelected.value = { ...sourceSelected.value, checkedCopyingFields };
    },
    disableLookupField({ copyingId, copyingRelId, copyingSourceId }) {
      // if it's already checked, we don't want to disable it
      if (this.isFieldChecked(copyingId)) return;
      const {
        lookupFieldAll,
        lookupFieldSelected,
        parentLookupFieldAll,
        parentLookupFieldSelected,
      } = this.findLookupFields({ copyingId, copyingRelId, copyingSourceId });
      lookupFieldAll.class = lookupFieldAll.class.concat(
        ' datasource-source-selector__node-disabled',
      );
      lookupFieldSelected.class = lookupFieldSelected.class.concat(
        ' datasource-source-selector__node-disabled',
      );
      lookupFieldAll.value.requiresUncheckedField = true;
      lookupFieldAll.value.requiresUncheckedField = true;
      const newDisabledChildrenList =
        this.getDisabledChildKeys(parentLookupFieldAll);
      parentLookupFieldAll.value.disabledChildren = newDisabledChildrenList;
      parentLookupFieldSelected.value.disabledChildren =
        newDisabledChildrenList;
      return { lookupFieldAll, lookupFieldSelected };
    },
    disableRequiredFields(source) {
      if (source.value.hasRequiredFields) {
        source.children.forEach((child) => {
          if (child.value && child.value.required) {
            this.allFields = this.addDisabledClassToChild(
              this.allFields,
              child,
            );
            this.selectedFieldsOnly = this.addDisabledClassToChild(
              this.selectedFieldsOnly,
              child,
            );
          }
        });
      }
    },
    enableCopiedSourceField(copiedFieldId, copiedSourceId, lookupFieldId) {
      // eslint-disable-next-line no-unused-vars
      const { sourceFieldAll, sourceFieldSelected, sourceAll, sourceSelected } =
        this.findSourceFields({ copiedFieldId, copiedSourceId });
      const checkedFieldsForSourceField = this.getCheckedFieldsForSource(
        sourceFieldAll.value.copyingFields,
        lookupFieldId,
        false,
      );
      if (!checkedFieldsForSourceField.length) {
        const enabledClass = 'datasource-source-selector__child';
        const keyVals = {
          type: SOURCE_FIELD,
          sourceId: copiedSourceId,
          fieldId: copiedFieldId,
        };
        const copiedFieldKey = getKey(keyVals);
        if (!sourceAll.value.checkedCopyingFields)
          sourceAll.value.checkedCopyingFields = [];
        const checkedCopyingFields = [
          ...sourceAll.value.checkedCopyingFields,
        ].filter((key) => key !== copiedFieldKey);
        sourceAll.value = { ...sourceAll.value, checkedCopyingFields };
        sourceSelected.value = {
          ...sourceSelected.value,
          checkedCopyingFields,
        };
        if (!sourceFieldAll.value.required) {
          sourceFieldAll.class = enabledClass;
          sourceFieldSelected.class = enabledClass;
        }
        // this is no longer required, because the linked field has been removed!
        sourceFieldAll.value.requiredForLookup = false;
        sourceFieldSelected.value.requiredForLookup = false;
      }
    },
    enableLookupField({ copyingId, copyingRelId, copyingSourceId }) {
      const enabledClass = 'datasource-source-selector__child';
      const {
        lookupFieldAll,
        lookupFieldSelected,
        parentLookupFieldAll,
        parentLookupFieldSelected,
      } = this.findLookupFields({ copyingId, copyingRelId, copyingSourceId });
      lookupFieldAll.class = enabledClass;
      lookupFieldSelected.class = enabledClass;
      lookupFieldAll.value.requiresUncheckedField = false;
      lookupFieldSelected.value.requiresUncheckedField = false;

      const newDisabledChildrenList =
        this.getDisabledChildKeys(parentLookupFieldAll);
      parentLookupFieldAll.value.disabledChildren = newDisabledChildrenList;
      parentLookupFieldSelected.value.disabledChildren =
        newDisabledChildrenList;

      return { lookupFieldAll, lookupFieldSelected };
    },
    enableRequiredField(sourceIndex, childIndex) {
      this.selectedFieldsOnly[sourceIndex].children[childIndex].class =
        'datasource-source-selector__child';
      this.allFields[sourceIndex].children[childIndex].class =
        'datasource-source-selector__child';
    },
    findLookupFields({ copyingId, copyingRelId, copyingSourceId }) {
      const copiedSourceIndex = this.allFields.findIndex(
        (source) => source.id === copyingSourceId,
      );
      const parentLookupFieldIndex = this.allFields[
        copiedSourceIndex
      ].children.findIndex(
        (field) => field.relationship_id === copyingRelId && field.children,
      );
      const parentLookupFieldAll =
        this.allFields[copiedSourceIndex].children[parentLookupFieldIndex];
      const parentLookupFieldSelected =
        this.selectedFieldsOnly[copiedSourceIndex].children[
          parentLookupFieldIndex
        ];
      const lookupFieldIndex = parentLookupFieldAll.children.findIndex(
        (lookupField) => lookupField.id === copyingId,
      );
      const lookupFieldAll =
        this.allFields[copiedSourceIndex].children[parentLookupFieldIndex]
          .children[lookupFieldIndex];
      const lookupFieldSelected =
        this.selectedFieldsOnly[copiedSourceIndex].children[
          parentLookupFieldIndex
        ].children[lookupFieldIndex];
      return {
        lookupFieldAll,
        lookupFieldSelected,
        parentLookupFieldAll,
        parentLookupFieldSelected,
      };
    },
    findSourceFields({ copiedFieldId, copiedSourceId }) {
      const sourceIndex = this.allFields.findIndex(
        (source) => source.id === copiedSourceId,
      );
      const fieldIndex = this.allFields[sourceIndex].children.findIndex(
        (field) => field.id === copiedFieldId,
      );
      return {
        sourceFieldAll: this.allFields[sourceIndex].children[fieldIndex],
        sourceFieldSelected:
          this.selectedFieldsOnly[sourceIndex].children[fieldIndex],
        sourceAll: this.allFields[sourceIndex],
        sourceSelected: this.selectedFieldsOnly[sourceIndex],
      };
    },
    getCheckedFieldsForSource(
      copyingFields,
      lookupFieldId,
      wasLookupFieldChecked,
    ) {
      return copyingFields.filter((field) => {
        const isCurrentUncheckedField =
          !wasLookupFieldChecked && field.copyingId === lookupFieldId;
        if (isCurrentUncheckedField) return;
        const isCurrentCheckedField =
          wasLookupFieldChecked && field.copyingId === lookupFieldId;
        return (
          isCurrentCheckedField ||
          this.selectedFields.some((key) => key.includes(field.copyingId))
        );
      });
    },
    getDisabledChildKeys(lookupParent) {
      return lookupParent.children
        .filter((child) => child.value && child.value.requiresUncheckedField)
        .map((child) => child.key);
    },
    isFieldChecked(id) {
      return this.selectedFields.some((key) => key.includes(id.toString()));
    },
    orderNodes(nodes) {
      return orderBy(nodes, ['title']);
    },
    onFieldSelected(info) {
      const { checked } = info.nodeInfo;
      const checkedNodeValue = info.nodeInfo.node.value;
      if (!checked && checkedNodeValue.required) return;
      if (
        checkedNodeValue.requiresUncheckedField ||
        checkedNodeValue.requiredForLookup ||
        checkedNodeValue.hasCheckedCopyingFields
      )
        return;
      this.$emit('fields-changed', info);
      switch (checkedNodeValue.type) {
        case SOURCE:
          checked
            ? this.checkSource(checkedNodeValue)
            : this.uncheckSource(checkedNodeValue);
          break;
        case SOURCE_FIELD:
          checked
            ? this.checkSourceField(checkedNodeValue)
            : this.uncheckSourceField(checkedNodeValue);
          break;
        case LOOKUP_FIELD:
          checked
            ? this.checkLookupField(checkedNodeValue)
            : this.uncheckLookupField(checkedNodeValue);
          break;
        default:
          break;
      }
    },
    uncheckLookupField(value) {
      const {
        source_id: sourceId,
        copies_source_field_id: copyId,
        relationship_id: relId,
        id,
      } = value;
      const sourceIndex = this.selectedFieldsOnly.findIndex(
        (source) => source.id === sourceId,
      );
      const source = this.selectedFieldsOnly[sourceIndex];
      const parentLookupFieldIndex = source.children.findIndex(
        (field) => field.relationship_id === relId && field.children,
      );
      const isParentLookupField = !copyId;
      const copiedFieldSourceId = source.relationships.find(
        (rel) => rel.id === relId,
      ).references_source_id;
      let tooltipOptions = {
        lookupFieldId: id,
        relId,
        lookupSourceId: sourceId,
        copiedFieldId: copyId,
        copiedSourceId: copiedFieldSourceId,
        messageTypeField: UNSYNCED,
        messageTypeLookup: UNSYNCED,
        wasLookupFieldChecked: false,
      };
      const parentLookupField = source.children[parentLookupFieldIndex];
      if (isParentLookupField) {
        parentLookupField.children.forEach((child) => {
          this.enableCopiedSourceField(
            child.copies_source_field_id,
            copiedFieldSourceId,
            child.id,
          );
          tooltipOptions = {
            lookupFieldId: child.id,
            relId,
            lookupSourceId: sourceId,
            copiedFieldId: child.copies_source_field_id,
            copiedSourceId: copiedFieldSourceId,
            messageTypeLookup: UNSYNCED,
            wasLookupFieldChecked: false,
          };
          this.updateTooltipText(tooltipOptions);
        });
      } else {
        this.enableCopiedSourceField(copyId, copiedFieldSourceId, id);
        this.updateTooltipText(tooltipOptions);
      }
    },
    uncheckSource(value) {
      if (value.hasRequiredFields) {
        const sourceIndex = this.selectedFieldsOnly.findIndex(
          (source) => source.id === value.id,
        );
        const sourceTable = this.selectedFieldsOnly[sourceIndex].table;
        const requiredColumnsForSource = this.requiredColumns[sourceTable];
        this.selectedFieldsOnly[sourceIndex].children.forEach(
          (child, index) => {
            if (requiredColumnsForSource.includes(child.column)) {
              this.enableRequiredField(sourceIndex, index);
            }
          },
        );
      }
    },
    uncheckSourceField(value) {
      if (!value.copyingFields) return;
      value.copyingFields.forEach((copyingField) => {
        const { lookupFieldAll, lookupFieldSelected } =
          this.disableLookupField(copyingField);
        const fullField = this.getSourceFieldById(value.id);
        const tooltipText = getFieldTooltipText(
          fullField,
          null,
          UNSYNCED_DISABLED,
        );
        lookupFieldAll.fieldTooltipText = tooltipText;
        lookupFieldSelected.fieldTooltipText = tooltipText;
      });
    },
    updateTooltipText({
      lookupFieldId,
      relId,
      lookupSourceId,
      copiedFieldId,
      copiedSourceId,
      messageTypeLookup,
      wasLookupFieldChecked,
    }) {
      const valueForGetLookupFields = {
        copyingId: lookupFieldId,
        copyingRelId: relId,
        copyingSourceId: lookupSourceId,
      };
      const valueForGetSourceFields = { copiedFieldId, copiedSourceId };
      const {
        lookupFieldAll,
        lookupFieldSelected,
        parentLookupFieldAll,
        parentLookupFieldSelected,
      } = this.findLookupFields(valueForGetLookupFields);
      const { sourceFieldAll, sourceFieldSelected, sourceAll, sourceSelected } =
        this.findSourceFields(valueForGetSourceFields);

      lookupFieldAll.fieldTooltipText = getFieldTooltipText(
        sourceFieldAll,
        sourceAll,
        messageTypeLookup,
      );
      lookupFieldSelected.fieldTooltipText = getFieldTooltipText(
        sourceFieldSelected,
        sourceSelected,
        messageTypeLookup,
      );

      let fieldMessageType;

      const checkedCopiedFields = this.getCheckedFieldsForSource(
        sourceFieldAll.value.copyingFields,
        lookupFieldId,
        wasLookupFieldChecked,
      );
      if (!checkedCopiedFields.length) {
        fieldMessageType = UNSYNCED;
      } else {
        fieldMessageType =
          checkedCopiedFields.length > 1 ? CANNOT_UNSYNC_PLURAL : CANNOT_UNSYNC;
      }

      sourceFieldAll.fieldTooltipText = getFieldTooltipText(
        checkedCopiedFields,
        parentLookupFieldAll,
        fieldMessageType,
      );
      sourceFieldSelected.fieldTooltipText = getFieldTooltipText(
        checkedCopiedFields,
        parentLookupFieldSelected,
        fieldMessageType,
      );
    },
  },
};
</script>
<style lang="pcss" scoped>
.datasource-source-selector {
  @apply flex flex-col border border-neutral-border rounded-bts-base;
}

.datasource-source-selector__tabs {
  @apply border-none;
}
</style>

<style lang="pcss">
.datasource-source-selector {
  .ant-input {
    @apply rounded-bts-md bg-white;
  }
  .ant-tabs-content {
    @apply max-h-[250px] overflow-y-auto overflow-x-hidden leading-normal;
  }
  .ant-tabs-nav .ant-tabs-tab {
    @apply w-auto;
  }
  .ant-tabs-ink-bar {
    @apply w-auto;
  }
  .ant-tabs-nav {
    @apply py-0;
  }
  .datasource-source-selector__parent {
    .ant-tree-checkbox {
      @apply pl-16;
    }
    .ant-tree-checkbox {
      @apply ml-12 pl-0;
    }
    .ant-tree-node-content-wrapper {
      @apply w-[380px];
    }
  }
  .bitts-tabs .ant-tabs-nav .ant-tabs-tab {
    @apply px-0 mx-16 text-m;
  }
  .datasource-source-selector__child {
    @apply pl-44 pr-8;

    .ant-tree-node-content-wrapper {
      @apply w-[356px];
    }
  }
  .datasource-source-selector__node-disabled {
    cursor: not-allowed;

    .ant-tree-checkbox {
      @apply opacity-50;
      cursor: not-allowed;
    }
    .ant-tree-node-content-wrapper {
      @apply text-neutral-text/50;
      cursor: not-allowed !important;
    }
  }
  .datasource-source-selector__node-lookup {
    @apply last:pb-8 pl-24;
  }
  .bitts-tree .ant-tree li ul {
    @apply p-0;
  }

  .bitts-tabs .ant-tabs-content {
    @apply px-0 pt-0;
  }
}
</style>
