import {
  createRouter as _createRouter,
  createWebHistory,
  isNavigationFailure,
} from 'vue-router';

import useCopilotTracking from '@copilot/composables/useCopilotTracking';
import { COPILOT_BASE_PATH } from '@copilot/constants/router';
import { COPILOT_SOURCE_EXTENSION } from '@copilot/constants/sources';
import { useCopilotStore } from '@copilot/stores/CopilotStore';

import { EVENT_SITES } from '@/constants/analytics';
import { TEMP_DISABLE_CHROME_BILLING_GATE } from '@/constants/feature_flags';
import { handleLogout, routingOverride } from '@/constants/router';
import itly from '@/itly';
import { getAndClearLSNextUrl, ls } from '@/local_storage';
import { useBillingStore, useFeatureFlagStore } from '@/stores/index';

const BareLayout = () => import('@copilot/layout/BareLayout.vue');
const BaseLayout = () => import('@copilot/layout/BaseLayout.vue');

const LoggedOut = () => import('@copilot/pages/LoggedOut.vue');
const MaintenanceMode = () => import('@/pages/MaintenanceMode.vue');
const NoAccess = () => import('@copilot/pages/NoAccess.vue');
const NotFound = () => import('@copilot/pages/NotFound.vue');
const UpgradeRequired = () => import('@copilot/pages/UpgradeRequired.vue');

const Account = () => import('@copilot/pages/Account/Account.vue');
const Contact = () => import('@copilot/pages/Contacts/Contact.vue');
const ContactList = () => import('@copilot/pages/Contacts/ContactList.vue');
const ExtensionIgnoredDomains = () =>
  import('@copilot/pages/ExtensionIgnoredDomains.vue');
const Home = () => import('@copilot/pages/Home.vue');
const Login = () => import('@copilot/pages/Login/Login.vue');
const Outreach = () => import('@copilot/pages/Outreach.vue');
const Overlap = () => import('@copilot/pages/Overlaps/Overlap.vue');
const Overlaps = () => import('@copilot/pages/Overlaps/Overlaps.vue');
const Play = () => import('@copilot/pages/Plays/Play.vue');
const PlayList = () => import('@copilot/pages/Plays/PlayList.vue');
const SafarLoginHelp = () => import('@copilot/pages/SafariLogInHelp.vue');
const Settings = () => import('@copilot/pages/Settings.vue');

export const createRouter = (store) => {
  const billingStore = useBillingStore();
  const copilotStore = useCopilotStore();
  const featureFlagStore = useFeatureFlagStore();

  const userHasNoAccess = () => {
    return !store.isLoggedIn || store.userHasNoOrg || store.orgMarkedAsFailed;
  };

  // Billing Tier Check
  // User must have Connector for Copilot at minimum, and Supernode for some
  const upgradeRequired = () => {
    if (
      copilotStore.source === COPILOT_SOURCE_EXTENSION &&
      featureFlagStore.hasFeatureFlag(TEMP_DISABLE_CHROME_BILLING_GATE)
    ) {
      // Feature flag is on to temporary disable gating for chrome folks
      return false;
    }
    return (
      billingStore.isFreeTier ||
      (copilotStore.requiresEnterprise && !billingStore.isEnterpriseTier)
    );
  };

  const enforceAuthentication = async (to, from, next, checkEa = true) => {
    if (!store.isLoggedIn) {
      next({
        name: 'login',
        query: {
          next: to.fullPath,
          source: to.query.source,
          sso: to.query.sso,
        },
      });
      return;
    }

    /* Make sure we are in the right org */
    const currentOrganizationId = ls.orgId.get()?.toString();
    const queryOrganizationId = to.query.organization_id?.toString();
    if (queryOrganizationId && currentOrganizationId !== queryOrganizationId) {
      const newUrl = new URL(window.location.href);

      /* Org doesn't exist, remove it from the URL to prevent infinite loop */
      const org = store.authorizations.find(
        (auth) =>
          auth.organization.id === parseInt(queryOrganizationId) &&
          (auth.role !== null ||
            (this.isCopilot && auth.sales_edge_role !== null)),
      );
      if (!org) {
        newUrl.searchParams.delete('organization_id');
      } else {
        /* Change org */
        ls.orgId.set(parseInt(queryOrganizationId));
      }
      window.location.replace(newUrl.toString());
    }

    /* No access check */
    const nextRoute = await routingOverride({ to, from, store }, checkEa, true);
    if (nextRoute !== null || userHasNoAccess()) {
      next({
        name: 'no_access',
        query: { next: encodeURIComponent(to.fullPath) },
      });
      return;
    }

    if (upgradeRequired()) {
      next({
        name: 'upgrade_required',
        query: { next: encodeURIComponent(to.fullPath) },
      });
      return;
    }

    next();
  };

  const router = _createRouter({
    history: createWebHistory(COPILOT_BASE_PATH),
    routes: [
      {
        name: 'main',
        path: '/',
        beforeEnter(_to, _from, next) {
          const newSession = ls.newSession.get();

          if (newSession && !store.userHasNoOrg) {
            itly.userLoggedIn({ event_site: EVENT_SITES.LOGIN_SALES_INTEL });
            ls.newSession.set();
          }

          const goNext = getAndClearLSNextUrl();
          if (goNext) {
            // This is very heavy handed, but necessary to ensure the ?source= gets picked up in app.js
            window.location.replace(`/copilot-view${goNext}`);
            return;
          }
          next({ name: 'home' });
        },
      },
      {
        /*
         * UNAUTHENTICATED
         */
        path: '/',
        component: BareLayout,
        children: [
          {
            name: 'login',
            path: 'login',
            beforeEnter(_to, _from, next) {
              if (store.isLoggedIn) {
                next(getAndClearLSNextUrl() || { name: 'home' });
              } else {
                next();
              }
            },
            component: Login,
          },
          {
            name: 'logged_out',
            path: '/logged-out',
            component: LoggedOut,
          },
          {
            name: 'logout',
            path: '/logout',
            beforeEnter(to, _from, _next) {
              handleLogout(
                `${COPILOT_BASE_PATH}/logged-out${to.query?.source ? `?source=${to.query.source}` : ''}`,
              );
            },
          },
          {
            name: 'maintenance_mode',
            component: MaintenanceMode,
            path: '/',
          },
          {
            name: 'safari_login_help',
            component: SafarLoginHelp,
            path: 'safari-login-help',
            /* By including the routes below it allows us to reuse logic for what happens when you click to upgrade or talk to sales */
          },
          {
            name: 'self-serve-connector-seats',
            path: 'connector-seats',
            beforeEnter(to, _from, _next) {
              window.open(`/self-serve${to.fullPath}`, '_blank');
            },
          },
          {
            name: 'self-serve-purchase-connector',
            beforeEnter(to, _from, _next) {
              window.open(`/self-serve${to.fullPath}`, '_blank');
            },
            path: 'purchase-connector',
          },
        ],
      },
      {
        // The switch_org route doesn't need to be authenticated primarily
        // because the user may be coming from the admin panel and we can
        // go ahead and set the org context before potentially asking the
        // user to login. In other words, if the user is not logged in and
        // they hit this route, we'll first set the org in local storage,
        // then reload the page, then the user will be asked to log in and
        // they'll already be targeting the org they want. We also want
        // this route to not pass through the "userNeedsToRegister" check
        // which happens for full-fledged application routes because the
        // user may have just registered and the pinia store might not have
        // information about the org, thus thinking they need to register
        // when they just have
        name: 'switch_org',
        path: '/switch-org',
        beforeEnter(to, from, _next) {
          ls.orgId.set(to.query.organization_id);
          // if there is a next in the url, use that
          const nextPath = from.query?.next
            ? decodeURIComponent(from.query.next)
            : from.fullPath;
          const [path, query] = nextPath.split('?');
          const gotoQuery = new URLSearchParams(query || '');
          // Add indicator that this is coming from an org switch, so we post to chrome.
          gotoQuery.set('fromOrgSwitch', true);
          window.location.replace(
            `${COPILOT_BASE_PATH}${path}?${gotoQuery.toString()}`,
          );
        },
      },
      {
        /*
         * AUTHENTICATED, NON-APPLICATION ROUTES
         */
        path: '/',
        beforeEnter(to, _from, next) {
          if (!store.isLoggedIn) {
            next({ name: 'login', query: { next: to.fullPath } });
          } else {
            next();
          }
        },
        component: BareLayout,
        children: [
          {
            name: 'outreach',
            path: 'outreach',
            component: Outreach,
          },
          {
            name: 'no_access',
            path: 'no-access',
            beforeEnter(_to, _from, next) {
              if (userHasNoAccess()) {
                next();
              } else {
                router.go(-1);
              }
            },
            component: NoAccess,
          },
          {
            name: 'upgrade_required',
            path: 'upgrade-required',
            beforeEnter(_to, _from, next) {
              if (upgradeRequired()) {
                next();
              } else {
                router.go(-1);
              }
            },
            component: UpgradeRequired,
          },
          {
            path: '/:pathMatch(.*)*',
            name: 'not_found',
            component: NotFound,
          },
        ],
      },
      {
        /*
         * AUTHENTICATED
         */
        path: '/',
        beforeEnter: enforceAuthentication,
        component: BaseLayout,
        children: [
          {
            name: 'home',
            path: 'home',
            component: Home,
          },
          {
            name: 'account',
            path: '/account/:recordId',
            component: Account,
          },
          {
            name: 'contacts',
            path: '/contacts/:recordId',
            component: ContactList,
          },
          {
            name: 'contact',
            path: '/contacts/:recordId/:email',
            component: Contact,
          },
          {
            name: 'plays',
            path: '/plays/:recordId',
            component: PlayList,
          },
          {
            name: 'play',
            path: '/plays/:recordId/:id',
            component: Play,
          },
          {
            name: 'overlaps',
            path: '/overlaps/:recordId',
            component: Overlaps,
          },
          {
            name: 'overlaps-detail',
            path: '/overlaps/:recordId/:partnerRecordId/:tab',
            component: Overlap,
          },
          {
            name: 'gong-engage',
            path: '/gong-engage',
            beforeEnter(to, _from, next) {
              // Gong specifies that they provide the ID as a query param called "account-id"
              // Here we pull it out and use it to go to our "overlaps" route
              const { 'account-id': accountId, ...rest } = to.query;
              next(
                `/account/${accountId}?${new URLSearchParams(rest).toString()}`,
              );
            },
          },
          {
            name: 'settings',
            path: '/settings',
            component: Settings,
          },
          {
            name: 'extension_ignored_domains',
            path: '/extension-ignored-domains',
            component: ExtensionIgnoredDomains,
          },
        ],
      },
    ],
  });

  router.beforeEach((_to, _from, next) => {
    if (store.initializationFailed) {
      return;
    }
    next();
  });

  router.beforeEach((to, _from, next) => {
    if (store.maintenanceModeEvent && to.name !== 'maintenance_mode') {
      next({ name: 'maintenance_mode' });
    } else {
      next();
    }
  });

  router.onError((error) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')
    ) {
      window.location.reload();
    }
  });

  // Want to track page views in our analytics tooling
  router.afterEach((to) => {
    // Overlaps detail page has its own tracking
    if (to.name === 'overlaps-detail') return;

    const copilotStore = useCopilotStore();
    const copilotTracking = useCopilotTracking();
    if (window.analytics) {
      const trackingObject = {
        source: copilotStore.source,
        ...copilotTracking.getTrackedFields(),
      };
      if (store.state?.currentOrg?.id) {
        trackingObject.organization_id = store.state.currentOrg.id;
      }
      if (store.state?.currentUser) {
        if (store.state.currentUser.id) {
          trackingObject.user_id = store.state.currentUser.id;
        }
        if (store.state.currentUser.email) {
          trackingObject.email = store.state.currentUser.email;
        }
      }
      window.analytics.page(trackingObject);
    }
  });

  // Handle NavigationDuplicated errors
  // https://github.com/vuejs/vue-router/issues/2881
  const originalPush = router.push;
  router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        console.warn(err);
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  const originalReplace = router.replace;
  router.replace = function replace(location, onResolve, onReject) {
    if (onResolve || onReject) {
      return originalReplace.call(this, location, onResolve, onReject);
    }
    return originalReplace.call(this, location).catch((err) => {
      if (isNavigationFailure(err)) {
        // resolve err
        console.warn(err);
        return err;
      }
      // rethrow error
      return Promise.reject(err);
    });
  };

  return router;
};
