<template>
  <BittsModal
    name="create-population-modal"
    :loading="loading"
    :visible="true"
    :show-buttons="false"
    title="Create a Population"
    :show-divider="true"
    @closed="modalClosed"
  >
    <template #content>
      <div class="c-create-populations">
        <div class="c-create-populations__content">
          <div
            v-for="popType in standardTypesAndDescriptions"
            :key="popType.title"
            class="c-create-populations__population-type-standard"
            @click="goToEditor(popType.title)"
          >
            <div
              v-if="!doesStandardPopulationExist(popType.title)"
              class="c-create-populations__population-type"
            >
              <img
                :src="popTypeImage(popType)"
                class="c-create-populations__img"
              />
              <div class="flex flex-col ml-16">
                <span class="c-create-populations__population-name">{{
                  popType.title
                }}</span>
                <span class="c-create-populations__population-description">
                  {{ popType.description }}
                </span>
              </div>
            </div>
          </div>
          <div class="mt-16">
            <span class="text-xs text-neutral-500 uppercase tracking-wider">
              Need something specific?
            </span>
            <div
              class="c-create-populations__population-type mt-8"
              @click="goToEditor('Custom')"
            >
              <img
                src="@/assets/pngs/empty-state-custom-pops.png"
                class="c-create-populations__img"
              />
              <div class="flex flex-col ml-16">
                <span class="c-create-populations__population-name"
                  >Custom</span
                >
                <span class="c-create-populations__population-description">
                  A specific segment of your data.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-create-populations__footer">
        <BittsButton
          text="Cancel"
          type="neutral"
          variant="outline"
          class="w-full"
          @click="modalClosed"
        />
      </div>
    </template>
  </BittsModal>
</template>

<script>
import { BittsButton, BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { mapState } from 'pinia';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { MAP_POP_NAME_TO_STANDARD_TYPE } from '@/constants/standard_populations';
import { usePopulationsStore } from '@/stores';

const STANDARD_TYPES_AND_DESCRIPTIONS = [
  {
    title: 'Prospects',
    description: 'Potential customers for your business.',
    pngPathName: 'standard-pops-prospects.png',
  },
  {
    title: 'Open Opportunities',
    description: 'Accounts with opportunities in your pipeline.',
    pngPathName: 'standard-pops-open-opps.png',
  },
  {
    title: 'Customers',
    description: 'All accounts and people that are current customers.',
    pngPathName: 'standard-pops-customers.png',
  },
];

export default {
  name: 'CreatePopulationModal',
  components: {
    BittsButton,
    BittsModal,
  },

  setup() {
    useHead({
      title: 'New Population - Crossbeam',
    });
    const { iteratively } = useIteratively();
    return { iteratively };
  },

  data() {
    return {
      loading: false,
      standardTypesAndDescriptions: STANDARD_TYPES_AND_DESCRIPTIONS,
    };
  },
  computed: {
    ...mapState(usePopulationsStore, ['populations']),
    standardPopulations() {
      if (this.populations) {
        return this.populations.filter((pop) => pop.standard_type);
      }
      return [];
    },
  },
  methods: {
    doesStandardPopulationExist(popType) {
      const standardType = MAP_POP_NAME_TO_STANDARD_TYPE[popType];
      return !!this.standardPopulations.find(
        (pop) => pop.standard_type === standardType,
      );
    },
    goToEditor(popType) {
      const route = {
        name: 'create_population',
      };
      if (popType !== 'Custom') {
        const standardType = MAP_POP_NAME_TO_STANDARD_TYPE[popType];
        route.query = {
          standardType,
        };
      }
      this.iteratively.userClickedCreatePopulation({
        event_site: EVENT_SITES.CREATE_POPULATION_MODAL,
        population_type: route.query?.standardType
          ? route.query.standardType
          : 'custom',
      });
      this.$router.push(route);
    },
    async modalClosed() {
      await this.$router.push({ name: 'populations' });
    },
    popTypeImage(popType) {
      return new URL(
        `../../assets/pngs/${popType.pngPathName}`,
        import.meta.url,
      ).href;
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-create-populations__content {
  @apply px-24 pt-16 pb-40 flex flex-col;
}

.c-create-populations__footer {
  @apply px-36 pb-24;
}

.c-create-populations__header {
  @apply flex items-center justify-between p-24;
}

.c-create-populations__img {
  width: 52px;
}

.c-create-populations__loading {
  @apply py-240;
}

.c-create-populations__population-name {
  @apply text-neutral-800;
}

.c-create-populations__population-description {
  @apply text-neutral-500 text-sm;
}

.c-create-populations__population-type {
  @apply cursor-pointer py-16 pl-14 border border-neutral-200 border-solid rounded-bts-base flex;
}

.c-create-populations__population-type-standard {
  @apply mt-8;
}

.c-create-populations__population-type-standard:first-child {
  @apply mt-0;
}
</style>
