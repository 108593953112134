<template>
  <BittsLoading :is-loading="pageLoading" />
</template>

<script>
import { BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';

import useAuth from '@/composables/useAuth';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

export default {
  components: { BittsLoading },
  setup() {
    useHead({
      title: 'SSO Academy Login - Crossbeam',
    });
    const { isLoggedIn } = useAuth();

    return { isLoggedIn };
  },
  data() {
    return {
      pageLoading: true,
    };
  },
  async created() {
    const query = this.$route?.query;
    const academyCourse = query?.next ? query.next : '/';
    if (this.isLoggedIn) {
      const url = urls.academy.login;
      try {
        const { data: tokenLoginUrl } = await axios.post(url);
        const urlWithCourse = `${tokenLoginUrl.url}&next=${academyCourse}`;
        this.pageLoading = false;
        this.redirectToSkillJar(urlWithCourse);
      } catch (_err) {
        this.addErrorFlash({
          message: 'There was an error logging into Crossbeam Academy',
          description:
            'If this error persists, please contact <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>',
        });
        this.$router.push('/home');
      }
    } else {
      this.$router.push(`/login?next=/academy/login?next=${academyCourse}`);
    }
  },
  methods: {
    redirectToSkillJar(url) {
      window.location.replace(url);
    },
    ...mapActions(useFlashesStore, ['addErrorFlash']),
  },
};
</script>
