<template>
  <div @click.stop>
    <BittsModal
      :visible="visible"
      :loading="loading"
      :saving="saving"
      :disabled="!selectedStandardPopType"
      title="Convert to Standard Population"
      @closed="onClose"
      @saved="onSave"
    >
      <template #subtitle>
        This population will be renamed and converted to a standard Population.
        This name change will be reflected in all reports.
      </template>
      <template #content>
        <BittsSelect
          v-model="selectedStandardPopType"
          form-label="Standard Population"
          :options="missingStandardPopulations"
          :allow-clear="true"
          :searchable="false"
          placeholder="Select Existing Population"
          class="my-8"
        />
      </template>
    </BittsModal>
  </div>
</template>

<script>
import { BittsModal, BittsSelect } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions } from 'pinia';

import { ALL_STANDARD_POPULATIONS } from '@/constants/standard_populations';
import { useFlashesStore, usePopulationsStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'ConvertToStandardModal',
  components: { BittsModal, BittsSelect },
  props: {
    hasPopulationRefresh: {
      type: Boolean,
      default: false,
    },
    population: {
      type: Object,
      required: true,
    },
    standardPopulations: {
      type: Array,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'population-converted'],
  data() {
    return {
      saving: false,
      loading: false,
      selectedStandardPopType: null,
      showConvertToStandardModal: false,
    };
  },
  computed: {
    missingStandardPopulations() {
      const missingPops = ALL_STANDARD_POPULATIONS.filter((standardPop) => {
        return !this.standardPopulations.find(
          (pop) => pop.standard_type === standardPop.standard_type,
        );
      }).map((pop) => {
        return {
          ...pop,
          value: pop.standard_type,
          label: pop.name,
        };
      });
      return missingPops;
    },
    selectedStandardPopulation() {
      return ALL_STANDARD_POPULATIONS.find(
        (pop) => pop.standard_type === this.selectedStandardPopType,
      );
    },
  },
  async created() {
    this.loading = true;
    const populationsStore = usePopulationsStore();
    await populationsStore.readySync;
    this.loading = false;
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    onClose() {
      this.$emit('closed');
      this.selectedStandardPopType = null;
    },
    async onSave() {
      this.saving = true;
      try {
        const url = urls.populations.patch_v3(this.population.id);
        const payload = {
          name: this.selectedStandardPopulation.name,
          standard_type: this.selectedStandardPopulation.standard_type,
          description: null,
        };
        if (this.hasPopulationRefresh) {
          payload.description = this.population.description || null;
        }
        await axios.patch(url, payload);
      } catch (_error) {
        this.addErrorFlash({
          message: 'Population Not Saved',
          description:
            'Please try again or contact support@crossbeam.com for help.',
        });
      } finally {
        this.$emit('population-converted');
        this.saving = false;
        this.addSuccessFlash({ message: 'Population Converted' });
      }
    },
  },
};
</script>
<style lang="pcss">
.c-convert-to-standard {
  .multiselect__input {
    margin-left: 0 !important;
  }
}
</style>

<style lang="pcss" scoped>
.c-convert-to-standard {
  min-height: 100px;
}

.c-convert-to-standard__body {
  @apply p-24;
}

.c-convert-to-standard__header {
  @apply flex flex-col p-24;
}

.c-convert-to-standard__title {
  @apply flex items-center justify-between pb-8;
}
</style>
