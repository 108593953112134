<template>
  <BittsLayout :is-page="true" layout-classes="py-0">
    <template #content>
      <PopulationDetail
        ref="populationDetail"
        :population-id="populationId"
        @population-change="onPopulationChange"
      />
      <BittsModal
        save-text="Save Changes"
        cancel-text="Discard Changes"
        :visible="showUnsavedChangesModal"
        :content-text="unsavedChangesModalText"
        title="Save Population Changes"
        :use-cancel-button-emitter="true"
        @saved="onSaveChangesViaModal"
        @closed="onCloseChangesViaModal"
        @cancelled="onDiscardChangesViaModal"
      />
    </template>
  </BittsLayout>
</template>

<script>
import { BittsLayout, BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import PopulationDetail from '@/components/populations/PopulationDetail.vue';

import { usePopulationsStore } from '@/stores';

export default {
  name: 'EditPopulation',
  components: {
    PopulationDetail,
    BittsModal,
    BittsLayout,
  },
  beforeRouteLeave(to, _from, next) {
    if (this.hasPopulationChanged) {
      this.nextRoute = to;
      this.showUnsavedChangesModal = true;
      next(false);
    } else {
      next();
    }
  },
  setup() {
    const route = useRoute();
    const populationsStore = usePopulationsStore();

    const populationId = parseInt(route.params.population_id);
    const population = computed(() =>
      populationsStore.getPopulationById(populationId),
    );

    useHead({
      title: computed(() => population.value?.name ?? ''),
      titleTemplate: 'Edit - %s - Crossbeam',
    });

    return {
      population,
      populationId,
    };
  },
  data() {
    return {
      hasPopulationChanged: false,
      nextRoute: null,
      showUnsavedChangesModal: false,
    };
  },
  computed: {
    unsavedChangesModalText() {
      return 'Your population has unsaved changes. Would you like to save them before leaving?';
    },
  },
  methods: {
    onPopulationChange(hasChanges) {
      this.hasPopulationChanged = hasChanges;
    },
    async onDiscardChangesViaModal() {
      this.showUnsavedChangesModal = false;
      this.hasPopulationChanged = false;
      await this.$router.push({
        ...this.nextRoute,
      });
    },
    async onSaveChangesViaModal() {
      await this.$refs.populationDetail.onSavePopulation();
      await this.$router.push({
        ...this.nextRoute,
      });
      this.showUnsavedChangesModal = false;
    },
    onCloseChangesViaModal() {
      this.showUnsavedChangesModal = false;
    },
  },
};
</script>
