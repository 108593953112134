<template>
  <div>
    <BittsModal
      v-if="feed"
      :visible="true"
      :loading="loading"
      :show-buttons="false"
      :no-padding="true"
      @closed="modalClosed"
    >
      <template #title>
        <div class="c-datasource-settings__header">
          <BittsSvg
            class="w-32 h-32 mr-8"
            :svg="feed.integration.type + 'Icon'"
          />
          <span class="text-xl font-bold text-neutral-text-strong">
            {{ feed.integration.friendly_name }} Settings
          </span>
        </div>
      </template>
      <template #content>
        <div v-if="feed" class="c-datasource-settings">
          <div
            v-if="requireFieldsSelectionBeforeAnySync"
            class="c-datasource-settings__section"
          >
            <div class="c-datasource-settings__section-header">
              <div>Customize Synced Fields</div>
            </div>
            <p class="text-neutral-text pt-4">
              Once you hit import, data syncing will begin immediately but could
              take up to 30 minutes to fully import. We’ll email you when it’s
              ready!
            </p>
          </div>
          <div v-else>
            <div class="flex flex-col pl-24 items-start">
              <div class="flex items-center gap-4">
                <h3>Connection Details</h3>
                <BittsTag v-if="feed.is_sandbox" variant="rounded">
                  Sandbox
                </BittsTag>
              </div>
              <p v-if="feedConnectedAt" class="text-sm text-neutral-text">
                {{ feedConnectedAt }}
                <span v-if="connectorName"> by {{ connectorName }} </span>
              </p>
              <button
                v-if="feed.integration.has_auth_step"
                class="text-info-text cursor-pointer"
                @click="controlAuthorizeFlow(feed)"
              >
                Click here to reauthorize in
                {{ feed.integration.friendly_name }}
              </button>
            </div>
            <div
              class="c-datasource-settings__section px-24 flex items-center justify-between"
            >
              <div class="c-datasource-settings__section-header">
                Integration Status
              </div>
              <div
                :class="feedStatus.statusBgColor"
                class="text-primary-accent text-sm py-4 px-8 rounded-bts-sm"
              >
                {{ feedStatus.text }}
              </div>
            </div>
            <div class="c-datasource-settings__section px-24">
              <div>
                <div class="c-datasource-settings__section-header">
                  <span
                    >Sync Data from {{ feed.integration.friendly_name }}</span
                  >
                </div>
              </div>
              <BittsSwitch v-model="isSyncing" :disabled="isSaving" />
            </div>
            <BittsAlert
              v-if="feedHasErrorMessage"
              :message="feed.error_message"
              color="error"
              class="mt-8 mx-24"
            />
          </div>
          <div class="c-datasource-settings__section px-24">
            <BittsSelect
              v-model="selectedFrequency"
              :options="frequencyOptions"
              :disabled="true"
              placeholder="Select Frequency"
              class="c-datasource-settings__freq-search"
              form-label="Update Frequency"
            />
          </div>
          <div class="c-datasource-settings__section px-24">
            <div class="w-full">
              <div
                v-if="!requireFieldsSelectionBeforeAnySync"
                class="c-datasource-settings__section-header mb-4"
              >
                <span>Fields to Sync</span>
              </div>
              <DataSourceSelector
                :fields-pending="allFields.length === 0"
                :initial-all-fields="allFields"
                :initial-selected-fields-only="selectedFieldsOnly"
                :required-columns="requiredColumns"
                :selected-fields="selectedSourceFields"
                @fields-changed="onFieldsChanged"
                @refresh-fields="refreshFieldsDebounced"
              />
              <div v-if="feedFreq" class="c-datasource-settings__note">
                Changes may take up to {{ feedFreq.toLowerCase() }} to take
                effect.
              </div>
              <BittsAlert
                v-if="error"
                :description="error"
                color="error"
                message="Unable to save feed settings"
                class="mt-12"
              />
              <BittsAlert
                v-if="isAnyFieldUnchecked"
                message="This change may impact Reports"
                description="Removing fields may remove filters from Reports.
                  Be sure you want to make this change."
                color="warning"
                class="mt-12"
              />
            </div>
          </div>
          <div class="flex items-center justify-end px-24 pt-24">
            <BittsButton
              :disabled="isSaving"
              type="danger"
              text="Remove This Data Source"
              class="mr-16"
              @click="confirmDeleteFeed"
            />
            <BittsButton
              v-if="requireFieldsSelectionBeforeAnySync"
              :disabled="isSaving"
              text="Import Data"
              data-testid="data-source-settings-import"
              @click="saveFeed"
            />
            <BittsButton
              v-else
              :text="isSaving ? 'Saving...' : 'Save Changes'"
              :disabled="isSaving"
              @click="saveFeed"
            />
          </div>
        </div>
      </template>
    </BittsModal>
    <BittsModal
      :title="prompt.deleteHeaderText"
      :content-text="prompt.deleteText"
      :save-text="prompt.deleteButtonText"
      :cancel-text="prompt.deleteCancelButtonText"
      :loading="isDeleting"
      :visible="showConfirmDelete"
      confirm-type="danger"
      @saved="deleteFeed"
      @closed="hideConfirmDelete"
    />
    <BittsModal
      title="Reauthorize Google Sheets?"
      :content-text="prompt.googleReauthText"
      save-text="Continue"
      :visible="showConfirmReauthorize"
      @saved="reauthorize(feed)"
      @closed="hideConfirmReauthorize"
    />
  </div>
</template>

<script>
import {
  BittsAlert,
  BittsButton,
  BittsModal,
  BittsSelect,
  BittsSvg,
  BittsSwitch,
  BittsTag,
} from '@crossbeam/bitts';

import { debounce, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { mapActions, mapState } from 'pinia';

import DataSourceSelector from '@/components/data-sources/DataSourceSelector.vue';

import useFeedDetails from '@/composables/useFeedDetails';
import {
  ALL_FIELDS,
  CANNOT_UNSYNC,
  CANNOT_UNSYNC_PLURAL,
  CONTAINS_COPIED,
  CONTAINS_LOOKUPS,
  LOOKUP_FIELD,
  LOOKUP_PARENT,
  SELECTED_FIELDS,
  SOURCE,
  SOURCE_FIELD,
  SYNCED,
  UNSYNCED,
  UNSYNCED_DISABLED,
  getFieldTooltipText,
  getIconTooltipText,
  getKey,
  getRequiredColumns,
  isInPopDefinition,
  isValidSourceField,
  patchFeed,
  patchSources,
} from '@/constants/data_sources';
import { captureException } from '@/errors';
import {
  useDataSharesStore,
  useFeedsStore,
  useFlashesStore,
  usePopulationsStore,
  useSourcesStore,
  useTeamStore,
} from '@/stores';

export default {
  name: 'DataSourceSettingsModal',
  components: {
    BittsSvg,
    DataSourceSelector,
    BittsAlert,
    BittsButton,
    BittsSelect,
    BittsModal,
    BittsSwitch,
    BittsTag,
  },
  props: {
    feedId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { feedStatus, statuses, feedHasErrorMessage, reauthorize } =
      useFeedDetails({ feedId: props.feedId });
    const sourcesStore = useSourcesStore();
    const feedsStore = useFeedsStore();
    return {
      feedStatus,
      statuses,
      feedHasErrorMessage,
      reauthorize,
      sourcesStore,
      feedsStore,
    };
  },
  data() {
    return {
      loading: true,
      isDeleting: false,
      isSaving: false,
      isSyncing: false,
      sourceFieldsPatch: [],
      sourcesPatch: [],
      selectedFrequency: null,
      selectedSourceFields: [],
      allFrequencies: {
        360: '6 Hours',
        720: '12 Hours',
        1440: '24 Hours',
        10080: '1 Week',
      },
      prompt: {
        deleteHeaderText: 'Delete Data Source',
        deleteCancelButtonText: 'No, Cancel',
        deleteButtonText: 'Yes, Continue',
        deleteText:
          'Are you sure you want to delete your connection? Removing this data source will permanently delete all associated populations and sharing rules. This cannot be undone.',
        googleReauthText:
          'All Google Sheets used in Crossbeam must be shared with or owned by ' +
          'the new user you are authenticating.',
      },
      allFields: [],
      selectedFieldsOnly: [],
      showConfirmationModal: false,
      error: null,
      originalSettingsLookup: {},
      requiredColumns: {},
      showConfirmReauthorize: false,
      showDataSourceSettingsModal: false,
      showConfirmDelete: false,
    };
  },
  computed: {
    ...mapState(useTeamStore, ['authorizations']),
    ...mapState(usePopulationsStore, ['populations']),
    feedConnectedAt() {
      if (this.feed?.connected_at) {
        return DateTime.fromISO(this.feed.connected_at).toFormat(
          'MMMM d, yyyy',
        );
      }
      return null;
    },
    feed() {
      return this.getFeedById(this.feedId);
    },
    feedFreq() {
      return this.allFrequencies[this.selectedFrequency];
    },
    frequencyOptions() {
      return Object.keys(this.allFrequencies)
        .map((freq) => parseInt(freq))
        .sort((freq1, freq2) => freq1 - freq2)
        .map((frequency) => ({
          value: frequency,
          label: this.allFrequencies[frequency],
        }));
    },
    connectorName() {
      const connector = this.authorizations.filter(
        (authorization) => authorization.user.email === this.feed.connected_by,
      )[0];
      return connector
        ? `${connector.user.first_name} ${connector.user.last_name}`
        : this.feed.connected_by;
    },
    changedSourceFieldSettings() {
      return this.sourceFieldsPatch.filter(
        (field) =>
          (this.originalSettingsLookup[field.id] && !field.is_visible) ||
          (!this.originalSettingsLookup[field.id] && field.is_visible),
      );
    },
    connectionPrefixText() {
      return this.feed.is_sandbox
        ? 'Sandbox connection established'
        : 'Connection established';
    },
    sourcesForFeed() {
      return this.getSourcesByFeedId(this.feedId);
    },
    isAnyFieldUnchecked() {
      return (
        this.sourceFieldsPatch.filter((field) => !field.is_filterable).length >
        0
      );
    },
    requireFieldsSelectionBeforeAnySync() {
      return this.feedStatus.type === this.statuses.REQUIRE_SOURCES_SELECTION;
    },
  },
  async created() {
    this.refreshFieldsDebounced = debounce(this.refreshFields, 1000);
    this.loading = true;
    await Promise.all([this.feedsStore.readySync, this.sourcesStore.readySync]);

    if (this.feed) {
      await this.refreshData();
      this.loading = false;
    } else {
      this.hideModal();
    }
  },
  mounted() {
    this.showDataSourceSettingsModal = true;
  },
  methods: {
    ...mapActions(useDataSharesStore, ['refreshDataSharesStore']),
    ...mapActions(useFlashesStore, [
      'addSuccessFlash',
      'addErrorFlash',
      'addUnhandledError',
    ]),
    ...mapActions(usePopulationsStore, ['refreshPopulationsStore']),
    ...mapActions(useFeedsStore, [
      'removeFeed',
      'refreshFeedsStore',
      'getFeedById',
    ]),
    ...mapActions(useSourcesStore, [
      'refreshSourcesStore',
      'getSourcesByFeedId',
      'getSourceById',
      'getSourceFieldById',
    ]),
    addUnsyncableFieldToSourceValue(sourceIndex, copiedField) {
      const allValue = this.allFields[sourceIndex].value;
      const selectedValue = this.selectedFieldsOnly[sourceIndex].value;
      if (!allValue.checkedCopyingFields) {
        allValue.checkedCopyingFields = [];
      }
      const keyVals = {
        type: SOURCE_FIELD,
        sourceId: copiedField.source_id,
        fieldId: copiedField.id,
      };
      const key = getKey(keyVals);
      const fieldHasKey = allValue.checkedCopyingFields.includes(key);
      if (!fieldHasKey) {
        const checkedCopyingFields = [...allValue.checkedCopyingFields, key];
        this.allFields[sourceIndex].value = {
          ...allValue,
          checkedCopyingFields,
        };
        this.selectedFieldsOnly[sourceIndex].value = {
          ...selectedValue,
          checkedCopyingFields,
        };
      }
    },
    addLookupFieldToSource(sourceId, field) {
      // index is the same for selected and all, so we only have to loop once
      const sourceWithLookupIndex = this.selectedFieldsOnly.findIndex(
        (source) => source.id === sourceId,
      );
      // if it's the first, we need to add the title
      const copiedSourceId = this.allFields[
        sourceWithLookupIndex
      ].relationships.find(
        (rel) => rel.id === field.relationship_id,
      ).references_source_id;
      const copiedSource = this.getSourceById(copiedSourceId);
      // since the parent can be intermediate and not fully "selected"
      // we just need to check if any of the children are
      const areAnyChildLookupFieldsSelected = field.childrenSelected.some(
        (child) => child.is_visible && child.is_filterable,
      );
      const disabledChildNodeKeys = field.childrenSelected
        .filter((child) => child.value.requiresUncheckedField)
        .map((child) => child.key);
      this.selectedFieldsOnly[sourceWithLookupIndex].children.push({
        ...field,
        children: field.childrenSelected,
        class: `datasource-source-selector__node-lookup
           ${areAnyChildLookupFieldsSelected ? '' : 'hidden'}`,
        hasIcon: true,
        tooltipText: getIconTooltipText(copiedSource, LOOKUP_PARENT),
        value: {
          ...field.value,
          disabledChildren: disabledChildNodeKeys,
        },
      });
      this.allFields[sourceWithLookupIndex].children.push({
        ...field,
        children: field.childrenAll,
        class: 'datasource-source-selector__node-lookup',
        hasIcon: true,
        tooltipText: getIconTooltipText(copiedSource, LOOKUP_PARENT),
        value: {
          ...field.value,
          disabledChildren: disabledChildNodeKeys,
        },
      });
      if (!this.allFields[sourceWithLookupIndex].hasIcon) {
        const iconInfo = {
          hasIcon: true,
          tooltipText: getIconTooltipText(null, CONTAINS_LOOKUPS),
        };
        this.allFields[sourceWithLookupIndex] = {
          ...this.allFields[sourceWithLookupIndex],
          ...iconInfo,
        };
        this.selectedFieldsOnly[sourceWithLookupIndex] = {
          ...this.selectedFieldsOnly[sourceWithLookupIndex],
          ...iconInfo,
        };
      }
    },
    changeAllFieldsForSource(source, isChecked, fieldsToNotUpdate = []) {
      source.fields.forEach((sourceField) => {
        if (!isValidSourceField(sourceField)) return;
        let keyVals = {
          type: SOURCE_FIELD,
          sourceId: source.id,
          fieldId: sourceField.id,
        };
        if (sourceField.relationship_id) {
          keyVals = {
            ...keyVals,
            type: LOOKUP_FIELD,
            relId: sourceField.relationship_id,
          };
        }
        const key = getKey(keyVals);
        if (!fieldsToNotUpdate.includes(key)) {
          this.updateField(isChecked, sourceField.id);
        }
      });
    },
    controlAuthorizeFlow(feed) {
      if (feed.integration.type === 'google_sheets') {
        this.confirmReauthorize();
      } else {
        this.reauthorize(feed);
      }
    },
    getUncheckedRequiredSiblings(sourceId) {
      if (!sourceId) return [];
      const source = this.getSourceById(sourceId);
      const requiredSiblings = this.requiredColumns[source.table];
      const requiredSiblingsKeys = source.fields
        .filter((field) => requiredSiblings.includes(field.column))
        .map((col) => {
          return {
            ...col,
            key: getKey({
              type: SOURCE_FIELD,
              sourceId: col.source_id,
              fieldId: col.id,
              relId: col.relationship_id,
            }),
          };
        });
      return requiredSiblingsKeys.filter(
        (sib) => !this.selectedSourceFields.includes(sib),
      );
    },
    onFieldsChanged({ checkedKeys, nodeInfo }) {
      const checkedNodeValue = nodeInfo.node.value;
      const { checked } = nodeInfo;
      const {
        id,
        type,
        source_id: sourceId,
        copies_source_field_id: copiesId,
        disabledChildren = [],
        checkedCopyingFields = [],
      } = checkedNodeValue;
      let fieldsRequiredDisabled = checkedKeys.filter(
        (key) => !key.includes('parent'),
      );
      // we have to remove the disabled key itself and also the parent
      if (disabledChildren && disabledChildren.length) {
        fieldsRequiredDisabled = fieldsRequiredDisabled.filter(
          (key) => !disabledChildren.includes(key) && !key.includes(id),
        );
      }
      // add required keys if they exist
      if (checkedCopyingFields && checkedCopyingFields.length) {
        checkedCopyingFields.forEach((key) => fieldsRequiredDisabled.push(key));
      }
      if (checked) {
        const uncheckedRequiredSiblings =
          this.getUncheckedRequiredSiblings(sourceId);
        if (uncheckedRequiredSiblings.length) {
          uncheckedRequiredSiblings.forEach((sib) => {
            if (!fieldsRequiredDisabled.includes(sib.key)) {
              fieldsRequiredDisabled.push(sib.key);
              this.updateField(checked, sib.id);
            }
          });
        }
      }
      this.selectedSourceFields = fieldsRequiredDisabled;
      const isChildLookupField = type === LOOKUP_FIELD && !!copiesId;
      if (type === SOURCE_FIELD || isChildLookupField) {
        this.updateField(checked, id);
        if (checked || this.wasLastUncheckedFieldInSource(sourceId)) {
          // make sure source is checked if any field is checked
          this.updateSource(checked, sourceId);
        }
      } else if (type === SOURCE) {
        const source = this.getSourceById(id);
        const fieldsToNotUpdate = checked
          ? disabledChildren
          : checkedCopyingFields;
        this.changeAllFieldsForSource(source, checked, fieldsToNotUpdate);
        if (checked || !fieldsToNotUpdate.length) {
          this.updateSource(checked, id);
        }
      } else {
        const sourceId = checkedNodeValue.source_id;
        const source = this.getSourceById(sourceId);
        const lookupSource = {
          fields: source.fields.filter(
            (field) =>
              field.relationship_id === checkedNodeValue.relationship_id,
          ),
          id: source.id,
        };
        const fieldsToNotUpdate = checked
          ? disabledChildren
          : checkedCopyingFields;
        this.changeAllFieldsForSource(lookupSource, checked, fieldsToNotUpdate);
      }
    },
    async refreshData() {
      //  Defaulting to 360 here adds some protection against errors using
      //  this view between muncher code being deployed and the migration being
      //  run. After both happen, this change can be reverted.
      this.requiredColumns = await getRequiredColumns(this.feed);
      this.selectedFrequency = this.allFrequencies[this.feed.frequency]
        ? this.feed.frequency
        : 360;
      this.isSyncing = !this.feed.is_paused;
      await this.setFields();
    },
    async refreshFields() {
      await this.refreshSourcesStore();
      await this.setFields();
    },
    updateField(checked, id) {
      this.sourceFieldsPatch = this.sourceFieldsPatch
        .filter((sourceField) => sourceField.id !== id)
        .concat({
          id,
          is_filterable: checked,
          is_visible: checked,
        });
    },
    updateSource(checked, id) {
      this.sourcesPatch = this.sourcesPatch
        .filter((source) => source.id !== id)
        .concat({
          id,
          sync_enabled: checked,
        });
    },
    confirmDeleteFeed() {
      this.showDataSourceSettingsModal = false;
      this.showConfirmDelete = true;
      this.showConfirmationModal = true;
    },
    confirmReauthorize() {
      this.showDataSourceSettingsModal = false;
      this.showConfirmReauthorize = true;
      this.showConfirmationModal = true;
    },
    async modalClosed() {
      if (this.showConfirmationModal) {
        this.showConfirmationModal = false;
        return;
      }
      let name = 'data-sources';
      const standardType = this.$route.query.standardType;
      switch (this.$route.name) {
        case 'data-sources-settings':
          name = 'data-sources';
          break;
        case 'edit-snowflake-population-data':
          name = 'edit_population';
          break;
        case 'edit-new-snowflake-population-data':
          name = 'create_population';
          break;
        default:
          break;
      }
      const route = { name };
      if (standardType) {
        route.query = {
          standardType,
        };
      }
      await this.$router.push(route);
      this.showDataSourceSettingsModal = false;
    },
    hideModal() {
      this.$router.push({ name: 'data-sources' });
    },
    getChildren(source, pops, requiredColumns) {
      const childMap = {
        childrenForAllFields: [],
        childrenForSelectedFields: [],
        lookupFields: [],
        hasRequiredFields: false,
      };
      source.fields.forEach((field) => {
        // set children
        const isValidField =
          !field.column.startsWith('_sdc_') && !field.syncing_disabled_reason;
        if (!isValidField) return;
        if (!field.relationship_id || !field.copies_source_field_id) {
          const type = SOURCE_FIELD;
          const keyVals = {
            type,
            sourceId: field.source_id,
            fieldId: field.id,
          };
          const childKey = getKey(keyVals);
          const isSelected = field.is_visible && field.is_filterable;
          const blockedPops = pops.filter((pop) =>
            isInPopDefinition(pop, field.id),
          );
          const isRequired =
            blockedPops.length ||
            (requiredColumns[source.table] &&
              requiredColumns[source.table].includes(field.column));
          // if an org is already syncing id, we want to let them deselect it
          // but we are not letting them re-select it
          const isLookupIdAndSelected = isSelected && field.relationship_id;
          if (!field.relationship_id || isLookupIdAndSelected) {
            if (isSelected) {
              this.selectedSourceFields.push(childKey);
              this.originalSettingsLookup[field.id] = true;
            }
            const newField = {
              ...field,
              title: field.display_name,
              lowercaseTitle: field.display_name.toLowerCase(),
              value: {
                type,
                id: field.id,
                source_id: field.source_id,
                required: isRequired,
              },
              key: childKey,
              isRequired,
              blockedPops,
              tableName: source.table,
              scopedSlots: {
                title: 'data-source',
              },
            };
            if (isRequired) {
              childMap.hasRequiredFields = true;
            }
            const childForAllFields = {
              ...newField,
              class: this.getClassForChild(isSelected, isRequired, ALL_FIELDS),
            };
            const childForSelectedFields = {
              ...newField,
              class: this.getClassForChild(
                isSelected,
                isRequired,
                SELECTED_FIELDS,
              ),
            };
            childMap.childrenForAllFields.push(childForAllFields);
            childMap.childrenForSelectedFields.push(childForSelectedFields);
          }
          if (field.relationship_id) {
            childMap.lookupFields.push(field);
          }
        } else {
          childMap.lookupFields.push(field);
        }
      });
      return childMap;
    },
    async setFields() {
      this.selectedSourceFields = [];
      let allLookupFields = [];
      this.allFields = [];
      this.selectedFieldsOnly = [];
      this.sourcesForFeed.forEach((source) => {
        const keyVals = {
          type: SOURCE,
          sourceId: source.id,
        };
        const key = getKey(keyVals);
        const {
          childrenForAllFields,
          childrenForSelectedFields,
          lookupFields,
          hasRequiredFields,
        } = this.getChildren(source, this.populations, this.requiredColumns);

        const newSource = {
          ...source,
          title: source.table,
          lowercaseTitle: source.table.toLowerCase(),
          key,
          value: {
            type: SOURCE,
            id: source.id,
            hasRequiredFields,
          },
          scopedSlots: {
            title: 'data-source',
          },
        };
        this.allFields.push({
          ...newSource,
          children: this.orderNodes(childrenForAllFields),
          class: 'datasource-source-selector__parent',
        });
        this.selectedFieldsOnly.push({
          ...newSource,
          children: this.orderNodes(childrenForSelectedFields),
          class: 'datasource-source-selector__parent',
        });
        allLookupFields = [...allLookupFields, ...lookupFields];
      });
      this.setLookupFields(allLookupFields);
      this.orderFields();
    },
    getChildLookupFields(lookupField, title, key, value) {
      const copiedField = this.getSourceFieldById(
        lookupField.copies_source_field_id,
      );
      const isChildChecked =
        lookupField.is_visible && lookupField.is_filterable;
      const isCopiedFieldUnchecked =
        !copiedField.is_visible || !copiedField.is_filterable;
      if (isChildChecked) {
        this.selectedSourceFields.push(key);
        this.originalSettingsLookup[lookupField.id] = true;
      }
      let messageType = isChildChecked ? SYNCED : UNSYNCED;
      if (isCopiedFieldUnchecked) {
        messageType = UNSYNCED_DISABLED;
      }
      const copiedSource = this.getSourceById(copiedField.source_id);
      const genericBaseNode = {
        title,
        lowercaseTitle: title.toLowerCase(),
        key,
        value: {
          ...value,
          requiresUncheckedField: isCopiedFieldUnchecked,
        },
        copiedField,
        fieldTooltipText: getFieldTooltipText(
          copiedField,
          copiedSource,
          messageType,
        ),
        scopedSlots: {
          title: 'data-source',
        },
      };
      return {
        lookupFieldAll: {
          ...lookupField,
          ...genericBaseNode,
          class: this.getClassForChild(
            isChildChecked,
            false,
            ALL_FIELDS,
            isCopiedFieldUnchecked,
          ),
        },
        lookupFieldSelected: {
          ...lookupField,
          ...genericBaseNode,
          class: this.getClassForChild(
            isChildChecked,
            false,
            SELECTED_FIELDS,
            isCopiedFieldUnchecked,
          ),
        },
      };
    },
    getKeyTitleValue(lookupField) {
      const keyVals = {
        type: LOOKUP_FIELD,
        sourceId: lookupField.source_id,
        fieldId: lookupField.id,
        relId: lookupField.relationship_id,
      };
      let key = getKey(keyVals);
      if (!lookupField.copies_source_field_id) {
        key += '__parent';
      }
      const title = lookupField.display_name;
      const value = {
        copies_source_field_id: lookupField.copies_source_field_id,
        relationship_id: lookupField.relationship_id,
        source_id: lookupField.source_id,
        id: lookupField.id,
        type: LOOKUP_FIELD,
      };
      return { key, title, value };
    },
    getLookupFieldsByRelId(lookupFields) {
      const lookupFieldsByRelId = {};
      const copyingLookupFields = [];
      lookupFields.forEach((lookupField) => {
        const { relationship_id: relationshipId } = lookupField;
        if (!lookupFieldsByRelId[relationshipId]) {
          lookupFieldsByRelId[relationshipId] = {
            childrenAll: [],
            childrenSelected: [],
          };
        }
        // if the field isn't copying anything, it's a "parent" lookup field
        const isChild = !!lookupField.copies_source_field_id;
        const { key, title, value } = this.getKeyTitleValue(lookupField);
        if (!isChild) {
          lookupFieldsByRelId[relationshipId] = {
            ...lookupFieldsByRelId[relationshipId],
            ...lookupField,
            title,
            lowercaseTitle: title.toLowerCase(),
            key,
            value,
            scopedSlots: {
              title: 'data-source',
            },
          };
        } else {
          copyingLookupFields.push(lookupField);
          const { lookupFieldAll, lookupFieldSelected } =
            this.getChildLookupFields(lookupField, title, key, value);
          lookupFieldsByRelId[relationshipId].childrenAll.push(lookupFieldAll);
          lookupFieldsByRelId[relationshipId].childrenSelected.push(
            lookupFieldSelected,
          );
        }
      });
      return { lookupFieldsByRelId, copyingLookupFields };
    },
    orderFields() {
      this.allFields = this.allFields.map((source) => {
        const orderedChildren = this.orderNodes(source.children);
        return {
          ...source,
          children: orderedChildren,
        };
      });
      this.selectedFieldsOnly = this.selectedFieldsOnly.map((source) => {
        const orderedChildren = this.orderNodes(source.children);
        return {
          ...source,
          children: orderedChildren,
        };
      });
      this.allFields = this.orderNodes(this.allFields);
      this.selectedFieldsOnly = this.orderNodes(this.selectedFieldsOnly);
    },
    orderNodes(nodes) {
      return orderBy(nodes, ['title']);
    },
    getClassForChild(isChildChecked, isRequired, tab, isUnlinked = false) {
      let classes = 'datasource-source-selector__child ';
      if (tab === SELECTED_FIELDS && !isChildChecked) {
        classes += 'hidden';
      }
      if ((isRequired && isChildChecked) || isUnlinked) {
        classes += ' datasource-source-selector__node-disabled';
      }
      return classes;
    },
    async deleteFeed() {
      this.isDeleting = true;
      try {
        this.showConfirmDelete = false;
        this.showConfirmationModal = false;
        this.showDataSourceSettingsModal = false;

        await this.removeFeed(this.feed.id);

        const dispatches = [
          this.refreshPopulationsStore(),
          this.refreshDataSharesStore(),
          this.refreshFeedsStore(),
        ];
        await Promise.all(dispatches);
        this.modalClosed();
      } catch (err) {
        captureException(err);
        this.addErrorFlash({
          message: 'There was an issue with your delete',
          description: 'If the error persists contact support@crossbeam.com',
        });
      } finally {
        this.isDeleting = false;
      }
    },
    hideConfirmDelete() {
      this.showConfirmDelete = false;
      this.showDataSourceSettingsModal = true;
    },
    hideConfirmReauthorize() {
      this.showConfirmReauthorize = false;
      this.showDataSourceSettingsModal = true;
    },
    async saveFeed() {
      this.error = null;
      const customizedSourceSelection =
        this.feedStatus.type === this.statuses.REQUIRE_SOURCES_SELECTION;
      const savedMessage = customizedSourceSelection
        ? 'Data Source saved'
        : 'Your settings have been saved';
      try {
        this.isSaving = true;
        if (
          this.sourcesPatch.length > 0 ||
          this.changedSourceFieldSettings.length > 0
        ) {
          await patchSources({
            feedId: this.feed.id,
            sources: this.sourcesPatch,
            fields: this.changedSourceFieldSettings,
          });
          this.sourcesPatch = [];
          this.sourceFieldsPatch = [];
        }

        if (
          this.isSyncing !== !this.feed.is_paused ||
          this.selectedFrequency !== this.feed.frequency ||
          customizedSourceSelection
        ) {
          await patchFeed(this.feed.id, {
            is_paused: !this.isSyncing,
            frequency: this.selectedFrequency,
          });
        }
      } catch (err) {
        this.error = err.response?.data?.errors[0];
        if (!this.error) {
          this.addUnhandledError(err);
        }
        captureException(err);
      } finally {
        if (!this.error) {
          await this.refreshFeedsStore();
          await this.refreshDataSharesStore();
          await this.refreshSourcesStore();
          if (!customizedSourceSelection) {
            await this.refreshData();
          }
          this.isSaving = false;
          this.hideModal();
          this.addSuccessFlash({ message: savedMessage });
        }
        this.isSaving = false;
      }
    },
    setLookupFields(lookupFields) {
      const { lookupFieldsByRelId, copyingLookupFields } =
        this.getLookupFieldsByRelId(lookupFields);
      const relIds = Object.keys(lookupFieldsByRelId);
      const sourcesWithLookupFields = [];
      relIds.forEach((id) => {
        const field = lookupFieldsByRelId[id];
        const { source_id: sourceId } = field;
        // if this is the first lookup field of this particular source, we want to
        // add the "lookup fields" tree header to it, this is how we are keeping track of that
        const isSourcesFirstLookupField =
          !sourcesWithLookupFields.includes(sourceId);
        this.addLookupFieldToSource(sourceId, field);
        if (isSourcesFirstLookupField) {
          sourcesWithLookupFields.push(sourceId);
        }
      });
      this.setLinkedSourceFields(copyingLookupFields);
    },
    getCopyingFieldsAndTooltipText(
      copiedFieldAll,
      copiedFieldSelected,
      copyingField,
      isChecked,
    ) {
      const info = {};
      if (!copiedFieldAll.value.copyingFields) {
        copiedFieldAll.value.copyingFields = [];
        copiedFieldSelected.value.copyingFields = [];
      }
      const copyingFieldInfo = this.getCopyingFieldInfo(
        copyingField,
        isChecked,
      );
      const copyingFields = [
        ...copiedFieldAll.value.copyingFields,
        copyingFieldInfo,
      ];
      const value = { copyingFields, isChecked };
      info.tooltipText = this.getTooltipText(value);
      info.copyingFields = copyingFields;
      return info;
    },
    setLinkedSourceFields(copyingLookupFields) {
      copyingLookupFields.forEach((copyingField) => {
        const copiedField = this.getSourceFieldById(
          copyingField.copies_source_field_id,
        );
        const isCopyingFieldChecked =
          copyingField.is_visible && copyingField.is_filterable;
        // just finding indices of the source field that is being copied by the lookup
        const sourceIndex = this.allFields.findIndex(
          (source) => source.id === copiedField.source_id,
        );
        const fieldIndex = this.allFields[sourceIndex].children.findIndex(
          (field) => field.id === copiedField.id,
        );

        // we need this field to populate the link icon tooltip
        const copyingSource = this.getSourceById(copyingField.source_id);
        const lookupFieldParent = copyingSource.fields.find(
          (field) =>
            field.relationship_id === copyingField.relationship_id &&
            !field.copies_source_field_id,
        );

        // for both all and selected, we add the copying field so we can reference it later
        // we also add the linked slot to the source, so we can see the icon in the UI
        const iconInfo = {
          hasIcon: true,
          tooltipText: getIconTooltipText(lookupFieldParent, CONTAINS_COPIED),
        };
        this.allFields[sourceIndex] = {
          ...this.allFields[sourceIndex],
          ...iconInfo,
        };
        this.selectedFieldsOnly[sourceIndex] = {
          ...this.selectedFieldsOnly[sourceIndex],
          ...iconInfo,
        };
        const copiedFieldAll = this.allFields[sourceIndex].children[fieldIndex];
        const copiedFieldSelected =
          this.selectedFieldsOnly[sourceIndex].children[fieldIndex];

        let allClass = copiedFieldAll.class;
        let selectedClass = copiedFieldSelected.class;

        if (isCopyingFieldChecked) {
          this.addUnsyncableFieldToSourceValue(sourceIndex, copiedField);
          if (!allClass.includes('disabled')) {
            allClass += ' datasource-source-selector__node-disabled';
            selectedClass += ' datasource-source-selector__node-disabled';
          }
        }
        const options = {
          copyingField,
          requiredForLookup: isCopyingFieldChecked,
          sourceIndex,
          fieldIndex,
          allClass,
          selectedClass,
        };
        this.updateNode(options);
        const { copyingFields, tooltipText } =
          this.getCopyingFieldsAndTooltipText(
            copiedFieldAll,
            copiedFieldSelected,
            copyingField,
            isCopyingFieldChecked,
          );
        this.allFields[sourceIndex].children[fieldIndex].value.copyingFields =
          copyingFields;
        this.selectedFieldsOnly[sourceIndex].children[
          fieldIndex
        ].value.copyingFields = copyingFields;
        this.allFields[sourceIndex].children[fieldIndex].fieldTooltipText =
          tooltipText;
        this.selectedFieldsOnly[sourceIndex].children[
          fieldIndex
        ].fieldTooltipText = tooltipText;
      });
    },
    getCopyingFieldInfo(copyingField, isCopyingFieldChecked) {
      return {
        copyingId: copyingField.id,
        copyingSourceId: copyingField.source_id,
        copyingRelId: copyingField.relationship_id,
        copyingFieldName: copyingField.display_name,
        initiallyChecked: isCopyingFieldChecked,
      };
    },
    getTooltipText({ copyingFields, isCopyingFieldChecked }) {
      const checkedCopyingFields = copyingFields.filter(
        (field) => field.initiallyChecked,
      );
      if (!isCopyingFieldChecked && !checkedCopyingFields.length) {
        return getFieldTooltipText(null, null, UNSYNCED);
      }
      const messageType =
        checkedCopyingFields.length > 1 ? CANNOT_UNSYNC_PLURAL : CANNOT_UNSYNC;
      return getFieldTooltipText(checkedCopyingFields, null, messageType);
    },
    updateNode({
      sourceIndex,
      fieldIndex,
      requiredForLookup,
      allClass,
      selectedClass,
    }) {
      this.allFields[sourceIndex].children[fieldIndex] = {
        ...this.allFields[sourceIndex].children[fieldIndex],
        class: allClass,
        value: {
          ...this.allFields[sourceIndex].children[fieldIndex].value,
          requiredForLookup,
        },
      };
      this.selectedFieldsOnly[sourceIndex].children[fieldIndex] = {
        ...this.selectedFieldsOnly[sourceIndex].children[fieldIndex],
        class: selectedClass,
        value: {
          ...this.selectedFieldsOnly[sourceIndex].children[fieldIndex].value,
          requiredForLookup,
        },
      };
    },
    wasLastUncheckedFieldInSource(sourceId) {
      // all keys contain their source ids in the format {{type}}__{{fieldId}}__{{sourceId}}
      // we have to pull out the source id to check it against the deselected one
      return !this.selectedSourceFields.some((key) => {
        const keySourceId = key.split('__')[2];
        return parseInt(keySourceId) === sourceId;
      });
    },
  },
};
</script>
<style lang="pcss">
.c-datasource-settings {
  @apply py-24 px-0;
}

.c-datasource-settings__header {
  @apply flex flex-row items-center;
}

.c-datasource-settings__section {
  @apply px-24 pt-20 flex justify-between flex-wrap;
}

.c-datasource-settings__freq-search {
  @apply self-stretch;
}

.c-datasource-settings__section-header {
  @apply font-bold text-neutral-text-strong text-base;
}

.c-datasource-settings__note {
  @apply pt-4 text-base text-neutral-text-placeholder;
}
</style>

<style lang="pcss">
.c-datasource-settings__freq-search {
  .multiselect__tags {
    @apply border border-neutral-border;
  }
}
</style>
