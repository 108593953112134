/* Copilot login popup
 * This is cleared when the tab is closed
 */

import { COPILOT_BASE_PATH } from '@copilot/constants/router';

const isLoginPopup = () => sessionStorage.getItem('loginPopup') === '1';

const isLoginPopupQueryStr = 'isLoginPopup=1';

const setIsLoginPopup = () => {
  if (
    window.location.href.includes(isLoginPopupQueryStr) &&
    !window.location.href.includes(`${COPILOT_BASE_PATH}/login`)
  ) {
    sessionStorage.setItem('loginPopup', '1');
  }
};

const splitText = (text) => {
  const parts = [];
  const regex = /(\*\*.*?\*\*)/g;
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(text)) !== null) {
    const boldText = match[1];
    const normalText = text.slice(lastIndex, match.index);

    if (normalText) {
      parts.push({ text: normalText, bold: false });
    }

    if (boldText.startsWith('**') && boldText.endsWith('**')) {
      parts.push({ text: boldText.slice(2, -2), bold: true });
    } else {
      parts.push({ text: boldText, bold: false });
    }

    lastIndex = regex.lastIndex;
  }

  const remainingText = text.slice(lastIndex);
  if (remainingText) {
    parts.push({ text: remainingText, bold: false });
  }

  return parts;
};
const formatAmount = (amount, currencyCode) => {
  if (amount) {
    if (!currencyCode) {
      currencyCode = 'USD';
    }

    const formatter = new Intl.NumberFormat('en-US', {
      currency: currencyCode,
      style: 'currency',
    });

    return formatter.format(amount);
  }
};

export {
  formatAmount,
  isLoginPopup,
  setIsLoginPopup,
  isLoginPopupQueryStr,
  splitText,
};
