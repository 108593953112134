<template>
  <div
    class="c-sharing-dashboard-pop__content-wrapper"
    :class="{
      'standard-population': isStandardPopulation,
    }"
  >
    <div class="c-sharing-dashboard-pop-content">
      <BittsSvg
        v-if="!population.isEmptyDetail && svg"
        class="w-16 h-16 mr-8 min-w-[16px]"
        :svg
      />
      <p class="truncate">
        {{ population.name }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { BittsSvg } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  population: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  svg: {
    type: String,
    default: null,
  },
});

const isStandardPopulation = computed(() => !!props.population?.standard_type);
</script>
<style scoped lang="pcss">
.c-sharing-dashboard-pop__content-wrapper {
  @apply text-sm text-neutral-text-strong rounded-4 border border-neutral-border inline-flex cursor-default px-4 py-2 bg-white w-full;

  &.standard-population {
    @apply before:content-[''] before:w-2 before:bg-info-accent before:rounded-full before:mr-4;
  }
}

.c-sharing-dashboard-pop-content {
  @apply inline-flex items-center font-bold text-neutral-text py-2 truncate;
}

.standard-population-border {
  @apply h-full rounded-full bg-info-accent w-2 mr-4;
}
</style>
