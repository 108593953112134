<template>
  <RouterLink :to="listLink" class="module-list-tile">
    <div class="flex">
      <BittsAvatarStack
        size="small"
        class="self-start w-[39px] mr-12"
        :org-list="props.list.permissions"
      />
      <div class="module-list-tile__title">
        {{ props.list.name }}
      </div>
    </div>
    <div class="flex flex-col w-full gap-8">
      <div class="module-list-tile__description">
        {{ props.list.description }}
      </div>
      <div class="flex items-center justify-between">
        <div class="module-list-tile__info self-end">
          {{ `${recordNumber} records • Last updated ${lastUpdated}` }}
        </div>
        <BittsUserStack size="small" :users="usersInList" />
      </div>
    </div>
  </RouterLink>
</template>
<script setup>
import { BittsAvatarStack, BittsUserStack } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { useCollaborateStore } from '@/stores';

const props = defineProps({
  list: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const recordNumber = computed(() => props.list.row_count);
const lastUpdated = computed(() =>
  DateTime.fromISO(props.list.updated_at).toRelative(),
);

const { currentOrg, currentUser } = useAuth();
const { getListUsersInOrg } = useCollaborateStore();

const listLink = computed(() => {
  return { name: 'collaborate_list', params: { id: props.list.id } };
});

const ownOrgUsersInList = computed(() => {
  const users = getListUsersInOrg(props.list.id, currentOrg.value.id);
  users.sort((a, b) => {
    if (a.user_id === currentUser.value.id) return -1;
    if (b.user_id === currentUser.value.id) return 1;
    return 0;
  });
  return users;
});

const partnerOrg = computed(() =>
  props.list.permissions.find(
    (org) => org.organization_id !== currentOrg.value.id,
  ),
);
const partnerOrgUsersInList = computed(() =>
  getListUsersInOrg(props.list.id, partnerOrg.value.organization_id),
);
const usersInList = computed(() => [
  ...ownOrgUsersInList.value,
  ...partnerOrgUsersInList.value,
]);
</script>
<style lang="pcss" scoped>
.module-list-tile {
  @apply p-16 rounded-bts-md flex flex-col gap-8;
}
.module-list-tile__description {
  @apply text-neutral-text;
}
.module-list-tile__info {
  @apply text-neutral-text-weak text-sm;
}
.module-list-tile__title {
  @apply text-neutral-text-strong font-bold text-m;
}
</style>
