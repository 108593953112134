<template>
  <BittsModal
    :name="reportModalName"
    save-text="Save Preferences"
    :title="titleText"
    :loading="initiallyLoading"
    :visible="showReportNotificationsModal"
    :show-divider="true"
    :disabled="!areAllEmailsValid"
    @saved="onSave"
    @closed="hide"
  >
    <template #content>
      <div class="c-notification-settings">
        <div class="c-notification-settings__content">
          <BittsSelectTags
            v-model="emails"
            :form-label="{ title: 'Email Addresses' }"
            class="mb-16"
          />
          <span v-if="!areAllEmailsValid" class="text-danger-text mb-24 text-sm"
            >Must be valid email addresses</span
          >
          <div>
            <div v-if="slackApp && slackApp.is_enabled">
              <BittsSelect
                v-model="selectedChannelName"
                :allow-clear="true"
                :form-label="{
                  title: 'Slack Channel',
                  helpText: `Connecting a Slack channel will notify ${ACCOUNT_OWNER_DISPLAY}s directly through Slack mentions. If you are not syncing your ${ACCOUNT_OWNER_DISPLAY} emails, we will use their first and last name.`,
                }"
                :options="slackChannels"
                option-type="svg"
                placeholder="Select Channel"
                class="mb-16"
                @update:model-value="checkChannelStatus"
              />
              <BittsAlert
                v-if="needsReauth"
                :description="reauthDescription"
                message="Your Slack Integration requires an update"
                color="error"
                class="mb-24"
              />
              <BittsAlert
                v-else-if="channelWasDeleted"
                :description="deletedChannelDescription"
                message="We stopped sending Slack notifications to a channel"
                color="error"
                class="mb-24 pt-12"
              >
                <template #link>
                  <a
                    href="https://help.crossbeam.com/en/articles/3639783-slack-app"
                    class="border-b-solid border-b-2 border-neutral-400"
                  >
                    Learn more
                  </a>
                </template>
              </BittsAlert>
              <BittsAlert
                v-else-if="noSharedChannels"
                :description="trySlackConnectDescription"
                message="You should try using Slack Connect"
                color="info"
                class="mb-24 pt-12"
              >
                <template #icon>
                  <span class="text-lg"> 🤝 </span>
                </template>
              </BittsAlert>
              <BittsAlert
                v-if="showCantFindPartnerWarningText"
                :message="getCantFindPartnerWarningText.title"
                :description="getCantFindPartnerWarningText.body"
                color="warning"
                class="mb-24"
              />
              <BittsAlert
                v-if="showSharedWithPartnersText"
                :message="getSharedWithPartnersText.title"
                :description="getSharedWithPartnersText.body"
                color="warning"
                class="mb-24"
              />
            </div>
            <ConnectSlackButton v-else :next-url="nextUrl" />
          </div>
        </div>
        <span v-if="error" class="c-notification-settings__error">
          {{ error }}
        </span>
      </div>
    </template>
  </BittsModal>
</template>

<script>
import {
  BittsAlert,
  BittsModal,
  BittsSelect,
  BittsSelectTags,
} from '@crossbeam/bitts';

import { useVuelidate } from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';
import axios from 'axios';
import { sortBy, uniqBy } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { computed } from 'vue';

import ConnectSlackButton from '@/components/ConnectSlackButton.vue';

import { ACCOUNT_OWNER_DISPLAY } from '@/constants/mdm';
import { PARTNER_GREENFIELD } from '@/constants/reports';
import { captureException } from '@/errors';
import {
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
  useReportsStore,
} from '@/stores';
import urls from '@/urls';

const VALID_SLACK_CHANNEL = /^[a-z\d-_]+$/;
const BEGINS_WITH_HASHTAG = /^#/;
const VALID_EMAIL = /\S+@\S+\.\S+/;

export default {
  components: {
    BittsAlert,
    BittsSelect,
    BittsSelectTags,
    BittsModal,
    ConnectSlackButton,
  },
  props: {
    partnerPopulationIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    reportModalName: {
      type: String,
      default: 'report-notifications',
    },
    reportName: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    showReportNotificationsModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'notifications-modal-hidden'],
  setup(props) {
    const val = computed(() => props.modelValue);

    const rules = {
      modelValue: {
        slack: {
          channel: {
            id: {
              maxLength: maxLength(80),
              validSlackChannel: (id) => {
                return (
                  !id ||
                  (BEGINS_WITH_HASHTAG.test(id) &&
                    VALID_SLACK_CHANNEL.test(id.replace('#', ''))) ||
                  VALID_SLACK_CHANNEL.test(id)
                );
              },
            },
          },
        },
      },
    };
    const v$ = useVuelidate(rules, { modelValue: val });

    return { v$, ACCOUNT_OWNER_DISPLAY };
  },
  data() {
    return {
      deletedChannelDescription:
        "We don't have access to this channel anymore, Crossbeam was either removed or the channel was deleted.",
      emails: [],
      error: null,
      initiallyLoading: false,
      saving: false,
      reauthDescription:
        'This application has new and exciting features and needs to be updated to work properly. Visit the Integrations Page to update this.',
      selectedChannelName: null,
      channelWasDeleted: null,
      sharedChannelInfo: null,
      trySlackConnectDescription:
        'Try using a Slack Connect channel with your partner to send shared alerts and collaborate instantly.',
      typedEmail: null,
      unknownPartnersInChannel: null,
      allPartnersInChannel: null,
    };
  },
  computed: {
    ...mapState(useReportsStore, ['slackApp']),
    ...mapState(usePartnersStore, ['getPartnerOrgById']),
    areAllEmailsValid() {
      // .every returns true on an empty array, which is what we want
      // no emails is a valid notification
      return this.emails.every((email) => this.isEmailValid(email));
    },
    isSelectedChannelShared() {
      return (
        this.selectedChannel &&
        this.selectedChannel.channel_type === 'shared-channel'
      );
    },
    needsReauth() {
      return this.slackApp && this.slackApp.needs_reauth;
    },
    nextUrl() {
      const { name, params } = this.$route;
      const url = this.$router.resolve({
        name,
        params,
        query: {
          show_notification_configs: true,
        },
      }).href;
      return url;
    },
    noSharedChannels() {
      return (
        this.slackChannels &&
        this.slackChannels.every(
          (channel) => channel.channel_type !== 'shared-channel',
        )
      );
    },
    reportId() {
      return this.$route?.params?.report_id || this.$route?.query?.id;
    },
    report() {
      return this.reportId ? this.getByReportId(this.reportId) : null;
    },
    isPartnerDataOnly() {
      return this.report?.consolidated_report_type === PARTNER_GREENFIELD;
    },
    itemLabel() {
      if (this.isPartnerDataOnly) return 'partner record';
      return 'overlap';
    },
    reportPartnerIdList() {
      let partners = [];
      if (this.partnerPopulationIds) {
        partners = uniqBy(
          this.partnerPopulationIds.map(
            (id) => this.getPartnerPopulationById(id)?.organization_id,
          ),
          'id',
        );
      }
      return partners;
    },
    showCantFindPartnerWarningText() {
      // for now we only filter out partner data in a shared channel for partner greenfield
      return (
        this.selectedChannelName &&
        this.isSelectedChannelShared &&
        this.sharedChannelInfo &&
        !this.saving &&
        this.isPartnerDataOnly &&
        !this.allPartnersInChannel
      );
    },
    getCantFindPartnerWarningText() {
      return {
        title:
          "We can't find your report partners in this Slack Connect channel",
        body: `Ensure your partners are in this Slack Connect channel and have the Crossbeam Slack integration installed for us to include their ${this.itemLabel} information in these notifications. Make sure this channel only includes people you want to have this partner record notification`,
      };
    },
    showSharedWithPartnersText() {
      return (
        this.selectedChannelName &&
        this.isSelectedChannelShared &&
        this.sharedChannelInfo &&
        !this.saving &&
        !this.showCantFindPartnerWarningText
      );
    },
    getSharedWithPartnersText() {
      return {
        title: this.sharedChannelInfo?.known_partner_org_ids?.length
          ? `This report is sending notifications to a Slack Connect Channel with ${this.getOrgNames()}`
          : 'This report is sending notifications to a Slack Connect Channel',
        body: this.hasUnknownPartners
          ? `Note, the Crossbeam Slack integration hasn't been added by all organizations in this channel so we don't know who they are. Make sure this channel only includes people you want to have this ${this.itemLabel} information.`
          : '',
      };
    },
    selectedChannel() {
      return this.slackChannels.find(
        (channel) => channel.name === this.selectedChannelName,
      );
    },
    slackChannels() {
      if (!this.slackApp || !this.slackApp.channel_list) return [];
      const channels = this.slackApp.channel_list.map((channel) => {
        return {
          ...channel,
          label: channel.name,
          value: channel.name,
          svg: this.getSvgName(channel),
        };
      });
      return sortBy(channels, 'name');
    },
    toServer() {
      const notifications = [];
      if (this.emails.length > 0 || this.typedEmail) {
        const emails = this.emails;
        if (this.typedEmail && emails.indexOf(this.typedEmail) < 0) {
          emails.push(this.typedEmail);
        }
        notifications.push({
          notification_type: 'email',
          emails: this.emails,
        });
      }
      if (this.selectedChannelName) {
        notifications.push({
          notification_type: 'slack',
          channel_id: this.selectedChannel.id,
        });
      }
      return notifications;
    },
    titleText() {
      let result = 'Notification Settings';
      if (this.reportName) result += ` for ${this.reportName}`;
      return result;
    },
  },
  watch: {
    modelValue() {
      if (!this.initiallyLoading) {
        this.rehydrate();
      }
    },
    report() {
      if (!this.initiallyLoading) {
        this.rehydrate();
      }
    },
  },
  async created() {
    this.initiallyLoading = true;
    await this.loadSlack();
    this.rehydrate();
    if (this.isSelectedChannelShared) {
      try {
        await this.checkChannelStatus(this.selectedChannel.name);
      } catch (error) {
        this.initiallyLoading = false;
        this.error = error.response.data.errors
          ? this.parseErrors(error.response.data.errors)
          : error;
        captureException(error);
      } finally {
        this.initiallyLoading = false;
      }
    }
    this.initiallyLoading = false;
  },
  methods: {
    ...mapActions(usePopulationsStore, ['getPartnerPopulationById']),
    ...mapActions(useFlashesStore, ['addSuccessFlash']),
    ...mapActions(useReportsStore, [
      'getByReportId',
      'loadSlack',
      'refreshReportsStore',
      'saveNotificationConfigs',
    ]),
    addTag(newTag) {
      this.emails.push(newTag);
    },
    async checkChannelStatus(channel) {
      if ((Array.isArray(channel) && !channel.length) || !channel) {
        this.selectedChannelName = null;
        return;
      }
      this.selectedChannelName = channel;
      if (
        this.selectedChannel &&
        this.selectedChannel.channel_type === 'shared-channel'
      ) {
        this.saving = true;
        try {
          const response = await axios.get(
            urls.slack.sharedChannel(this.selectedChannel.id),
          );
          this.sharedChannelInfo = response.data;
          this.hasUnknownPartners = response.data.has_unknown_partners;
          this.allPartnersInChannel = this.reportPartnerIdList.every((id) =>
            response.data.known_partner_org_ids.includes(id),
          );
        } catch (error) {
          this.sharedChannelInfo = null;
          this.hasUnknownPartners = null;
          this.allPartnersInChannel = null;
          this.saving = false;
          this.error = error.response.data.errors
            ? this.parseErrors(error.response.data.errors)
            : error;
          captureException(error);
        } finally {
          this.saving = false;
        }
      } else {
        this.hasUnknownPartners = null;
        this.allPartnersInChannel = null;
        this.sharedChannelInfo = null;
      }
    },
    getSvgName(channel) {
      switch (channel.channel_type) {
        case 'private-channel':
          return 'slackPrivate';
        case 'public-channel':
          return 'slackChannel';
        default:
          return 'slackShared';
      }
    },
    hide() {
      this.typedEmail = null;
      this.error = null;
      this.$emit('notifications-modal-hidden');
      this.rehydrate();
    },
    isEmailValid(email) {
      return VALID_EMAIL.test(email.toLowerCase());
    },
    async onSave() {
      this.v$.$touch();
      if (this.v$.$invalid) return;
      this.saving = true;
      try {
        const response = await this.saveNotificationConfigs({
          reportId: this.reportId,
          notifications: this.toServer,
        });
        this.$emit('update:modelValue', response);
        this.hide();
        this.addSuccessFlash({ message: 'Notification Settings Saved' });
        await this.refreshReportsStore();
      } catch (error) {
        this.saving = false;
        this.error = error.response.data.errors
          ? this.parseErrors(error.response.data.errors)
          : error;
        captureException(error);
      } finally {
        this.saving = false;
      }
    },
    getOrgNames() {
      const orgNames = this.sharedChannelInfo.known_partner_org_ids.map(
        (id) => this.getPartnerOrgById(id).name,
      );
      let orgNamesString = '';
      if (orgNames.length > 1) {
        const commaSeparatedOrgs = orgNames.slice(0, orgNames.length - 1);
        orgNamesString += commaSeparatedOrgs.join(', ');
        orgNamesString += ` and ${orgNames[orgNames.length - 1]}`;
      } else {
        orgNamesString = orgNames[0];
      }
      return orgNamesString;
    },
    parseErrors(errors) {
      return errors
        .map((errorCode) => {
          if (errorCode === 'channel_not_found') {
            return `#${this.slack.channel} is not a channel in your Slack organization.
              Please specify a valid channel and try again.`;
          }
          return errorCode;
        })
        .join(' ');
    },
    rehydrate() {
      const slackNotification = this.modelValue.find(
        (notification) => notification.notification_type === 'slack',
      );
      if (!this.slackNotification) this.selectedChannelName = null;
      // if the current slack channel for notifs is deletesd
      // this will just be 'undefined', which is fine
      if (slackNotification && this.slackChannels) {
        if (slackNotification.channel_id || slackNotification.properties) {
          const slackId =
            slackNotification.channel_id ||
            slackNotification.properties.channel_id;
          this.selectedChannelName = this.slackChannels.find(
            (channel) => channel.id === slackId,
          )?.name;
        } else if (slackNotification.slack_channel) {
          this.selectedChannelName = this.slackChannels.find(
            (channel) => channel.name === slackNotification.slack_channel,
          )?.name;
        }
        this.channelWasDeleted = !this.selectedChannelName;
      }

      this.emails = this.modelValue
        .filter((notification) => notification.notification_type === 'email')
        .reduce(
          (notifications, notification) =>
            notifications.concat(notification.emails),
          [],
        );
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-notification-settings {
  min-height: 100px;
}

.c-notification-settings__header {
  @apply flex items-center justify-between p-24;
}

.c-notification-settings__content {
  @apply flex flex-col;
}

.c-notification-settings__footer {
  @apply flex p-24 pt-0 w-full justify-end;
}

.c-notification-settings__selector {
  @apply border-solid border-2 border-neutral-200 mb-24;
  border-radius: 4px !important;
}

.c-notification-settings__error {
  @apply text-brand-red pb-24;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 12px;
}

.c-notification-settings__email-invites {
  padding-bottom: 12px;
}

.c-notification-settings__loading {
  height: 500px;
}

.c-notification-settings__add-email {
  position: absolute;
  font-size: 24px;
  margin-top: -42px;
  right: 0px;
  padding-right: 40px;
}

.c-notification-settings__text-input {
  font-size: 12px;
}

.c-notification-settings__slack-button {
  width: 160px;
}
</style>
