import { difference, sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { BILLING_TIERS } from '@/constants/billing';
import { PC_INTEGRATIONS, SNOWFLAKE_PUSH } from '@/constants/feature_flags';
import { PCLOUD_INTEGRATIONS } from '@/constants/integrations';
import {
  useBillingStore,
  useConnectionsStore,
  useFeatureFlagStore,
  useOauthApplicationStore,
  usePartnerCloudStore,
} from '@/stores';

export default function useIntegrations() {
  const partnerCloudStore = usePartnerCloudStore();
  const billingStore = useBillingStore();
  const connectionsStore = useConnectionsStore();
  const featureFlagStore = useFeatureFlagStore();
  const oAuthApplicationsStore = useOauthApplicationStore();

  const { isEnterpriseTier, isConnectorTier, billingTier } =
    storeToRefs(billingStore);

  const {
    availableIntegrations,
    enabledFriggs,
    enabledTray,
    enabledSalesEdge,
    slackAppIntegration,
    partnerStackIntegration,
  } = storeToRefs(partnerCloudStore);

  const { oAuthApplications } = storeToRefs(oAuthApplicationsStore);

  const slackIsActive = computed(() => !!slackAppIntegration.value?.is_enabled);

  const partnerStackIsActive = computed(
    () => !!partnerStackIntegration.value?.is_enabled,
  );

  const salesEdgeIsActive = computed(() => enabledSalesEdge.value.length > 0);

  const salesforceIsActive = computed(() =>
    connectionsStore.activeConnections.some(
      (s) => s.integration_type === 'salesforce',
    ),
  );

  const showSalesforcePushInstalled = computed(() => {
    return connectionsStore.activeConnections.some(
      (s) => s.integration_type === 'salesforce' && s.date_last_pushed,
    );
  });

  const snowflakeIsActive = computed(() =>
    connectionsStore.activeConnections.some(
      (s) => s.integration_type === 'snowflake',
    ),
  );

  function findIntegration(integrationType) {
    const { name, tier, avatar, type } = PCLOUD_INTEGRATIONS.find(
      (int) => int.type === integrationType,
    );
    return { name, tier, avatar, type };
  }

  const appsCollectionData = computed(() => {
    return {
      slack: {
        isReleased: slackIsActive.value,
        isActive: slackIsActive.value,
        ...findIntegration('slack'),
      },
      salesforce: {
        isReleased: salesforceIsActive.value,
        isActive: salesforceIsActive.value,
        ...findIntegration('salesforce'),
      },
      salesforce_v2: {
        isActive: salesforceIsActive.value,
        // we are using isReleased as isInstalled on this one
        isReleased: showSalesforcePushInstalled.value && isEnterpriseTier.value,
        ...findIntegration('salesforce_v2'),
      },
      snowflake: {
        isReleased: featureFlagStore.hasFeatureFlag(SNOWFLAKE_PUSH),
        isActive: snowflakeIsActive.value,
        ...findIntegration('snowflake'),
      },
      partner_stack: {
        isReleased: partnerStackIsActive.value,
        isActive: partnerStackIsActive.value,
        ...findIntegration('partner_stack'),
      },
    };
  });

  const enabledIntegrationsLookup = computed(() =>
    allEnabledIntegrations.value.reduce((acc, curr) => {
      acc[curr.type] = true;
      return acc;
    }, {}),
  );

  const inactiveIntegrations = computed(() => {
    const activeOriginalIntegrationsFilteredOut =
      availableIntegrations.value.filter((integration) => {
        // filtering out Frigg hubspot while we move folks to the new Tray Hubspot Custom Object
        const removeLegacyHubspot = integration.type === 'hubspot';
        const removeLegacySalesforcePush = integration.type === 'salesforce_v2';
        if (removeLegacyHubspot) return false;

        if (removeLegacySalesforcePush) return false;

        if (integration.type === 'salesedge-hubspot') {
          return !salesEdgeIsActive.value;
        }

        return !appsCollectionData.value[integration.type]?.isReleased;
      });

    return sortBy(activeOriginalIntegrationsFilteredOut, [
      (integration) => integration.name.toLowerCase(),
    ]);
  });

  const allRecommendedIntegrations = computed(() => {
    return availableIntegrations.value.filter((integration) => {
      if (hasNewHubspotInstalled.value && integration.type === 'tray-hubspot') {
        return true;
      }
      if (integration.type === 'hubspot') return false;
      const removeLegacySalesforcePush = integration.type === 'salesforce_v2';
      if (removeLegacySalesforcePush) return false;

      const removeNewHubspot =
        hasLegacyHubspotInstalled.value && integration.type === 'tray-hubspot';
      if (removeNewHubspot) return false;

      const { tier, recommended } = integration;
      const integrationTier = BILLING_TIERS[tier?.toUpperCase()];
      const connectorIntegration = tier === BILLING_TIERS.CONNECTOR;
      const enterpriseIntegration = tier === BILLING_TIERS.ENTERPRISE;
      if (recommended) {
        if (integrationTier === BILLING_TIERS.FREE) return true;
        if (isEnterpriseTier.value)
          return enterpriseIntegration || connectorIntegration;
        if (isConnectorTier.value) return connectorIntegration;
      }
    });
  });

  const recommendedIntegrations = computed(() => {
    return allRecommendedIntegrations.value.reduce((acc, curr) => {
      if (!enabledIntegrationsLookup.value[curr.type]) acc.push(curr);
      return acc;
    }, []);
  });

  const activeHardCodedIntegrations = computed(() =>
    Object.values(appsCollectionData.value).filter((app) => app.isReleased),
  );

  const allEnabledIntegrations = computed(() => {
    /* Adding a type property to oAuthApps so we can render them in the Installed Integrations */
    const oAuthApps = oAuthApplications.value.map((app) => {
      if (!app.type) app.type = 'oauth';
      return app;
    });
    return sortBy(
      [
        ...activeHardCodedIntegrations.value,
        ...enabledFriggs.value,
        ...enabledTray.value,
        ...enabledSalesEdge.value,
        ...oAuthApps,
      ],
      ['name'],
    );
  });

  const hasLegacyHubspotInstalled = computed(() =>
    enabledFriggs.value?.some((integration) => integration.type === 'hubspot'),
  );
  const hasNewHubspotInstalled = computed(() =>
    featureFlagStore.hasFeatureFlag(PC_INTEGRATIONS.HUBSPOT_V2),
  );

  const allIntegrations = computed(() => {
    const unavailableButPopular = difference(
      inactiveIntegrations.value,
      recommendedIntegrations.value,
      allEnabledIntegrations.value,
    );
    return sortBy(unavailableButPopular, ['name']);
  });

  const superNodeIntegrations = computed(() =>
    allIntegrations.value.filter(isIntegrationSupernode),
  );
  const connectorIntegrations = computed(() =>
    allIntegrations.value.filter((integration) => {
      return (
        isIntegrationConnector(integration) ||
        featureFlagStore.hasFeatureFlag(integration.ff)
      );
    }),
  );

  const availableIntegrationsFromFeatureFlag = computed(() => {
    return inactiveIntegrations.value.filter((integration) => {
      return (
        (!integration.ff && integration.name !== 'PartnerStack') ||
        featureFlagStore.hasFeatureFlag(integration.ff) ||
        integration.includedWithBillingTier === billingTier.value
      );
    });
  });

  const installedConnectorIntegrations = computed(() => {
    return allEnabledIntegrations.value.filter(isIntegrationConnector);
  });

  const availableConnectorIntegrations = computed(() =>
    availableIntegrationsFromFeatureFlag.value.filter(isIntegrationConnector),
  );

  const installedOrAvailableConnectorIntegrations = computed(() => {
    return (
      installedConnectorIntegrations.value.length +
      availableConnectorIntegrations.value.length
    );
  });

  return {
    snowflakeIsActive,
    salesforceIsActive,
    slackIsActive,
    partnerStackIsActive,
    allIntegrations,
    recommendedIntegrations,
    allEnabledIntegrations,
    availableIntegrationsFromFeatureFlag,
    installedOrAvailableConnectorIntegrations,
    installedConnectorIntegrations,
    hasLegacyHubspotInstalled,
    superNodeIntegrations,
    connectorIntegrations,
  };
}

const isIntegrationConnector = (integration) =>
  integration.tier === BILLING_TIERS.CONNECTOR;
const isIntegrationSupernode = (integration) =>
  integration.tier === BILLING_TIERS.ENTERPRISE;
