<template>
  <div class="c-collaborate-opportunities-editor__container">
    <BittsDropdown
      v-click-away="informStopEditing"
      prompt="Select Opportunity"
      :transparent="true"
      :options="opportunityOptions"
      :is-initially-visible="true"
      parent-class="c-collaborate-opportunities-editor__dropdown"
      placement="bottomLeft"
      @visibility-changed="informStopEditing"
    >
      <template #content>
        <BittsLoading :is-loading="loading">
          <div>
            <p class="text-neutral-text-placeholder py-4 px-8">
              Select Opportunity
            </p>
            <div
              v-for="opt in opportunityOptions"
              :key="opt.id"
              class="c-collaborate-opportunities-editor__option"
              @click="onOptionSelected(opt)"
              @mouseover="showTooltip[opt.id] = true"
              @mouseleave="showTooltip[opt.id] = false"
            >
              <OpportunityTooltip
                :opt="opt"
                :show-tooltip="showTooltip[opt.id]"
                placement="right"
                overlay-class="c-collaborate-opportunities-editor__tooltip"
                @tooltip-cta-clicked="onCtaClicked(opt)"
              >
                <div class="truncate">
                  {{ opt.name || '--' }}
                </div>
              </OpportunityTooltip>
            </div>
          </div>
        </BittsLoading>
      </template>
    </BittsDropdown>
  </div>
</template>
<script>
import { BittsDropdown, BittsLoading } from '@crossbeam/bitts';

import axios from 'axios';
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { mixin as VueClickAway } from 'vue3-click-away';

import OpportunityTooltip from '@/components/shared-list/OpportunityTooltip.vue';

import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'SharedListOpportunitiesEditorCell',
  components: {
    BittsDropdown,
    BittsLoading,
    OpportunityTooltip,
  },
  mixins: [VueClickAway],
  props: {
    params: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
  },
  setup(props) {
    const selectedOption = ref(null);
    const loading = ref(true);
    const opportunityOptions = ref([]);
    const showTooltip = reactive({});
    const flashesStore = useFlashesStore();
    const route = useRoute();
    const newData = [...props.params.data.data];
    function onCtaClicked(opt) {
      window.open(opt.crm_record_link, '_blank');
    }

    function informStopEditing() {
      props.params?.api?.stopEditing();
    }

    function findColumnSetNewValue(columnName, value) {
      const allColumns = props.params.columnApi.getColumns();
      const foundCol = allColumns.find(
        (col) => col.colDef.headerName === columnName,
      );
      const foundColIdx = newData.findIndex(
        (col) => col.column_id === foundCol.colId,
      );
      newData.splice(foundColIdx, 1, {
        column_id: foundCol.colId,
        value,
      });
    }

    function onOptionSelected(option) {
      const {
        configured_amount: amount,
        sales_stage: salesStage,
        id: opportunityId,
        name = '--',
      } = option;

      findColumnSetNewValue('Opportunity Stage', salesStage);
      findColumnSetNewValue('Opportunity Amount', amount.toString());
      findColumnSetNewValue('Opportunity Name', name);

      props.params.node.updateData({
        ...props.params.data,
        opportunity: option,
        data: [...newData],
      });
      selectedOption.value = name;
      updateOpportunity(opportunityId);
      props.params.api.redrawRows({ rowNodes: [props.params.node] });
      informStopEditing();
    }

    function getValue() {
      return selectedOption.value;
    }

    async function updateOpportunity(id) {
      const listId = route.params.id;
      const {
        source_id: sourceId,
        record_id: recordId,
        row_id: rowId,
      } = props.params.data;
      const payload = {
        source_id: sourceId,
        master_id: recordId,
        opportunity_id: id,
      };
      try {
        await axios.put(urls.lists.setOpportunity(listId, rowId), payload);
      } catch (e) {
        captureException(e);
        flashesStore.addErrorFlash({
          message:
            'Something went wrong, reach out to our support team for help',
        });
      }
    }

    onMounted(async () => {
      loading.value = true;
      const { source_id: sourceId, record_id: recordId } =
        props.params?.data || {};
      try {
        const { data } = await axios.get(
          urls.lists.getOpportunity(sourceId, recordId),
        );
        opportunityOptions.value = data.items;
        if (!data.items.length) {
          selectedOption.value = 'No opportunity available';
          informStopEditing();
        }
      } catch (e) {
        captureException(e);
        flashesStore.addErrorFlash({
          message:
            'Something went wrong, reach out to our support team for help',
        });
      } finally {
        loading.value = false;
      }
    });

    return {
      selectedOption,
      loading,
      opportunityOptions,
      showTooltip,
      onOptionSelected,
      getValue,
      informStopEditing,
      onCtaClicked,
    };
  },
};
</script>
<style lang="pcss">
.c-collaborate-opportunities-editor__container {
  @apply max-w-full w-full;
  .c-bitts-dropdown,
  .c-bitts-dropdown__prompt {
    @apply w-full;
  }
  .c-bitts-dropdown
    > .ant-popover-open.c-bitts-dropdown__prompt
    > .c-bitts-dropdown__prompt--basic {
    @apply px-0 text-neutral-text-placeholder w-full flex items-center justify-between;
    svg {
      color: theme(colors.neutral.accent) !important;
    }
  }
}
.c-collaborate-opportunities-editor__dropdown {
  @apply w-[310px];
  &.c-bitts-dropdown-content
    .c-bitts-dropdown-selections
    .c-bitts-dropdown-selections__option {
    @apply mx-4;
  }
}
.c-collaborate-opportunities-editor__option {
  @apply px-8 py-4 mx-4 mb-4;
  &:hover,
  &:focus-visible {
    @apply bg-neutral-100 cursor-pointer rounded-bts-sm;
  }
}
.c-collaborate-opportunities-editor__tooltip {
  @apply max-w-[310px];
  left: 312px !important;
  .ant-tooltip-content {
    margin-bottom: 64px;
    @apply w-[240px];
  }
}
</style>
