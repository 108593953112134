<template>
  <BittsLayout :is-page="true" class="items-center">
    <BittsLoading :is-loading="!ready">
      <div class="c-access-denied">
        <h1 class="title"> No access </h1>
        <div class="c-access-denied__message">
          {{ denyMessage }}
        </div>
        <div v-if="showAdmins">
          <div class="text-m font-bold mb-18"> Crossbeam admins: </div>
          <div
            v-for="(user, index) in adminUsers"
            :key="index"
            class="text-m mb-8 text-brand-blue no-underline"
          >
            <a :href="`mailto:${user.email}`">
              {{ user.email }}
            </a>
          </div>
        </div>
        <BittsSvg svg="emptyStateFreeAccount" />
      </div>
    </BittsLoading>
  </BittsLayout>
</template>

<script setup>
import { BittsLayout, BittsLoading, BittsSvg } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

import { allReady, useRolesStore, useTeamStore } from '@/stores';

defineProps({
  showAdmins: {
    type: Boolean,
    default: true,
  },
  denyMessage: {
    type: String,
    default:
      'You do not have access to the page you are trying to reach.' +
      ' Contact a Crossbeam admin to request access.',
  },
});

onBeforeRouteUpdate((to, _from, next) => {
  to.matched[1].components.default = to.matched[1].components.overwritten;
  next();
});

onBeforeRouteLeave((_to, from, next) => {
  from.matched[1].components.default = from.matched[1].components.overwritten;
  next();
});

const teamStore = useTeamStore();
const rolesStore = useRolesStore();
const { adminRole } = storeToRefs(rolesStore);
const ready = allReady(teamStore, rolesStore);
const adminUsers = computed(() =>
  teamStore.getAuthsByRoleId(adminRole.value?.id).map((auth) => auth.user),
);
</script>
<style scoped lang="pcss">
.c-access-denied {
  @apply text-brand-navy flex flex-col justify-center items-center;
}
.c-access-denied__message {
  @apply text-m mb-18 text-neutral-text-weak;
}
.title {
  @apply text-xl text-neutral-text-strong font-bold mb-16 text-center;
}
</style>
