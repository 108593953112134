<template>
  <div>
    <BittsContainer
      v-if="!emptyStateAlertState || !partner"
      class="c-account-mapping-matrix__container"
      :no-padding="true"
    >
      <BittsLoading :is-loading="loading">
        <div id="account_mapping_matrix" class="c-account-mapping-matrix">
          <div
            v-if="showTitleBar"
            class="c-account-mapping-matrix__main-title-bar"
          >
            <div class="text-m md:text-lg text-neutral-text-strong font-bold">
              Account Mapping Matrix
            </div>
            <div class="flex items-center">
              <BittsCheckbox
                label="Hide Empty Populations"
                :checked="emptyPopulationsHidden"
                class="mr-16 mt-[-4px]"
                @input="onCheckHideEmpty"
              />
              <BittsSelect
                v-if="showViewSelectComponent"
                v-model="currView"
                :options="viewOptions"
                :searchable="false"
                class="min-w-[250px]"
                data-testid="amm-view-select"
                @update:model-value="changeView"
              >
                <template #suffix="{ option }">
                  <UpsellTooltip
                    v-if="option.cta"
                    :billing-interaction="{
                      cta: BILLING_CTAS.POTENTIAL_REVENUE,
                      cta_2: CTA_2.PARTNER_DETAIL_PAGE,
                      event_site: EVENT_SITES.AMM_VIEW_SELECT_TOOLTIP,
                    }"
                  >
                    <BittsTag color="upsell" size="x-small" variant="rounded">
                      Upgrade
                    </BittsTag>
                    <template #title>
                      {{ option.cta.text }}
                    </template>
                  </UpsellTooltip>
                </template>
              </BittsSelect>
            </div>
          </div>
          <div v-if="!showHalfEmptyState">
            <div class="c-account-mapping-matrix__header-row">
              <div class="c-account-mapping-matrix__empty-block" />
              <div class="w-full">
                <AMMOrgTitleBar
                  :org="partner"
                  :column-decrement-disabled="columnPageStart === 0"
                  :column-increment-disabled="
                    columnPageEnd >= partnerPopulations.length
                  "
                  :num-own-pops="rows ? rows.length : 0"
                  class="c-account-mapping-matrix__partner-org-title-bar"
                  @increment-column="columnPageStart += 1"
                  @decrement-column="columnPageStart -= 1"
                />
                <div class="c-account-mapping-matrix__partner-populations">
                  <div class="flex w-full">
                    <AMMColumnHeaderCell
                      v-for="(column, index) in columns"
                      :key="index"
                      :column-index="index"
                      :num-columns="columns ? columns.length : 0"
                      :column="column"
                      :column-increment-disabled="
                        columnPageEnd >= partnerPopulations.length
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <AMMOrgTitleBar
                :org="currentOrg"
                :row-decrement-disabled="rowPageStart === 0"
                :row-increment-disabled="rowPageEnd >= ourPopulations.length"
                :num-own-pops="rows ? rows.length : 0"
                direction="vertical"
                class="c-account-mapping-matrix__current-org-title-bar"
                @increment-row="rowPageStart += 1"
                @decrement-row="rowPageStart -= 1"
              />
              <div
                class="c-account-mapping-matrix__current-org-populations w-full"
              >
                <div
                  v-for="(population, rowIndex) in rows"
                  :key="rowIndex"
                  class="flex w-full"
                >
                  <AMMRowHeaderCell
                    :population="population"
                    :index="rowIndex"
                    :num-rows="rows ? rows.length : 0"
                    :row-increment-disabled="
                      rowPageEnd >= ourPopulations.length
                    "
                  />
                  <component
                    :is="cellType"
                    v-for="(cell, cellIndex) in getCellsForCurrentPage(
                      population.name,
                    )"
                    v-bind="
                      cellProps({ cell, cellIndex, population, rowIndex })
                    "
                    :key="cellIndex + Math.random()"
                    v-on="cellHandlers"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <BittsEmptyState
              :title="halfEmptyStateTitle"
              :font-awesome-icon="halfEmptyStateIcon"
              :font-awesome-icon-class="
                !isFreeTier ? 'text-neutral-accent' : 'text-upsell-accent'
              "
              class="c-account-mapping-matrix__empty-state"
            >
              <template #subtitle>
                <div class="c-account-mapping-matrix__empty-state-description">
                  {{ halfEmptyStateText }}
                </div>
                <BittsButton
                  v-if="isFreeTier"
                  text="Upgrade"
                  class="mt-16"
                  @click="
                    handleBillingInteraction({
                      cta: BILLING_CTAS.PIPELINE_MAPPING,
                      event_site: EVENT_SITES.AMM_UPGRADE_PROMPT,
                    })
                  "
                />
              </template>
            </BittsEmptyState>
          </div>
        </div>
      </BittsLoading>
    </BittsContainer>
    <BittsContainer
      v-else
      class="c-account-mapping-matrix__container bg-neutral-background-weak"
    >
      <BittsEmptyState
        :title="emptyStateInfo.title(partner?.name, partner?.offline_partner)"
        :png-name="emptyStateInfo.pngName"
        class="c-account-mapping-matrix__empty-state"
      >
        <template #subtitle>
          <div class="c-account-mapping-matrix__empty-state-description">
            {{ emptyStateInfo.description(partner?.offline_partner) }}
          </div>
          <a
            v-if="emptyStateInfo.helpLink"
            :href="emptyStateInfo.helpLink"
            target="_blank"
            class="mt-4 text-info-text"
          >
            {{ emptyStateInfo.helpLinkText }}
            <FontAwesomeIcon
              :icon="['far', 'arrow-up-right']"
              :style="{ height: '12px', width: '12px', color: 'currentColor' }"
              class="text-info-accent ml-2"
            />
          </a>
          <BittsButton
            :text="emptyStateInfo.buttonText(partner?.offline_partner)"
            class="mt-16"
            @click="ctaClicked()"
          />
        </template>
      </BittsEmptyState>
    </BittsContainer>
  </div>
</template>

<script>
import {
  BittsButton,
  BittsCheckbox,
  BittsContainer,
  BittsEmptyState,
  BittsLoading,
  BittsSelect,
  BittsTag,
} from '@crossbeam/bitts';

import Humanize from 'humanize-plus';
import { clone, orderBy, sortBy } from 'lodash';
import { mapActions, mapState } from 'pinia';
import { defineComponent } from 'vue';

import UpsellTooltip from '@/components/billing/UpsellTooltip.vue';

import useAuth from '@/composables/useAuth';
import useBilling from '@/composables/useBilling';
import {
  AMM_VIEW_OPTIONS,
  EMPTY_ALERT_STATES,
  NOT_CONFIGURED,
  OWN_NOT_SHARING,
  OWN_NO_CRM,
  OWN_NO_OPPS_DATA,
  PARTNER_NOT_SHARING,
  POSSIBLE_ALERT_STATES,
  POTENTIAL_REVENUE,
  TOTAL_OVERLAPS,
} from '@/constants/amm_grid';
import { BILLING_CTAS, CTA_2, EVENT_SITES } from '@/constants/analytics';
import { HIDDEN, OLAPS_ONLY } from '@/constants/data_shares';
import {
  ALL_STANDARD_POPULATIONS,
  STANDARD_POPULATION_NAMES,
  getPopulationByStandardType,
} from '@/constants/standard_populations';
import {
  useBillingStore,
  useDataSharesStore,
  usePopulationsStore,
  useSourcesStore,
} from '@/stores';

import AMMCell from './AMMCell.vue';
import AMMColumnHeaderCell from './AMMColumnHeaderCell.vue';
import AMMOrgTitleBar from './AMMOrgTitleBar.vue';
import AMMRowHeaderCell from './AMMRowHeaderCell.vue';
import EmptyAMMCell from './EmptyAMMCell.vue';

const STANDARD_POP_MAP = {
  Customers: { name: 'Customers', standard_type: 'customers' },
  'Open Opportunities': {
    name: 'Open Opportunities',
    standard_type: 'open_opportunities',
  },
  Prospects: { name: 'Prospects', standard_type: 'prospects' },
};

const EMPTY_STATE_INFO = {
  [OWN_NOT_SHARING]: {
    title: (name) => `Update sharing settings to compare data with ${name}`,
    description: () =>
      'You are not sharing data with this partner yet, change your sharing settings to compare data',
    buttonText: () => 'Change Sharing Settings',
    helpLink:
      'https://help.crossbeam.com/en/articles/4236660-sharing-settings-for-partners',
    helpLinkText: 'Learn more about customizing sharing settings',
    tab: 'sharing',
    pngName: 'own-not-sharing.png',
  },
  [PARTNER_NOT_SHARING]: {
    title: (name, isOffline) =>
      isOffline
        ? `Create Populations for ${name} to see data here`
        : `Request Data from ${name} to see data here`,
    description: (isOffline) =>
      isOffline
        ? 'Once you create 1 Population for this partner you will be able to compare data with them'
        : 'Make sure at least 1 Population is shared with you from your partner. If you don’t see any Populations, you may need to reach out to them directly',
    buttonText: (isOffline) =>
      isOffline ? 'View Partner Data' : 'View Shared With You',
    tab: 'shared_with_you',
    pngName: 'partner-not-sharing.png',
  },
  [OWN_NO_CRM]: {
    title: (name) => `Connect data source to compare data with ${name}`,
    description: () =>
      'Add a CRM data source and connect opportunity data to create this report',
    buttonText: () => 'Connect Data Source',
    helpLink:
      'https://help.crossbeam.com/en/articles/6797349-potential-revenue',
    helpLinkText: 'Learn more about adding a data source',
    route: { name: 'data-sources' },
    pngName: 'connect-data-source.png',
  },
  /* everything below here, TBD based on design/product input, just using alert info for now */
  [NOT_CONFIGURED]: {
    title: () =>
      'Configure Populations on the settings page to gain access to these revenue metrics',
    description: () =>
      'Select at least 1 population in order for us to generate these numbers',
    buttonText: () => 'Configure Data',
    route: () => ({ name: 'organizationSettings' }),
    pngName: 'emptyStateOpenOpps.png',
  },
  [OWN_NO_OPPS_DATA]: {
    title: () =>
      'Add the Pipeline Mapping preset to gain access to revenue metrics',
    description: () =>
      'We need access these specific fields to generate these numbers',
    buttonText: () => 'Update Data Sync',
    route: ({ feedId, name }) => ({
      name,
      params: { id: feedId },
      query: { customizing: true },
    }),
    pngName: 'emptyStateOpenOpps.png',
  },
};

const EMPTY_GRID_COLUMNS = 4;

export default defineComponent({
  name: 'AccountMappingMatrix',
  components: {
    BittsButton,
    BittsCheckbox,
    BittsContainer,
    BittsEmptyState,
    BittsLoading,
    BittsSelect,
    BittsTag,
    UpsellTooltip,
    AMMOrgTitleBar,
    AMMColumnHeaderCell,
    AMMRowHeaderCell,
    AMMCell,
    EmptyAMMCell,
  },
  props: {
    alertState: {
      type: String,
      default: null,
      validator(value) {
        return POSSIBLE_ALERT_STATES.includes(value);
      },
    },
    ammView: {
      type: String,
      default: TOTAL_OVERLAPS,
      validator(value) {
        return [TOTAL_OVERLAPS, POTENTIAL_REVENUE].indexOf(value) !== -1;
      },
    },
    canSeeOppsData: {
      type: Boolean,
      default: false,
    },
    hideEmptyPopulations: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Object,
      default: null,
    },
    overlaps: {
      type: Array,
      default() {
        return [];
      },
    },
    showTitleBar: {
      type: Boolean,
      default: true,
    },
    useAllAlertsForEmptyState: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'empty-state-cta-clicked',
    'view-changed',
    'open-request-data-modal',
    'change-tab',
  ],
  setup() {
    const { handleBillingInteraction } = useBilling();
    const { currentOrg } = useAuth();

    return { handleBillingInteraction, currentOrg };
  },
  data() {
    return {
      BILLING_CTAS,
      EVENT_SITES,
      CTA_2,
      emptyPopulationsHidden: false,
      EMPTY_STATE_INFO,
      ourPopulations: [],
      partnerPopulations: [],
      columnPageStart: 0,
      loading: false,
      rowPageStart: 0,
      rowsToCellsMap: {},
      currView: this.ammView,
      selectedCell: -1,
    };
  },
  computed: {
    ...mapState(useBillingStore, ['isFreeTier']),
    ...mapState(usePopulationsStore, ['populations']),
    ...mapState(useSourcesStore, ['popSourceIdToSourcesMap']),
    showViewSelectComponent() {
      // on free tier we show the select because it's used as a pot rev upsell
      if (this.alertState === OWN_NO_CRM) return this.isFreeTier;
      return !this.alertState;
    },
    emptyStateAlertState() {
      if (
        EMPTY_ALERT_STATES.includes(this.alertState) ||
        this.useAllAlertsForEmptyState
      )
        return this.alertState;
      return null;
    },
    cellHandlers() {
      if (!this.partner) return {};
      return {
        'cell-selected': (id) => {
          this.selectedCell = id;
        },
        'open-request-data-modal': (payload) => {
          this.$emit('open-request-data-modal', payload);
        },
        'change-tab': () => {
          this.$emit('change-tab', 'shared_with_you');
        },
      };
    },
    cellType() {
      return this.partner ? 'AMMCell' : 'EmptyAMMCell';
    },
    columnPageEnd() {
      return this.columnPageStart + 4;
    },
    rowPageEnd() {
      return this.rowPageStart + 5;
    },
    columns() {
      const cols = this.partnerPopulations.slice(
        this.columnPageStart,
        this.columnPageEnd,
      );
      if (this.partner) return cols;
      return [...cols, {}];
    },
    halfEmptyStateIcon() {
      if (this.isFreeTier) return ['fad', 'wand-magic-sparkles'];
      return ['fad', 'loader'];
    },
    halfEmptyStateTitle() {
      if (this.isFreeTier) return 'Upgrade to Connector Tier to see this view';
      return 'Thank you for upgrading!';
    },
    halfEmptyStateText() {
      if (this.isFreeTier)
        return 'Pipeline mapping is not included in your current plan.';
      return 'We are preparing these numbers right now, please check back later';
    },
    emptyStateInfo() {
      if (!this.emptyStateAlertState) return {};
      return EMPTY_STATE_INFO[this.emptyStateAlertState];
    },
    partnerPops() {
      return this.getPartnerPopulationsByOrg(this.partner?.id) || [];
    },
    showHalfEmptyState() {
      return !this.canSeeOppsData && this.currView === POTENTIAL_REVENUE;
    },
    rows() {
      const rows = this.sortedPopulations.slice(
        this.rowPageStart,
        this.rowPageEnd,
      );
      if (this.partner) return rows;
      return [...rows, {}, {}];
    },
    viewOptions() {
      return AMM_VIEW_OPTIONS.map((option) => {
        if (option.value === 'Total Overlaps' || !this.isFreeTier)
          return option;
        return {
          ...option,
          cta: {
            text: 'Upgrade for complete visibility into your Potential Revenue pipeline',
          },
        };
      });
    },
    sortedPopulations() {
      const standardPopulations = this.ourPopulations.filter(
        (pop) => pop.standard_type !== null,
      );
      const customPopulations = this.ourPopulations.filter(
        (pop) => pop.standard_type === null,
      );
      const sortedCustomPops = sortBy(customPopulations, ['name']);
      return [...standardPopulations, ...sortedCustomPops];
    },
  },
  watch: {
    ammView() {
      this.currView = this.ammView;
    },
    overlaps: {
      async handler(val) {
        if (!val) return;
        if (this.hideEmptyPopulations && !!this.partner)
          this.emptyPopulationsHidden = true;
        await this.buildMatrix(val);
      },
      deep: true,
    },
    hideEmptyPopulations() {
      this.onCheckHideEmpty();
    },
  },
  unmounted() {
    this.resetSourcesByPopulationSourceId();
  },
  created() {
    if (this.hideEmptyPopulations && !!this.partner)
      this.emptyPopulationsHidden = true;
    this.buildMatrix(this.overlaps);
  },
  methods: {
    ...mapActions(useDataSharesStore, [
      'getIncomingDataShare',
      'getOutgoingSharingRules',
      'isSharingPopulationWithPartner',
    ]),
    ...mapActions(useSourcesStore, [
      'resetSourcesByPopulationSourceId',
      'setSourcesByPopulationSourceId',
    ]),
    ...mapActions(usePopulationsStore, [
      'getPopulationById',
      'getPartnerPopulationById',
      'getPopulationByName',
      'getPartnerPopulationsByOrg',
    ]),
    async buildMatrix(overlaps) {
      this.loading = true;
      // The columns array starts with standard populations. If there aren't any overlaps
      // we'll render an empty state for the matrix which will show standard
      // population column headers.
      const standardPopsInOrder = orderBy(ALL_STANDARD_POPULATIONS, ['name']);
      let columns = clone(standardPopsInOrder);
      // The rows array starts with standard populations. If there aren't any overlaps
      // we'll render an empty state for the matrix which will show standard population row headers.
      let rows = clone(standardPopsInOrder);
      if (this.emptyPopulationsHidden) {
        columns = columns.filter(
          (row) =>
            !!getPopulationByStandardType(row.standard_type, this.partnerPops),
        );
        rows = rows.filter((row) => {
          const pop = getPopulationByStandardType(
            row.standard_type,
            this.populations,
          );
          if (!pop || !this.partner) return false;
          return this.isSharingPopulationWithPartner(pop.id, this.partner.id);
        });
      }
      // create map of rows to individual cells
      const rowsToCellsMap = this.emptyPopulationsHidden
        ? this.getVisibleRowsToCellsMap(rows, columns)
        : this.getRowsToCellsMap();

      // initialize cells
      const cells = [];

      // update columns and rows
      for (const overlap of overlaps) {
        const population = this.getPopulationById(overlap.our_population_id);
        const excludePopulation =
          this.emptyPopulationsHidden &&
          !this.isSharingPopulationWithPartner(population.id, this.partner.id);
        if (excludePopulation) continue;
        const partnerPopulation = this.getPartnerPopulationById(
          overlap.partner_population_id,
        );
        if (!partnerPopulation) continue;

        const columnIndex = columns.findIndex(
          (item) => item.name === partnerPopulation.name,
        );

        // update column for standard population
        if (columnIndex >= 0) {
          columns[columnIndex] = partnerPopulation;
        } else {
          // add column for custom population
          columns.push(partnerPopulation);

          for (const name in rowsToCellsMap) {
            // add default cell for each row in grid
            const defaultReportCell = this.getDefaultReportCell({
              population: this.getPopulation(name),
              partnerPop: partnerPopulation,
            });
            rowsToCellsMap[name].push(defaultReportCell);
          }
        }

        const rowIndex = rows.findIndex(
          (item) => item.name === population.name,
        );

        // if the row does exist we're going to replace it
        // with the population associated with the current overlap
        if (rowIndex >= 0) {
          rows[rowIndex] = population;
        } else {
          // if a row for the given population does not exist, we add a new row for it here
          rows.push(population);
        }

        // update rows map - e.g. if a map does not exist for the given population
        if (!rowsToCellsMap[population.name]) {
          rowsToCellsMap[population.name] = this.emptyPopulationsHidden
            ? this.getVisibleDefaultRow(population.name, columns)
            : this.getDefaultRow(population.name);
        }

        const label = this.getCellLabel(population.name, overlap.overlap_count);
        const isPartnerPopOverlapsOnly = this.isPartnerPopulationOverlapsOnly(
          population.id,
          partnerPopulation.id,
        );
        const isPopulationOverlapsOnly = this.isPopulationOverlapsOnly(
          population.id,
        );

        const cell = {
          ...overlap,
          label,
          our_population_id: population.id,
          partner_population_id: partnerPopulation.id,
          overlaps: overlap.overlap_count,
          isPartnerPopOverlapsOnly,
          hasReport: true,
          isPopulationOverlapsOnly,
          population,
          partnerPop: partnerPopulation,
          potentialRevenue: overlap.open_deals_total_amount,
          openOpps: overlap.open_deals_count,
        };

        if (this.isPopulationHidden(cell)) {
          cell.hasReport = false;
          cell.isPopulationHidden = true;
        } else if (population.source_id) {
          await this.setSourcesByPopulationSourceId(population);
        }

        cells.push(cell);
      }

      // Handle updates for individual cells
      // Add each cell to the proper row / columnIndex
      cells.forEach((cell) => {
        const row = rowsToCellsMap[cell.population.name]; // e.g. { 'Customers': [] }
        const columnIndex = columns.findIndex(
          (item) => item.name === cell.partnerPop.name,
        );
        row[columnIndex] = cell;
      });

      this.ourPopulations = rows;
      this.partnerPopulations = columns;
      this.rowsToCellsMap = rowsToCellsMap;
      this.loading = false;
    },
    cellProps({ cell, cellIndex, population, rowIndex }) {
      const props = { rowIndex };
      if (!this.partner) return { ...props, cellIndex };
      return {
        ...props,
        cell,
        currentAmmState: this.currView,
        partner: this.partner,
        numColumns: this.columns?.length || 0,
        numRows: this.rows?.length || 0,
        columnIndex: cellIndex,
        popSources: this.popSourceIdToSourcesMap[population.source_id],
        rowIncrementDisabled: this.rowPageEnd >= this.ourPopulations.length,
        columnIncrementDisabled:
          this.columnPageEnd >= this.partnerPopulations.length,
      };
    },
    async ctaClicked() {
      if (this.partner?.offline_partner) {
        await this.$router.push({
          name: 'partner_details',
          params: {
            partner_org_id: this.partner.id,
          },
          query: { tab: 'data' },
        });
        return;
      }
      const { route, tab } = EMPTY_STATE_INFO[this.emptyStateAlertState];
      const ownNoOppsData = this.emptyStateAlertState === OWN_NO_OPPS_DATA;
      this.$emit('empty-state-cta-clicked', tab || route, ownNoOppsData);
    },
    changeView(view) {
      this.currView = view;
      this.$emit('view-changed', view.name);
    },
    getRowsToCellsMap() {
      return {
        Prospects: this.getDefaultRow('Prospects'),
        'Open Opportunities': this.getDefaultRow('Open Opportunities'),
        Customers: this.getDefaultRow('Customers'),
      };
    },
    getDefaultRow(populationName) {
      const population = this.getPopulation(populationName);
      const firstColumn = this.getDefaultReportCell({
        population,
        partnerPop: this.getPartnerPopulation('Customers'),
      });
      const secondColumn = this.getDefaultReportCell({
        population,
        partnerPop: this.getPartnerPopulation('Open Opportunities'),
      });
      const thirdColumn = this.getDefaultReportCell({
        population,
        partnerPop: this.getPartnerPopulation('Prospects'),
      });
      return [firstColumn, secondColumn, thirdColumn];
    },
    getDefaultReportCell(options) {
      const cell = {
        label: null,
        our_population_id: null,
        partner_population_id: null,
        overlaps: null,
        isPartnerPopOverlapsOnly: null,
        hasReport: false,
        isPopulationOverlapsOnly: false,
        population: {},
        partnerPop: {},
        ...options,
      };
      if (this.isPopulationHidden(cell)) {
        cell.hasReport = false;
        cell.isPopulationHidden = true;
      }
      return cell;
    },
    getPartnerPopulation(name) {
      return (
        this.getPartnerPopulationByName(name) || this.getDefaultPopulation(name)
      );
    },
    getPopulation(name) {
      return this.getPopulationByName(name) || this.getDefaultPopulation(name);
    },
    getDefaultPopulation(name) {
      return STANDARD_POP_MAP[name] || { name, standard_type: '' };
    },
    getPartnerPopulationByName(name) {
      return this.partnerPops.find((pop) => pop.name === name);
    },
    getCellLabel(pop, overlapCount) {
      const MAP_POP_TO_LABEL = {
        Prospects: Humanize.pluralize(overlapCount, 'Prospect'),
        'Open Opportunities': Humanize.pluralize(
          overlapCount,
          'Opportunity',
          'Opportunities',
        ),
        Customers: Humanize.pluralize(overlapCount, 'Customer'),
      };
      return (
        MAP_POP_TO_LABEL[pop] || Humanize.pluralize(overlapCount, 'Overlap')
      );
    },
    getVisibleDefaultRow(populationName, columns) {
      const population = this.getPopulation(populationName);
      const defaultRow = [];
      STANDARD_POPULATION_NAMES.forEach((name) => {
        const doesPartnerPopExist = columns.some(
          (column) => column.name === name,
        );
        if (doesPartnerPopExist) {
          defaultRow.push(
            this.getDefaultReportCell({
              population,
              partnerPop: this.getPartnerPopulation(name),
            }),
          );
        }
      });
      return orderBy(defaultRow, ['name']);
    },
    getVisibleRowsToCellsMap(rows, columns) {
      const defaultRows = {};
      STANDARD_POPULATION_NAMES.forEach((name) => {
        const doesPopExist = rows.some((row) => row.name === name);
        if (doesPopExist) {
          defaultRows[name] = this.getVisibleDefaultRow(name, columns);
        }
      });
      return defaultRows;
    },
    isPopulationHidden(cell) {
      const population = cell.population.id
        ? cell.population
        : this.getPopulationByName(cell.population.name);
      if (population) {
        const populationSetting = this.getOutgoingSharingRules({
          populationId: population.id,
          partnerPopulationId: cell.partner_population_id,
        })[0];
        return populationSetting
          ? populationSetting.visibility === HIDDEN
          : false;
      }
    },
    isPartnerPopulationOverlapsOnly(populationId, partnerPopulationId) {
      const matchedDataShare = this.getIncomingDataShare({
        populationId,
        partnerPopulationId,
      });
      return !matchedDataShare || !matchedDataShare.shared_fields.length;
    },
    isPopulationOverlapsOnly(populationId) {
      const populationSetting = this.getOutgoingSharingRules({
        populationId,
        isOverride: false,
      })[0];
      return populationSetting
        ? populationSetting.visibility === OLAPS_ONLY
        : false;
    },
    getCellsForCurrentPage(population) {
      if (!this.partner) return this.getEmptyGridCells();
      const cells = this.rowsToCellsMap[population];
      return cells.slice(this.columnPageStart, this.columnPageEnd);
    },
    getEmptyGridCells() {
      const cells = [];
      for (let i = 0; i < EMPTY_GRID_COLUMNS; i++) {
        cells.push(this.getDefaultReportCell({}));
      }
      return cells;
    },
    async onCheckHideEmpty() {
      if (!this.partner) return;
      this.emptyPopulationsHidden = !this.emptyPopulationsHidden;
      await this.buildMatrix(this.overlaps);
      this.columnPageStart = 0;
      this.rowPageStart = 0;
    },
  },
});
</script>

<style lang="pcss" scoped>
.c-account-mapping-matrix {
  .amm-column-header-cell:last-of-type {
    border-right: none;
  }
}
.c-account-mapping-matrix__container.bitts-container {
  @apply overflow-hidden;
}
.c-account-mapping-matrix__empty-state-description {
  @apply text-neutral-text text-center max-w-[625px];
}
.c-account-mapping-matrix__header-row {
  @apply flex;
  height: 81px;
}
.c-account-mapping-matrix__partner-populations {
  @apply flex;
}
.c-account-mapping-matrix__empty-block {
  @apply bg-neutral-50 rounded-tl-md border-r border-b border-neutral-border
  rounded-none min-w-[200px];
}
.c-account-mapping-matrix__main-title-bar {
  @apply p-16 flex justify-between items-center
  border-b border-solid border-neutral-border;
}
</style>

<style lang="pcss">
.c-account-mapping-matrix {
  .ant-select-selector {
    .bitts-tooltip {
      @apply hidden;
    }
  }
}
.c-account-mapping-matrix__container {
  .c-bitts-empty-state-large-border {
    @apply border-none;
  }
}
.c-account-mapping-matrix__empty-state {
  @apply pb-0 bg-neutral-background-weak h-[400px] flex items-center justify-center;
  div {
    @apply justify-center;
  }
  .c-bitts-empty-state-title {
    @apply text-neutral-text-strong pt-16;
  }
  .c-bitts-empty-state-large {
    @apply w-full;
  }
}
.c-account-mapping-matrix__view-selector {
  @apply min-w-[228px] mt-[-4px] h-[32px];
  .bitts-input-select__caret {
    top: 12px;
  }
  .multiselect {
    @apply h-[32px];
  }
  .multiselect__option--highlight {
    @apply bg-white;
  }
  .multiselect__tags {
    @apply pr-24 min-h-[32px] h-[32px] pt-0 flex items-center;
  }
  .multiselect__single {
    @apply mb-0;
  }
}
</style>
