<template>
  <BittsLayout :is-page="true">
    <template #content>
      <BittsLoading :is-loading="!ready">
        <div class="roles-page">
          <PageTitle
            title="Roles & Groups"
            subtitle="Roles and groups make it easier to assign different permissions to specific members of your team"
          >
            <template #action>
              <BittsButton
                v-if="!hideRoleButton"
                text="Add Role"
                :disabled="!hasScope('write:roles')"
                type="neutral"
                variant="outline"
                size="small"
                @click="addRoleClicked"
              />
            </template>
          </PageTitle>
          <div class="flex justify-between items-start">
            <div
              v-if="isEarlyAdopter"
              class="text-neutral-600 flex items-center gap-8 mr-8"
            >
              <BittsTooltip>
                <div class="flex items-center">
                  <FontAwesomeIcon
                    :icon="['fas', 'circle-heart']"
                    :style="{
                      height: '12px',
                      width: '12px',
                      color: 'currentColor',
                    }"
                    class="text-info-accent"
                    aria-label="Early Adopter heart"
                  />
                </div>
                <template #title>
                  <span>This is an early adopter perk</span>
                </template>
              </BittsTooltip>
              <div
                class="text-neutral-text-weak text-sm flex items-center font-bold"
              >
                <div class="text-info-text-strong">
                  {{ customRoleCount }}
                </div>
                &nbsp;/&nbsp;0&nbsp;
                <div>Custom Roles</div>
              </div>
            </div>
          </div>
          <router-view-wrapper />
          <div>
            <BillingCTA
              v-if="isEarlyAdopter"
              description="As an early adopter, you have existing custom roles. Upgrade to edit and create more."
              button-text="Talk to Sales"
              message="You’ve been given a gift"
              :billing-interaction="{
                cta: BILLING_CTAS.ROLES,
                event_site: EVENT_SITES.ROLES_PAGE_EA_CUSTOM_ROLES_CTA,
                talkToSalesReason: 'Early Adopter of Roles',
                isEarlyAdopter: true,
              }"
              class="mb-20 mt-8"
            />
          </div>
          <div class="flex flex-col items-stretch">
            <RoleCard
              v-for="role in allRolesWithIsCustom"
              :key="`role__${role.id}`"
              :num-role-members-text="numRoleMembersText(role.id)"
              :role="role"
              class="mb-16"
              @edit-role="roleCardClicked(role)"
            />
          </div>
          <BillingCTA
            v-if="showBottomCTA"
            learn-more-external-link="https://help.crossbeam.com/en/articles/4292722-user-roles-and-permissions"
            message="Customize permissions for individual team members"
            description="Control who has access to different features in Crossbeam with more granular roles & permissions on our Supernode plan"
            size="large"
            button-text="Talk to Sales"
            :billing-interaction="{
              event_site: EVENT_SITES.LARGE_ENTERPRISE_ROLE_CTA,
              talkToSalesReason: 'Custom roles',
              cta: BILLING_CTAS.ROLES,
            }"
            class="mb-20 mt-0"
          >
            <template #image>
              <img src="@/assets/pngs/ctas/custom-roles.png" />
            </template>
          </BillingCTA>
        </div>
      </BittsLoading>
    </template>
  </BittsLayout>
</template>

<script>
import {
  BittsButton,
  BittsLayout,
  BittsLoading,
  BittsTooltip,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { storeToRefs } from 'pinia';

import BillingCTA from '@/components/billing/BillingCTA.vue';
import PageTitle from '@/components/PageTitle.vue';
import RoleCard from '@/components/RoleCard.vue';

import useAuth from '@/composables/useAuth';
import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import {
  allReady,
  useBillingStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import { simplePluralize } from '@/utils';

export default {
  name: 'Roles',
  components: {
    BittsLayout,
    BittsLoading,
    BillingCTA,
    PageTitle,
    BittsButton,
    BittsTooltip,
    RoleCard,
  },
  setup() {
    useHead({ title: 'Manage Roles - Crossbeam' });
    const rolesStore = useRolesStore();
    const teamStore = useTeamStore();
    const billingStore = useBillingStore();

    const ready = allReady(rolesStore, teamStore);
    const { allRolesWithIsCustom } = storeToRefs(rolesStore);
    const { isEnterpriseTier } = storeToRefs(billingStore);

    function numRoleMembersText(roleId) {
      return simplePluralize(teamStore.getAuthsByRoleId(roleId), 'member');
    }

    useHead({ title: 'Manage Roles - Crossbeam' });
    const { currentOrg, currentUser, hasPermission, hasScope } = useAuth();

    return {
      BILLING_CTAS,
      EVENT_SITES,
      ready,
      isEnterpriseTier,
      numRoleMembersText,
      currentOrg,
      allRolesWithIsCustom,
      currentUser,
      hasPermission,
      hasScope,
    };
  },
  computed: {
    isEarlyAdopter() {
      const hasCustomRoles = this.allRolesWithIsCustom.some(
        (role) => role.is_custom,
      );
      return !this.isEnterpriseTier && hasCustomRoles;
    },
    customRoleCount() {
      return this.allRolesWithIsCustom.filter((role) => role.is_custom).length;
    },
    pluralizedRole() {
      return `role${this.customRoleCount > 1 ? 's' : ''}`;
    },
    upgradeMessage() {
      return this.isEarlyAdopter
        ? `Your Early Adopter plan includes ${this.customRoleCount} free ` +
            `custom role${this.customRoleCount > 1 ? 's' : ''}`
        : 'Your current plan doesn’t include custom user roles';
    },
    hideRoleButton() {
      return !this.isEnterpriseTier;
    },
    showBottomCTA() {
      if (this.isEarlyAdopter) return false;
      return !this.isEnterpriseTier;
    },
  },
  methods: {
    addRoleClicked() {
      this.$router.push({
        name: 'create_role',
      });
    },
    isClickable(role) {
      return this.hasScope('write:roles') && role.is_editable;
    },
    roleCardClicked(role) {
      if (this.isClickable(role)) {
        this.$router.push({
          name: 'role_detail',
          params: { role_id: role.id },
        });
      }
    },
  },
};
</script>

<style scoped lang="pcss">
.c-roles__role-card {
  @apply flex flex-col pl-12 py-16 pr-20
  border border-neutral-border rounded mb-20 bg-white;
}
.c-roles__clickable {
  @apply cursor-pointer;
}
.c-roles__role-card.c-roles__clickable {
  &:hover {
    @apply border-brand-blue;
  }
}

.c-roles__role-tag {
  @apply text-sm ml-6 px-8 py-4 rounded;
}

.c-roles__role-tag--system-role {
  @apply bg-neutral-200;
}

.c-roles__role-tag--custom-role {
  @apply bg-info-background-weak;
}
</style>
