<template>
  <BittsModal
    :visible="visible"
    :width="600"
    :no-padding="true"
    :loading="loading"
    :show-buttons="false"
    class="remove-population-modal"
    @closed="closeModal(false)"
  >
    <template #content>
      <div class="w-full flex flex-col items-center">
        <div class="trash-background">
          <FontAwesomeIcon
            :icon="['fad', 'trash']"
            :style="{ height: '72px', width: '72px' }"
            class="text-danger-accent mx-auto"
          />
        </div>
      </div>
      <h1 data-testid="remove-pop-title" class="remove-population-modal__title">
        <!-- Placeholder for now for offline partnerships -->
        Delete {{ offlinePartnerId ? `${offlinePartnerName}'s` : 'your' }}
        {{ populationName }} Population?
      </h1>
      <div class="remove-population-modal__changes">
        <h3 class="remove-population-modal__changes__header">
          Here's what will happen:
        </h3>
        <ul class="danger-list">
          <li
            v-for="(change, i) in changeList"
            :key="`feature__${i}`"
            class="danger-list__item"
          >
            <FontAwesomeIcon
              class="text-danger-accent w-18 h-18"
              :icon="['far', 'xmark']"
            />
            <span class="text-neutral-text text-base">
              {{ change }}
            </span>
          </li>
        </ul>
        <BittsAlert
          v-if="showDeletionWarning"
          class="mt-16"
          message="You won't be able to create another Custom Population"
          description="You are at your limit and will need to upgrade to add more"
          color="warning"
        />
        <BittsInput
          v-model="deleteText"
          data-testid="delete-confirmation-input"
          name="delete-confirmation-input"
          form-label="Type DELETE to confirm"
          class="pt-24"
          placeholder="DELETE"
        />
        <div class="footer">
          <BittsButton
            data-testid="close-button"
            class="flex-1"
            text="Cancel"
            type="neutral"
            size="large"
            @click="closeModal(false)"
          />
          <BittsButton
            :disabled="!canDelete"
            data-testid="delete-button"
            text="Delete Population"
            class="flex-1"
            size="large"
            type="danger"
            @click="handleDeletePopulation"
          />
        </div>
      </div>
    </template>
  </BittsModal>
</template>
<script setup>
import {
  BittsAlert,
  BittsButton,
  BittsInput,
  BittsModal,
} from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, inject, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import usePopulations from '@/composables/usePopulations';
import { captureException } from '@/errors';
import {
  useBillingStore,
  useFlashesStore,
  usePartnersStore,
  usePopulationsStore,
} from '@/stores';

const props = defineProps({
  populationId: {
    type: Number,
    default: null,
    required: false,
  },
  visible: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

const populationsStore = usePopulationsStore();
const billingStore = useBillingStore();
const flashesStore = useFlashesStore();
const partnersStore = usePartnersStore();

const { customPopulations } = storeToRefs(populationsStore);
const { isEnterpriseTier, customPopulationLimit } = storeToRefs(billingStore);

const { deletePopulation } = usePopulations();

const route = useRoute();
const router = useRouter();

const loading = ref(false);

const populationId = computed(
  () => props.populationId || Number(route.params.population_id),
);
const offlinePopulations = inject('offlinePopulations', []);
const deleteOfflinePopulation = inject('deleteOfflinePopulation', null);

const deleteText = ref('');
const canDelete = computed(() => deleteText.value.toLowerCase() === 'delete');

const offlinePartnerId = computed(() => route.query?.offlinePartnerId);
const offlinePartner = computed(() =>
  partnersStore.getPartnerOrgById(offlinePartnerId.value),
);
const offlinePartnerName = computed(() => offlinePartner.value?.name);
const offlinePartnerUuid = computed(() => offlinePartner.value?.uuid);

const population = computed(() =>
  offlinePartnerId.value
    ? offlinePopulations.value.find((pop) => pop.id === populationId.value)
    : populationsStore.getPopulationById(populationId.value),
);

const populationName = computed(() => population.value?.name);

const changeList = computed(() => {
  const base = [
    'This Population will immediately be deleted',
    'This Population will no longer be available in any reports',
  ];

  if (!offlinePartnerId.value)
    base.splice(1, 0, 'This data will no longer be shared with partners');
  return base;
});

const isStandard = computed(() => !!population.value?.standard_type);

/* Warn about deletions if a user is at or above their cap for custom populations */
const showDeletionWarning = computed(
  () =>
    !isStandard.value &&
    !isEnterpriseTier.value &&
    customPopulations.value.length > customPopulationLimit.value,
);

/* This modal can render in several different places. We are routing it's close
hander to different locations depending on where it started from: either
above the offline partner page (#1), above the pop editor for an
offline partner population (#2), above the pop editor for a normal org (#3),
or above the populations page (#4) */
async function closeModal(popDeleted = false) {
  if (
    route.name === 'offline-details-delete-population-modal' ||
    (offlinePartnerId.value && popDeleted)
  ) {
    await router.push({
      name: 'partner_details',
      params: {
        partner_org_id: offlinePartnerId.value,
      },
      query: {
        tab: 'data',
      },
    });
    return;
  }

  if (route.name === 'offline-delete-population-modal' && !popDeleted) {
    await router.push({
      name: 'edit_population',
      params: { population_id: population.value.id },
      query: {
        offlinePartnerId: offlinePartnerId.value,
        offlinePartnerUuid: offlinePartnerUuid.value,
      },
    });
    return;
  }

  if (route.name === 'delete-population-modal' && !popDeleted) {
    await router.push({
      name: 'edit_population',
      params: { population_id: population.value.id },
    });
    return;
  }

  await router.push({ name: 'populations' });
  emit('close');
}

async function handleDeletePopulation() {
  loading.value = true;
  try {
    if (offlinePartnerId.value)
      await deleteOfflinePopulation(
        population.value.id,
        offlinePartnerUuid.value,
      );
    else await deletePopulation(population.value);
  } catch (err) {
    captureException(err);
    flashesStore.addErrorFlash({
      message: `${population.value.name} Not Deleted`,
      description:
        'Please try again or contact support@crossbeam.com for help.',
    });
  } finally {
    loading.value = false;
    closeModal(true);
  }
}
</script>

<style lang="pcss">
.trash-background {
  @apply rounded-full w-120 h-120 p-24;
  background: radial-gradient(
    136.96% 69.92% at 50.09% -12.42%,
    theme(colors.danger.accent / 0.2) 0%,
    theme(colors.danger.accent / 0) 100%
  );
}
</style>

<style lang="pcss" scoped>
.danger-list__item {
  @apply flex items-start gap-6;
}

.danger-list {
  @apply flex flex-col gap-4;
}

.remove-population-modal__title {
  @apply text-neutral-text-strong text-xl font-bold text-center mt-16 mb-24 mx-72;
}

.remove-population-modal__changes {
  @apply bg-neutral-background-weak px-40 py-24 rounded-b-bts-lg;

  .remove-population-modal__changes__header {
    @apply text-neutral-text-strong font-bold mb-8;
  }
}

.footer {
  @apply w-full flex items-center gap-16 pt-24;
}
</style>
