<template>
  <div class="c-view-invite">
    <BittsLoading
      v-if="loading"
      :is-loading="loading"
      loading-container-class="h-screen"
    />
    <template v-else>
      <BittsCard
        class="c-view-invite__card"
        :class="{
          'pt-80': !isSomeoneElses,
        }"
        :no-padding="false"
      >
        <AccessDenied
          v-if="isSomeoneElses"
          data-testid="access-denied"
          :deny-message="denyMessage"
          :show-admins="false"
        />
        <div v-else-if="isLoggedIn" class="c-view-invite__card__content">
          <!-- TODO: Get actual logo -->
          <BittsAvatar
            v-if="clearbitCompany"
            size="large"
            shape="square"
            :org="clearbitCompany"
            class="c-view-invite__card__avatar"
          />
          <h1 class="title">
            Join {{ invite.organization.name }} on Crossbeam
          </h1>
          <p class="explainer">
            You've been invited to join your team on Crossbeam, the biggest
            ecosystem-led growth platform. Don't keep them waiting!
          </p>
          <BittsButton
            size="large"
            class="mt-12"
            text="Accept invitation"
            data-testid="accept-invite-button"
            @click="redeem"
          />
        </div>
      </BittsCard>
    </template>
  </div>
</template>

<script setup>
import {
  BittsAvatar,
  BittsButton,
  BittsCard,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import AccessDenied from '@/components/AccessDenied.vue';

import useAuth from '@/composables/useAuth';
import useClearbit from '@/composables/useClearbit';
import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';
import { switchOrganization } from '@/utils';

useHead({ title: 'Join - Crossbeam' });

const emit = defineEmits(['with-background']);

const flashesStore = useFlashesStore();
const { currentUser, isLoggedIn } = useAuth();

const route = useRoute();
const router = useRouter();

const { searchIcons } = useClearbit(false);
const loading = ref(true);
const invite = ref(null);
const code = ref(route.params.code);
const isSomeoneElses = computed(
  () =>
    isLoggedIn.value &&
    currentUser.value.email?.toLowerCase() !==
      invite.value?.user.email.toLowerCase(),
);

const denyMessage = computed(() => {
  if (!invite.value) return '';
  return (
    `This Invitation is for ${invite.value?.user.email}. ` +
    `You are logged in as ${currentUser.value.email}. ` +
    "Log out and try accepting the invitation again if it's intended for you."
  );
});

const clearbitCompany = ref(null);
onMounted(async () => {
  try {
    const response = await axios.get(urls.invites.details(code.value));
    invite.value = response.data;
    if (!isLoggedIn.value && !isSomeoneElses.value) {
      const invitingOrgString = JSON.stringify({
        ...invite.value.organization,
        email: invite.value.user.email,
      });
      await router.push({
        name: 'register-public-params',
        params: {
          invitingOrgString,
          inviteNextUrl: route.fullPath,
        },
      });
      return;
    }
    emit('with-background');
  } catch (err) {
    const { status, data } = err.response;
    if (
      status === 400 &&
      data.errors[0] === 'This invite has already been redeemed'
    ) {
      await router.push({ name: 'main' });
      return;
    }
    flashesStore.addUnhandledError(err);
    return;
  } finally {
    loading.value = false;
  }

  /* Get logo, if it exists in clearbit */
  try {
    const companyResults = await searchIcons(invite.value.organization.name);
    clearbitCompany.value = companyResults.find(
      (c) => c.name === invite.value.organization.name,
    );
  } catch (err) {
    flashesStore.addUnhandledError(err);
  }
});

const { iteratively } = useIteratively();
async function redeem() {
  try {
    loading.value = true;
    await axios.post(urls.invites.redeem(code.value));
    iteratively.userRedeemsInvite({
      event_site: EVENT_SITES.REDEEM_INVITE_BUTTON,
      source: route.query?.source || 'crossbeam-web',
    });
  } catch (err) {
    flashesStore.addUnhandledError(err);
    return;
  }
  switchOrganization(router, invite.value.organization.id);
}
</script>

<style lang="pcss" scoped>
.c-view-invite {
  @apply px-16;
}
.c-view-invite__card {
  @apply flex flex-col items-center max-w-[600px] mx-auto p-40 relative mt-72 sm:mt-0;
  .c-view-invite__card__content {
    @apply flex flex-col items-center;
  }
}
.c-view-invite__card__avatar {
  @apply absolute top-[-48px] outline outline-8 outline-white rounded-16 border-none;
}
.title {
  @apply text-xl text-neutral-text-strong text-center font-bold mb-16;
}
.explainer {
  @apply text-neutral-text-weak text-m mb-40 text-center;
}
</style>
<style lang="pcss">
.c-app-bare.c-app-bare__view_invite__background {
  background: radial-gradient(
    200.73% 104.41% at 50% 120.59%,
    theme(colors.brand-teal) 3.5%,
    theme(colors.denim.400) 70.74%
  );
  .c-app-bare__content {
    @apply justify-start sm:justify-center;
  }
}
</style>
