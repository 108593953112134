<template>
  <div class="c-register-or-setup">
    <div
      v-if="isLoggedIn && !isDomainAllowed"
      class="c-register-or-setup__work-email"
    >
      <h1 class="c-register-or-setup__work-email__title">
        Email Domain Validation
      </h1>
      <p class="c-register-or-setup__work-email__message">
        We require users to register using a work email address. Click below to
        go back and try your work email. If you need assistance please contact
        <Support />
      </p>
      <BittsButton
        class="m-auto"
        :loading="loading"
        text="Back to Registration"
        @click="backToRegistrationClicked"
      />
    </div>
    <Suspense v-else-if="isLoggedIn">
      <OrgDetailsForm :next-url="nextUrl" />
      <template #fallback>
        <div class="h-full w-full flex items-center justify-center">
          <img class="w-64" src="/images/gifs/loading.gif" />
        </div>
      </template>
    </Suspense>
    <BittsLoading v-else class="w-full h-full" :is-loading="loading">
      <RegistrationForm
        :next-url="nextUrl"
        :proposal-acceptance-code="proposalAcceptanceCode"
        :public-invite-code="publicInviteCode"
        :inviting-org="invitingOrg"
        :invite-next-url="inviteNextUrl"
      />
    </BittsLoading>
  </div>
</template>

<script>
import { BittsButton, BittsLoading } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';

import OrgDetailsForm from '@/components/registration/OrgDetailsForm.vue';
import RegistrationForm from '@/components/registration/RegistrationForm.vue';
import Support from '@/components/Support.vue';

import useAuth from '@/composables/useAuth';
import urls from '@/urls';

export default {
  name: 'Register',
  components: {
    OrgDetailsForm,
    RegistrationForm,
    BittsButton,
    BittsLoading,
    Support,
  },
  props: {
    /*
     * NOTE
     * the invitingOrgString and inviteNextUrl prop is being passed from the invite code flow
     * via router props. Commenting here because we don't generally use this pattern,
     * but passing with router cleans up our layout files quite a bit.
     */
    invitingOrgString: {
      type: String,
      default: '',
    },
    inviteNextUrl: {
      type: String,
      default: null,
    },
  },

  setup() {
    useHead({
      title: 'Register - Crossbeam',
    });
    const { currentUser, isLoggedIn } = useAuth();

    return { currentUser, isLoggedIn };
  },

  data() {
    return {
      loading: true,
      isDomainAllowed: true,
      invitingOrg: {},
    };
  },
  computed: {
    proposalAcceptanceCode() {
      // invite_code is checked here for backwards compatibility. We may have
      // some users who have received a link to this page using that query
      // param. proposal_acceptance_code is the correct one to use going
      // forward.
      return (
        this.$route.query.proposal_acceptance_code ||
        this.$route.query.invite_code
      );
    },
    nextUrl() {
      return this.$route.query.next;
    },
    publicInviteCode() {
      return this.$route.query.public_invite_code;
    },
  },
  async created() {
    if (this.invitingOrgString)
      this.invitingOrg = JSON.parse(this.invitingOrgString);
    try {
      if (this.isLoggedIn && !this.currentUser.id) {
        await this.checkIfDomainAllowed();
      }
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async checkIfDomainAllowed() {
      const response = await axios.get(urls.registration.cookieEmailDomain);
      this.isDomainAllowed = response.data.is_allowed;
    },
    async backToRegistrationClicked() {
      await this.$router.push({ name: 'logout', query: { next: 'register' } });
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-register-or-setup {
  @apply flex flex-col items-center justify-center relative;
}

.c-register-or-setup__work-email {
  @apply text-center;
}

.c-register-or-setup__work-email__title {
  font-weight: 600;
  @apply text-xl text-brand-navy text-center;
}

.c-register-or-setup__work-email__message {
  @apply my-6;
  max-width: 560px;
}
</style>
<style lang="pcss">
.c-app-bare__company_setup {
  .c-app-bare__content {
    @apply justify-start sm:justify-center;
  }
}
</style>
