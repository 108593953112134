/* Step Types */
export const CONNECT_DATA = 'connect_data';
export const INVITE_PARTNER = 'invite_partner';
export const CREATE_POPULATION = 'create_population';
export const SHARE_DATA = 'share_data';
export const ACCOUNT_MAPPING = 'account_mapping';
export const INVITE_TEAM_MEMBERS = 'invite_team_members';

/* Onboarding V3 */
export const PROGRESS = 'progress';
export const PENDING = 'pending';
export const COMPLETE = 'complete';
export const ERRORED = 'errored';
export const LOCKED = 'locked';
export const NOT_SYNCED = 'not_synced';
export const NEEDS_SHEET = 'needs_sheet';
export const DELETING = 'deleting';
export const NEEDS_PREVIOUS = 'needs_previous';
export const CALCULATING = 'calculating';

/* Returns list of objects indicating whether a feature is supported or not */
export function makeFeatureList(features, supported) {
  return features.map((feature, i) => ({
    [feature]: supported[i],
  }));
}
