<template>
  <BittsTooltip
    :overlay-class="overlayClass"
    button-text="View in CRM"
    :placement="placement"
    :include-cta="!!opt?.crm_record_link"
    :show-tooltip="showTooltip"
    :stop-propagation="true"
    :mount-to-body="mountToBody"
    @cta-clicked="emit('tooltip-cta-clicked')"
  >
    <template #default>
      <slot />
    </template>
    <template #title>
      <div class="mb-8 truncate">
        <p class="truncate" :class="{ italic: !opt?.name }">
          {{ opt?.name || 'No opportunity name' }}
        </p>
        <ul>
          <li v-if="opt?.close_date" class="ml-4">
            • Close Date: {{ formatDate(opt?.close_date) }}
          </li>
          <li v-if="opt?.configured_amount" class="ml-4">
            • Opportunity Amount:
            {{ `$${formatNumberWithCommas(opt?.configured_amount)}` }}
          </li>
          <li v-if="opt?.sales_stage" class="ml-4">
            • Stage: {{ opt?.sales_stage }}
          </li>
        </ul>
      </div>
    </template>
  </BittsTooltip>
</template>

<script setup>
import { BittsTooltip } from '@crossbeam/bitts';

import { extractLocalDate, formatNumberWithCommas } from '@/utils';

defineProps({
  opt: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  // hover trigger does not work well for the select options so using showTooltip for that
  showTooltip: {
    type: Boolean,
    default: null,
  },
  mountToBody: {
    type: Boolean,
    default: false,
  },
  overlayClass: {
    type: String,
    default: '',
  },
  placement: {
    type: String,
    default: 'bottom',
  },
});

function formatDate(date) {
  return extractLocalDate(date);
}

const emit = defineEmits(['tooltip-cta-clicked']);
</script>
