<template>
  <div
    v-if="props.params.data.is_greenfield && !props.params.data.record_id"
    data-testid="c-collaborate-integration-cell-renderer__empty-cell"
  />
  <div
    v-else
    class="w-full"
    data-testid="collaborate-integration-cell__link"
    @[handleClick]="onRouteToSales"
  >
    <BittsTooltip
      v-if="params.value?.submitted_on"
      :mount-to-body="true"
      placement="bottomLeft"
    >
      <template #default>
        <div
          class="flex items-center truncate"
          data-testid="c-collaborate-integration-cell-renderer__status"
        >
          <BittsSvg :svg="svgForDiv" class="w-16 h-16 mr-8" />
          <p class="truncate">
            {{ formattedCellValue }}
          </p>
        </div>
      </template>
      <template #title>
        Sent to {{ formattedValue }} on {{ formattedDate }}
      </template>
    </BittsTooltip>
  </div>
</template>
<script setup>
import { BittsSvg, BittsTooltip } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { CROSSBEAM_FOR_SALES } from '@/constants/shared_list';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const router = useRouter();
const isCrossbeamForSalesCell = computed(
  () => props.params.colDef.colId === CROSSBEAM_FOR_SALES,
);

const formattedValue = computed(() =>
  !isCrossbeamForSalesCell.value
    ? props.params.value.company
    : `${props.params.value.first_name} ${props.params.value.last_name}`,
);
const formattedDate = computed(() =>
  DateTime.fromISO(props.params.value?.submitted_on).toFormat('MMM d, yyyy'),
);

const formattedCellValue = computed(() => `Sent ${formattedDate.value}`);

const svgForDiv = computed(() =>
  isCrossbeamForSalesCell.value
    ? 'crossbeamForSalesTag'
    : 'partnerStackAltLogo',
);

const handleClick = computed(() =>
  isCrossbeamForSalesCell.value && props.params.value?.se_list_id
    ? 'click'
    : null,
);

async function onRouteToSales() {
  await router.push({
    path: `/co-sell/lists/${props.params.value?.se_list_id}`,
    replace: true,
  });
}
</script>
