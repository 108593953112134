import axios from 'axios';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { MDM_PROPERTIES } from '@/constants/mdm';
import {
  PotentialRevenueCustomAmount,
  PotentialRevenueField,
  PotentialRevenueSettings,
  PotentialRevenueSource,
} from '@/interfaces/potential_revenue';
import { initStore } from '@/stores/store-utils';
import urls from '@/urls';

import { useBillingStore } from './BillingStore';
import { useSourcesStore } from './SourcesStore';

export const usePotentialRevenueStore = defineStore('PotentialRevenue', () => {
  const potentialRevenueSettings = ref<PotentialRevenueSettings>({});
  const billingStore = useBillingStore();
  const sourcesStore = useSourcesStore();

  const { error, ready, readySync, running, refresh } = initStore(async () => {
    if (billingStore.isFreeTier) return;
    await sourcesStore.readySync;
    const { data } = await axios.get(urls.org.potentialRevenueSettings);
    potentialRevenueSettings.value = data;
  });

  refresh({ useCache: true });

  const allSettingsUnchecked = computed<boolean>(() => {
    if (billingStore.isFreeTier) return false;
    return (
      !potentialRevenueSettings.value.our_populations_potential_influence
        ?.length ||
      !potentialRevenueSettings.value.partner_populations_potential_influence
        ?.length
    );
  });
  const potentialRevenueColumnSettings = computed<PotentialRevenueCustomAmount>(
    () => {
      return (
        potentialRevenueSettings.value.amount_column_potential_influence || {}
      );
    },
  );

  /* TODO: When SourcesStore gets typed, replace these with Source and SourceField interfaces */
  const potentialRevenueSettingsSource = computed<
    PotentialRevenueSource | undefined
  >(() => {
    // today we only let you configure a custom amount field for a single feed. If that is set in org settings, this will attempt to return the deal source for that feed
    const dealSources = sourcesStore.dealSources;
    if (!dealSources.length) return {};
    const sourceIds = Object.keys(potentialRevenueColumnSettings.value);
    if (sourceIds.length) {
      const piSourceIdStr = sourceIds[0]; // assuming only one source id for now, because that is all we support
      const source = dealSources.find(
        (source: PotentialRevenueSource) =>
          source.id === parseInt(piSourceIdStr || ''),
      );
      return source || {};
    }
    // if unable to find a source for a configured amount field, return the first deal source
    return dealSources[0];
  });
  const potentialRevenueSettingsSourceField = computed<
    PotentialRevenueField | undefined
  >(() => {
    if (!potentialRevenueSettingsSource.value) return;
    if (!Object.keys(potentialRevenueSettingsSource.value).length) return {};
    const sourceFieldIds = Object.values(potentialRevenueColumnSettings.value);
    if (sourceFieldIds[0]) {
      const piSourceFieldId = Math.floor(sourceFieldIds[0]);
      return (
        potentialRevenueSettingsSource.value.fields?.find(
          (field: PotentialRevenueField) => field.id === piSourceFieldId,
        ) || {}
      );
    }
    return (
      potentialRevenueSettingsSource.value.fields?.find(
        (item: PotentialRevenueField) =>
          item.mdm_property === MDM_PROPERTIES.deal.AMOUNT,
      ) || {}
    );
  });

  return {
    error,
    ready,
    readySync,
    running,
    allSettingsUnchecked,
    potentialRevenueColumnSettings,
    refreshPotentialRevenueStore: refresh,
    potentialRevenueSettings,
    potentialRevenueSettingsSource,
    potentialRevenueSettingsSourceField,
  };
});
