import {
  PARTNER_POPULATION_RULE,
  PARTNER_RULE,
  POPULATION_RULE,
} from '@/constants/data_shares';
import { MDM_TYPES } from '@/constants/mdm';
import { DataSharingVisibility } from '@/types/data_shares';

export interface SharedField {
  schema: string;
  table: string;
  pg_data_type: string;
  column: string;
  id: number;
  data_type: string;
  mdm_type:
    | typeof MDM_TYPES.ACCOUNT
    | typeof MDM_TYPES.CONTACT
    | typeof MDM_TYPES.DEAL
    | typeof MDM_TYPES.DEAL_CONTACT
    | typeof MDM_TYPES.LEAD
    | typeof MDM_TYPES.USER;
  source_id: number;
  display_name: string;
  source_field_id: number;
}

export interface SharedFieldWithPartnerOrgId extends SharedField {
  partner_org_id: number;
}

interface DataSharePartnerPopulation {
  partner_org_id: number;
  partner_population_id: number;
}

export interface IncomingGreenfieldShare {
  partner_population_ids: number[];
  partner_organization_id: number;
}

export interface IncomingDataShare {
  id: number;
  partner_org_id: number;
  partner_population_id: number;
  shared_fields: SharedField[];
  population_ids: number[];
}

export interface OutgoingDataShare {
  id: number;
  population_id: number;
  source_field_ids: number[];
  partner_populations: DataSharePartnerPopulation[];
}

interface IncomingSharingRuleBase {
  id: number;
  partner_org_id: number;
  partner_population_id: number;
  shared_fields: SharedField[];
}

interface IncomingSharingRuleDefault extends IncomingSharingRuleBase {
  is_override: false;
  population_id: null;
}

interface IncomingSharingRuleOverride extends IncomingSharingRuleBase {
  is_override: true;
  population_id: number;
}

export type IncomingSharingRule =
  | IncomingSharingRuleDefault
  | IncomingSharingRuleOverride;

interface OutgoingSharingRuleBase {
  id: number;
  population_id: number;
  source_field_ids: number[];
  visibility: DataSharingVisibility;
}

interface OutgoingSharingRulePopulation extends OutgoingSharingRuleBase {
  partner_org_id: null;
  partner_population_id: null;
  rule_type: typeof POPULATION_RULE;
}

interface OutgoingSharingRulePartner extends OutgoingSharingRuleBase {
  partner_org_id: number;
  partner_population_id: null;
  rule_type: typeof PARTNER_RULE;
}
interface OutgoingSharingRulePartnerPopulation extends OutgoingSharingRuleBase {
  partner_org_id: number;
  partner_population_id: number;
  rule_type: typeof PARTNER_POPULATION_RULE;
}

export type OutgoingSharingRule =
  | OutgoingSharingRulePopulation
  | OutgoingSharingRulePartner
  | OutgoingSharingRulePartnerPopulation;

export interface OutgoingSharingRuleMap {
  [PARTNER_POPULATION_RULE]?: Record<number, OutgoingSharingRule[]>;
  [PARTNER_RULE]?: Record<number, OutgoingSharingRule[]>;
  [POPULATION_RULE]?: Record<number, OutgoingSharingRule[]>;
}

type share =
  | IncomingDataShare
  | OutgoingDataShare
  | IncomingSharingRule
  | OutgoingSharingRule;

export function isIncomingShare(
  share: share,
): share is IncomingDataShare | IncomingSharingRule {
  return (share as IncomingDataShare).partner_org_id !== undefined;
}

export function isOutgoingShare(
  share: share,
): share is OutgoingDataShare | OutgoingSharingRule {
  return !isIncomingShare(share);
}
