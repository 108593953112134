import axios from 'axios';
import { camelCase, flatten, reduce, replace } from 'lodash';
import { DateTime } from 'luxon';
import { isHttpsUri } from 'valid-url';
import { onMounted, onUnmounted } from 'vue';

import { OPERATORS } from '@/constants/populations';
import { captureException } from '@/errors';

/* Whether the user's operating system meta key is Ctrl or Meta */
export const ctrlOrMeta = () => {
  if (operatingSystem() === 'macOS') {
    return '⌘';
  }
  return 'ctrl';
};

/* Helper for ctrlOrMeta */
const operatingSystem = () => {
  const appVersion = navigator.appVersion.toLowerCase();
  if (appVersion.indexOf('win') > -1) {
    return 'Windows';
  } else if (appVersion.indexOf('mac') > -1) {
    return 'macOS';
  }
  return '*nix';
};

/* Search at the top-level of an object for a case-insensitive string key */
export const shallowObjectSearch = (obj, keys, search) => {
  for (const key of keys) {
    if (typeof obj[key] === 'string') {
      if (obj[key]?.toLowerCase().includes(search.toLowerCase())) return true;
    }
  }
  return false;
};

/* Whether the element is in the visible viewport */
export const isElementInViewport = (element) => {
  const bounding = element.getBoundingClientRect();
  const result =
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight);
  return result;
};

/* Truthy or zero, used to check for truthiness when zero is possible */
export const isTruthyOrZero = (number) => {
  return number || number === 0;
};

/* Generates UUID4 string */
export const uuid4 = () => {
  const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  return template.replace(/[xy]/g, (c) => {
    /* eslint-disable-next-line custom/no-bitwise-operators */
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line custom/no-bitwise-operators
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

/* Gets all URL parameters by a given key */
export const getUrlParams = (paramKey) => {
  const params = new URLSearchParams(location.search);
  return params.getAll(paramKey);
};

/* Opens Itercom with given message if enabled, otherwise opens email client */
export const intercomOrEmailMessage = (to, subject, body) => {
  if (window.Intercom) {
    window.Intercom('showNewMessage', body);
  } else {
    const encodedSubject = encodeURIComponent(subject);
    const encodedBody = encodeURIComponent(body);
    location.href = `mailto:${to}?subject=${encodedSubject}&body=${encodedBody}`;
  }
};

export const copyToClipBoard = async (textToCopy) => {
  if (!navigator.clipboard) {
    copyTextFallback(textToCopy);
    return;
  }
  try {
    await navigator.clipboard.writeText(textToCopy);
  } catch (err) {
    captureException(err);
  }
};

/* Helper for copyToClipBoard */
const copyTextFallback = (textToCopy) => {
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    captureException(err);
  }
  document.body.removeChild(textArea);
};

/* Validity of origins for oAuth applications */
export const areAllOriginsValid = (origins) => {
  return origins.every((origin) => isOriginValid(origin));
};
export const areAllCallbacksValid = (callbacks) => {
  return callbacks.every((callback) => isCallbackValid(callback));
};

/* Helpers for validity checks */
const isOriginValid = (tag) => {
  return isUrlValid(tag, ['chrome-extensions://']);
};
const isCallbackValid = (tag) => {
  return isUrlValid(tag, []);
};

export const isExternalUrl = (url) => {
  if (!url) return false;
  try {
    const externalUrl = new URL(url);
    if (externalUrl.href) return true;
  } catch (_err) {
    /* Will throw if not a valid URL */
    return false;
  }
};

/* Checks whether URL is https or matches allowed protocol list */
export const isUrlValid = (tag, allowedProtocols = []) => {
  let matchesAllowedProtocols = false;
  allowedProtocols.forEach((allowedProtocol) => {
    if (tag.startsWith(allowedProtocol)) {
      matchesAllowedProtocols = true;
    }
  });
  if (tag.startsWith('https://')) {
    return true;
  } else if (matchesAllowedProtocols) {
    return true;
  }
  return isHttpsUri(replace(tag, '*', 'x'));
};

/* Gets mdm field by property type off item, or returns null */
export const getMdmField = (item, mdmPropertyName, isOursFilter = null) => {
  if (!item?.fields) return null;
  const field = item.fields.find((field) => {
    // filter for mdm property matching
    if (field.mdm_property !== mdmPropertyName) return false;
    // is_ours can either be true or false. if we care about that value, then supply the optional isOursFilter
    if (isOursFilter !== null) return field.is_ours === isOursFilter;
    return true;
  });
  return field ? field.value : null;
};

/* Routes to next route, if it exists */
export const resolveNext = (router, next) => {
  if (next === null || typeof next === 'string') {
    return next;
  }
  return router.resolve(next);
};

/* Used to switch to specific organization by orgId */
export const switchOrganization = async (
  router,
  orgId,
  to = { name: 'main' },
) => {
  const destination = router.resolve(to);
  await router.push({
    name: 'switch_org',
    query: {
      organization_id: orgId,
      next: destination.href,
    },
  });
};

/* convert filters/columns to encoded string for URL query, and back */
export const encodeReportInfoForUrl = (info) => {
  const str = JSON.stringify(info);
  return window.btoa(encodeURIComponent(str));
};

export const decodeReportInfoForUrl = (info) => {
  const str = decodeURIComponent(window.atob(info));
  return JSON.parse(str);
};

export const formatNumberWithCommas = (num) => {
  if (num === null || num === undefined) return num;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const populationProcessedAtText = (str) => {
  return str ? DateTime.fromISO(str).toRelative() : '';
};

export const returnTrimmedDomain = (domain) => {
  if (!domain) return '';
  return domain.replace(/^https?:\/\//, '');
};

export const transformedFilterParts = (filterParts, fieldsToRemove = []) => {
  return filterParts.map((filterPart) => {
    const updatedFilterPart = fieldsToRemove.reduce((acc, field) => {
      const { [field]: _, ...rest } = acc;
      return rest;
    }, filterPart);

    const matchingOperator = OPERATORS.find(
      (operator) => operator.id === updatedFilterPart.operator,
    );
    if (!matchingOperator || !matchingOperator.filterPartOverrides) {
      return updatedFilterPart;
    }
    return {
      ...updatedFilterPart,
      ...matchingOperator.filterPartOverrides,
    };
  });
};

export const toCamelCase = (obj) => {
  const transformed = {};
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) {
      transformed[camelCase(key)] = obj[key];
    } else if (typeof obj[key] === 'object') {
      transformed[camelCase(key)] = toCamelCase(obj[key]);
    } else {
      transformed[camelCase(key)] = obj[key];
    }
  });

  return transformed;
};

export const indexBy = (arr, key) => {
  return reduce(
    arr,
    (acc, el) => {
      acc[el[key]] = el;
      return acc;
    },
    {},
  );
};

export const jsonParseSafe = (s) => {
  try {
    return JSON.parse(s);
  } catch (_SyntaxError) {
    return null;
  }
};

// eslint-disable-next-line
export const REGEX_URL = /^[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?$/i

export const trim = (value) => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value.trim();
};

export const isEmailValid = (str) => {
  const VALID_EMAIL = /\S+@\S+\.\S+/;
  return VALID_EMAIL.test(str.toLowerCase());
};

export const popupCenter = ({ url, title, w, h, win }) => {
  const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
  const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
  return win.open(
    url,
    title,
    `scrollbars=yes,
    width=${w},
    height=${h},
    top=${y},
    left=${x}`,
  );
};

export function createAxiosWithRetry() {
  const userAxios = axios.create();

  userAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      try {
        return axios.request(error.config);
      } catch (_err) {
        return Promise.reject(error);
      }
    },
  );

  return userAxios;
}

export const hubSpotFormApi = async (formId, args, path, envID) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${envID}/${formId}`;
  const mapped = [];
  for (const key in args) {
    const fields = {
      objectTypeId: '0-1',
      name: key,
      value: args[key],
    };
    mapped.push(fields);
  }
  const payload = {
    fields: mapped,
    context: {
      pageUri: `www.crossbeam.com/${path}`,
      pageName: path,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: 'I agree to allow Crossbeam to store and process my personal data.',
      },
    },
  };
  await axios.post(url, payload, {
    withCredentials: false,
  });
};

export const fullPluralize = (numberOrArray, textSingle, textPlural) => {
  const len = Array.isArray(numberOrArray)
    ? numberOrArray.length
    : numberOrArray;
  if (len === 0) {
    return `No ${textPlural}`;
  } else if (len === 1) {
    return `1 ${textSingle}`;
  }
  return `${len} ${textPlural}`;
};

export const simplePluralize = (items, text) => {
  return fullPluralize(items, text, `${text}s`);
};

export const extractDataShareIds = (elements) => {
  return flatten(
    Object.keys(elements).map((key) => {
      return 'items' in elements[key]
        ? flatten(elements[key].items.map((item) => item.data_share_ids))
        : elements[key].data_share_ids;
    }),
  );
};

export const configureGlobalSettings = (csrfToken) => {
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.headers.post['X-CSRFToken'] = csrfToken;
  axios.defaults.headers.put['X-CSRFToken'] = csrfToken;
  axios.defaults.headers.delete['X-CSRFToken'] = csrfToken;
  axios.defaults.headers.patch['X-CSRFToken'] = csrfToken;
};

export const centsToDollars = (num) => {
  const CENTS_IN_CURRENCY = 100;
  const inDollars = `$${formatNumberWithCommas(num / CENTS_IN_CURRENCY)}`;
  if (!inDollars.includes('.')) return inDollars;

  /* Append an extra zero if the result is 150.2, 100.1, etc. */
  const [dollars, cents] = inDollars.split('.');
  return `${dollars}.${cents.length === 1 ? `${cents}0` : cents}`;
};

export const extractLocalDate = (dateTimeString) =>
  DateTime.fromISO(dateTimeString).toLocaleString({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

/* Promise that resolves once the HTML node is rendered in the DOM */
export function waitForElement(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const watchForResize = (selector, changeHandler) => {
  let resizeObserver;
  onMounted(() => {
    const element = document.querySelector(selector);
    resizeObserver = new ResizeObserver((entries) =>
      changeHandler({
        height: entries[0].target.clientHeight,
        width: entries[0].target.clientWidth,
        scrollHeight: entries[0].target.scrollHeight,
        scrollWidth: entries[0].target.scrollWidth,
        element: entries[0].target,
      }),
    );
    resizeObserver.observe(element);
  });

  onUnmounted(() => resizeObserver.disconnect());
};

/* mainly used to quickly emulate a slow XHR */
export const slowResponse = (delayedPromise, delay) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      delayedPromise.then((resp) => {
        resolve(resp);
      });
    }, delay);
  });
};

export const currencyFormatter = (value, includeCents = false) => {
  if (!value) return '$--';

  let formattedValue = value;

  // If value has cents, round to the nearest dollar
  if (!includeCents) {
    const hasCents = formattedValue % 1 !== 0;
    if (hasCents) formattedValue = Math.round(formattedValue);
  }

  const options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: includeCents ? 2 : 0,
  };

  const usd = new Intl.NumberFormat('en-US', options);
  return usd.format(formattedValue);
};

/**
 * Returns a sorting function for a list of objects. Sorts by their key in either ascending or descending order.
 * @param {string} [field] The field to sort by, e.g. name, date etc
 * @param {string} [order] The order of the sort, either ascending or descending
 * @param {string} [type] The type of the sort, e.g. string; raw (numbers, luxon DateTimes); timestamp (ISO); or length for arrays
 * @returns {Function} The function to sort the list
 */

const notThere = new Set([null, undefined]);
export function sortByKey(field = 'name', order = 'desc', type = 'string') {
  const defaultSort = 0;
  function notThereCheck(val1, val2) {
    if (notThere.has(val1) && notThere.has(val2)) return defaultSort;
    if (notThere.has(val1) && !notThere.has(val2))
      return order === 'desc' ? 1 : -1;
    if (!notThere.has(val1) && notThere.has(val2))
      return order === 'desc' ? -1 : 1;
    return null;
  }

  return function (a, b) {
    const val1 = a[field];
    const val2 = b[field];
    const notThereRes = notThereCheck(val1, val2);
    if (notThereRes !== null) return notThereRes;
    if (type === 'string') {
      if (order === 'desc') return val1.localeCompare(val2);
      return val2.localeCompare(val1);
    }

    if (type === 'raw') {
      if (val1 === val2) return defaultSort;
      if (order === 'desc') return val1 > val2 ? -1 : 1;
      return val1 > val2 ? 1 : -1;
    }

    if (type === 'timestamp') {
      const result = new Date(val1) - new Date(val2);
      if (result === 0) return defaultSort;
      if (order === 'desc') return result > 0 ? -1 : 1;
      return result > 0 ? 1 : -1;
    }

    if (type === 'length') {
      if (val1.length === val2.length) return defaultSort;
      if (order === 'desc') return val1.length > val2.length ? -1 : 1;
      return val1.length > val2.length ? 1 : -1;
    }

    return defaultSort;
  };
}

/* Used to dynamically inject strings using v-html. Only use with input from Crossbeam, not w/ outside data */
export function injectStrings(str, { values, bold, italic, underline } = {}) {
  for (const injection of values) {
    let wrappedInjection = injection;
    if (bold) wrappedInjection = `<b>${wrappedInjection}</b>`;
    if (italic) wrappedInjection = `<i>${wrappedInjection}</i>`;
    if (underline)
      wrappedInjection = `<span class="underline decoration-dashed decoration-neutral-text-placeholder underline-offset-4">${wrappedInjection}</span>`;
    str = str.replace('%s', wrappedInjection);
  }
  return str;
}

/* sleep blocks execution for the number of seconds provided. Useful for testing slow APIs */
export async function sleep(ms = 3000) {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

/* Makes a comma separated readable list out of the values presented */
export function formatList(items, { bold, italic, underline } = {}) {
  if (items.length === 1) {
    return injectStrings('%s', { values: items, bold, italic, underline });
  }

  const firstItems = items.slice(0, -1);
  return injectStrings(`${firstItems.map(() => '%s').join(', ')} and %s`, {
    values: items,
    bold,
    italic,
    underline,
  });
}

/** Ensures that every key in an object contains a default value if it was not defined
 * @param {obj} [object] The object that may be missing values
 * @param {fallbackMap} [object] The default object, which contains fallbacks for every required field
 */
export function ensureDefaultsExist(obj, fallbackMap) {
  const res = { ...obj };
  for (const [key, value] of Object.entries(fallbackMap)) {
    if (res[key] === undefined) res[key] = value;
  }
  return res;
}
