<template>
  <component
    :is="linkedOrUnlinkedRecord"
    v-bind="routerProps"
    class="flex items-center justify-between max-w-full"
  >
    <div
      class="flex items-center max-w-[80%]"
      data-testid="c-collaborate-record-cell__record"
    >
      <BittsTooltip placement="bottom" :mount-to-body="true" class="w-full">
        <p
          class="truncate"
          data-testid="c-collaborate-record-cell__record-name"
        >
          {{ params.value }}
        </p>
        <p
          class="truncate text-neutral-text-weak text-sm"
          data-testid="c-collaborate-record-cell__website"
        >
          {{ params.data.website }}
        </p>
        <template #title>
          <p>
            {{ params.value }}
          </p>
          <p class="text-sm">
            {{ params.data.website }}
          </p>
        </template>
      </BittsTooltip>
    </div>
    <BittsTooltip
      v-if="tooltipData"
      placement="bottomLeft"
      :data-testid="`${tooltipData.test_id}-tooltip`"
      :mount-to-body="true"
      overlay-class="c-collaborate-record-cell__warning"
      :include-cta="tooltipData?.ctaLabel"
      :button-text="tooltipData?.ctaLabel"
      @cta-clicked="goToTimeline"
    >
      <FontAwesomeIcon
        :icon="cellIcon.icon"
        :style="{ height: '12px', width: '12px', color: 'currentColor' }"
        :class="cellIcon.class"
      />
      <template #title>
        {{ tooltipData.text }}
      </template>
    </BittsTooltip>
    <div v-else class="mr-2">
      <SharedListShareActionDropdown
        data-testid="collaborate-share-action-dropdown"
        :single-send="true"
        :disabled="!!params.context.rowsSelected.length"
        :params
        :list="currentList"
        :rows-selected="params.context.rowsSelected"
        @option-selected="onOptionSelected"
      />
    </div>
  </component>
</template>
<script setup>
import { BittsTooltip } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import SharedListShareActionDropdown from '@/components/shared-list/SharedListShareActionDropdown.vue';

import useIntegrations from '@/composables/useIntegrations';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const router = useRouter();
const { partnerStackIsActive } = useIntegrations();

const isPartnerGreenfield = computed(
  () => props.params.data.is_greenfield && !props.params.data.record_id,
);
const isOwnDataLive = computed(() => props.params.data.own_data_live);
const sendToSalesError = computed(() => props.params.sendToSalesError);

const canSendToSales = computed(
  () => props.params.data.can_send_to_sales && !sendToSalesError?.value,
);
const cannotActOnRecord = computed(
  () =>
    !isOwnDataLive.value ||
    (!partnerStackIsActive.value && !canSendToSales.value),
);
const linkedOrUnlinkedRecord = computed(() =>
  props.params.data.source_id ? 'RouterLink' : 'div',
);

const currentList = computed(
  () => props.params.colDef.headerComponentParams.currentList,
);

const routerProps = computed(() => ({
  to: {
    name: 'individual_record',
    params: {
      source_id: props.params.data.source_id,
      source_primary_key: props.params.data.record_id,
    },
  },
}));

async function goToTimeline() {
  await router.push({
    name: 'activity_timeline',
    params: {
      source_id: props.params.data.source_id,
      source_primary_key: props.params.data.record_id,
    },
  });
}
const cellIcon = computed(() => {
  if (isPartnerGreenfield.value) {
    return {
      icon: ['fas', 'leaf'],
      class: 'text-success-accent',
    };
  }
  return {
    icon: ['fas', 'diamond-exclamation'],
    class: 'text-warning-accent',
  };
});

const tooltipData = computed(() => {
  if (isPartnerGreenfield.value) {
    return {
      test_id: 'partner-greenfield',
      text: 'Your partner has shared a Greenfield account',
    };
  }
  if (cannotActOnRecord.value) {
    if (sendToSalesError.value) {
      return {
        test_id: sendToSalesError.value.type,
        text: sendToSalesError.value.shortDescription,
      };
    }
    if (isOwnDataLive.value) {
      return {
        test_id: 'on-timeline',
        text: 'The record does not exist in either your population or your partner’s population. View this record’s timeline for population and overlap movement.',
        ctaLabel: 'View Timeline',
      };
    }
    if (!canSendToSales.value) {
      return {
        test_id: 'not-on-timeline',
        text: 'The record does not exist in either your population or your partner’s population.',
      };
    }
  }

  return null;
});

function onOptionSelected(option) {
  const {
    name,
    website,
    record_id: recordId,
    source_id: sourceId,
    is_greenfield: isGreenfield,
    row_id: rowId,
  } = props.params.data;
  const rowInfo = {
    ...props.params.data,
    name,
    website,
    recordId,
    sourceId,
    listId: currentList.value.id,
    rowId,
    isGreenfield,
  };
  props.params.context.onShowModal(option, rowInfo);
}
</script>
<style lang="pcss">
.c-collaborate-record-cell__warning {
  @apply mr-8;
}
</style>
