<template>
  <div class="w-full h-full flex justify-start items-center">
    <BittsChip
      v-if="props.params.value"
      :removable="false"
      :table-cell-style="true"
      :text="props.params.value"
      class="c-collaborate-user-cell__chip"
    >
      <template #icon>
        <FontAwesomeIcon
          v-if="isOwnColumn"
          :icon="['fas', 'user']"
          :style="{ height: '12px', width: '12px' }"
          class="mr-4 text-info-accent"
        />
        <FontAwesomeIcon
          v-else
          :icon="['fas', 'user']"
          :style="{ height: '12px', width: '12px' }"
          class="mr-4 text-accent-accent"
        />
      </template>
    </BittsChip>
  </div>
</template>
<script setup>
import { BittsChip } from '@crossbeam/bitts';

import { computed } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const isOwnColumn = computed(
  () => props.params.column.colDef.cellRendererParams.isOwnColumn,
);
</script>
<style lang="pcss">
.c-collaborate-user-cell__chip {
  @apply bg-neutral-background rounded-2xl truncate;
  span {
    @apply truncate;
  }
}
</style>
