<template>
  <BittsModal
    name="configure-feed"
    :visible="true"
    :show-buttons="false"
    :loading="loading"
    @closed="modalClosed('data-sources')"
  >
    <template #content>
      <SetupDataSource
        :feed-id="feedId"
        :feed="feed"
        :next="$route.params.next"
        loading-container-class="c-loading--datasources"
        class="c-feed-modal-wrap"
        @save="updateNickname"
        @close="modalClosed(closeDestination)"
      >
        <template #closeButton>
          <BittsButton
            type="neutral"
            variant="outline"
            :text="closeText"
            class="w-full"
            @click.prevent="modalClosed(closeDestination)"
          />
        </template>
      </SetupDataSource>
    </template>
  </BittsModal>
</template>

<script>
import { BittsButton, BittsModal } from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import axios from 'axios';
import { mapActions } from 'pinia';
import { computed, onUnmounted, ref } from 'vue';

import SetupDataSource from '@/components/data-sources/SetupDataSource.vue';

import { useFeedsStore, useFlashesStore } from '@/stores';
import urls from '@/urls';

export default {
  name: 'DataSourceOauthReturn',
  components: {
    BittsButton,
    BittsModal,
    SetupDataSource,
  },
  props: {
    feedId: {
      type: Number,
      required: true,
    },
  },

  setup() {
    const feed = ref({});

    useHead({
      title: computed(() => feed.value?.integration?.friendly_name),
      titleTemplate: 'Edit - %s - Data Sources - Crossbeam',
    });

    onUnmounted(() => {
      useHead({
        title: 'Data Sources - Crossbeam',
        titleTemplate: null,
      });
    });

    return {
      feed,
    };
  },

  data() {
    return {
      showConfigureFeedModal: false,
      loading: true,
    };
  },
  computed: {
    closeText() {
      const closeTextMap = {
        google_sheets: 'Add Google sheet',
      };
      return closeTextMap[this.feed?.integration.type] || 'Close';
    },
    closeDestination() {
      const closeDestinationMap = {
        google_sheets: 'google-sheets',
      };
      return closeDestinationMap[this.feed?.integration.type] || 'data-sources';
    },
    integrationName() {
      return this.feed?.integration.friendly_name;
    },
  },
  async created() {
    await this.refreshFeedsStore();
    this.feed = this.getFeedById(this.feedId);
    this.loading = false;
  },
  methods: {
    ...mapActions(useFeedsStore, ['getFeedById']),
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useFeedsStore, ['refreshFeedsStore']),
    async modalClosed(destination) {
      await this.refreshFeedsStore();
      const feed = this.getFeedById(parseInt(this.$route.params.id));
      if (!feed.initial_sync_complete && this.$route.query.successfulOauth) {
        this.addSuccessFlash({
          message: 'Data Source Added',
          description: 'Please wait a few minutes for data to finish syncing.',
        });
      }
      this.$router.push({ name: destination });
    },
    async updateNickname(payload, destination) {
      try {
        await axios.patch(
          urls.feeds.patch(payload.feedId),
          payload.axiosPayload,
        );
        this.addSuccessFlash({
          message: 'Data Source Renamed',
        });
      } catch (_err) {
        this.addErrorFlash({
          message: 'Error renaming data source',
          description: 'That data source could not be renamed.',
        });
      } finally {
        this.modalClosed(destination);
      }
    },
  },
};
</script>
<style lang="pcss" scoped>
.c-loading--datasources {
  height: 490px;
}
</style>
