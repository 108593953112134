<template>
  <BittsDrawer
    v-if="hasPartnerCloudFlag && !tooPopular"
    :mask-closable="true"
    :show-footer="true"
    :visible="showDrawer"
    :loading="dataLoading"
    :info="displayInfo"
    :status-text="statusText"
    :status-type="statusType"
    @closed="closeDrawerHandler"
  >
    <template #content>
      <BittsLoading :is-loading="dataLoading">
        <div v-if="!dataLoading" class="py-16 px-24">
          <p v-if="friggDetails.subtitle" class="text-neutral-800 leading-6">
            {{ friggDetails.subtitle }}
          </p>
          <p class="text-neutral-500 leading-6 text-sm mb-24">
            {{ integrationDescription }}
          </p>
          <FriggFormBuilder
            v-if="!dataLoading"
            :frigg-details="friggDetails"
            :config-response="friggConfig"
            :saving="saving"
            @saved="onAddOrSave"
          />
        </div>
      </BittsLoading>
    </template>
    <template #footer>
      <BittsButton
        :text="saveButtonText"
        :disabled="saving"
        :loading="saving"
        size="large"
        class="w-full"
        data-testid="frigg-drawer-save-button"
        @click="saving = true"
      />
    </template>
  </BittsDrawer>
</template>

<script>
import { BittsButton, BittsDrawer, BittsLoading } from '@crossbeam/bitts';

import axios from 'axios';
import { mapActions, mapState } from 'pinia';

import FriggFormBuilder from '@/components/integrations/FriggFormBuilder.vue';

import { FRIGG_THRESHOLD } from '@/constants/frigg';
import {
  useFeatureFlagStore,
  useFlashesStore,
  usePartnerCloudStore,
  usePartnersStore,
  useReportsStore,
} from '@/stores';
import { PC_FRIGG_INTEGRATIONS } from '@/stores/PartnerCloudStore';
import urls from '@/urls';

export default {
  name: 'FriggSettingsDrawer',
  components: {
    BittsButton,
    BittsDrawer,
    BittsLoading,
    FriggFormBuilder,
  },
  data() {
    return {
      friggDetails: {
        display: {},
      },
      friggType: this.$route.params.integration_type,
      dataLoading: true,
      saving: false,
      friggConfig: {},
      showDrawer: false,
    };
  },
  computed: {
    ...mapState(useFeatureFlagStore, ['hasFeatureFlag']),
    ...mapState(usePartnerCloudStore, ['getEnabledFriggByType']),
    ...mapState(usePartnersStore, ['overlapCounts']),
    hasPartnerCloudFlag() {
      return this.hasFeatureFlag(PC_FRIGG_INTEGRATIONS[this.friggType]);
    },
    saveButtonText() {
      if (this.saving) return 'Saving...';
      return this.enabled ? 'Save Changes' : 'Install Integration';
    },
    tooPopular() {
      return this.overlapCounts.overlap_usage?.total > FRIGG_THRESHOLD;
    },
    enabled() {
      return this.friggDetails.status === 'ENABLED';
    },
    statusText() {
      return {
        NEEDS_CONFIG: 'Needs Configuration',
        ENABLED: 'Active',
      }[this.friggDetails.status];
    },
    statusType() {
      return {
        NEEDS_CONFIG: 'warning',
        ENABLED: 'success',
      }[this.friggDetails.status];
    },
    displayInfo() {
      return {
        subtitle: 'Integration Status',
        title: this.friggDetails.name,
        domain: this.friggDetails.display.icon,
        org: this.avatarOrg,
      };
    },
    integrationDescription() {
      return this.friggDetails.longDescription
        ? this.friggDetails.longDescription
        : this.friggDetails.description;
    },
    avatarOrg() {
      return this.friggDetails.avatar
        ? {
            logo_url: new URL(
              `../../assets/pngs/partner-cloud/${this.friggDetails.avatar}`,
              import.meta.url,
            ).href,
          }
        : { clearbit_domain: this.friggDetails.display.icon };
    },
  },
  async created() {
    if (!this.hasPartnerCloudFlag) {
      this.redirectToIntegrations();
      return;
    }
    try {
      this.dataLoading = true;
      this.showDrawer = true;
      await this.refresh();
      this.friggDetails = this.getEnabledFriggByType(this.friggType);
      if (this.tooPopular || !this.friggDetails) {
        this.addErrorFlash({ message: 'Integration not found' });
        this.redirectToIntegrations();
        return;
      } else if (this.friggDetails.status === 'PROCESSING') {
        this.addSuccessFlash({
          message: 'Integration Processing',
          description:
            'You cannot change settings until processing is finished',
        });
        this.redirectToIntegrations();
        return;
      }
      const configUrl = urls.frigg.getConfigById(this.friggDetails.id);
      const { data: configResponse } = await axios.get(configUrl, {
        params: { integration_type: this.friggDetails.type },
      });
      this.friggConfig = configResponse;
    } catch (_err) {
      this.addErrorFlash({ message: 'Integration configuration not found' });
      this.redirectToIntegrations();
    } finally {
      this.dataLoading = false;
    }
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useReportsStore, ['refreshReportsStore']),
    ...mapActions(usePartnerCloudStore, ['refreshPartnerCloudStore']),
    async refresh() {
      const dispatches = [
        this.refreshPartnerCloudStore(),
        this.refreshReportsStore(),
      ];
      await Promise.all(dispatches);
    },
    async onAddOrSave({ payload, inputsValid }) {
      if (inputsValid && payload) {
        try {
          const configUrl = urls.frigg.getConfigById(this.friggDetails.id);
          await axios.patch(configUrl, payload, {
            params: { integration_type: this.friggDetails.type },
          });
          const savedText = this.enabled ? 'saved' : 'added';
          this.addSuccessFlash({
            message: `${this.friggDetails.name} Integration successfully ${savedText}`,
          });
          await this.refreshPartnerCloudStore();
          this.redirectToIntegrations();
        } catch (_err) {
          this.addErrorFlash({
            message: 'Settings not saved',
            description: `If this error persists, please contact
              <a target="_blank" href="mailto:support@crossbeam.com">support@crossbeam.com</a>.`,
          });
        }
      }
      this.saving = false;
    },
    redirectToIntegrations() {
      this.$router.push({ name: 'integrations' });
    },
    modalCloseHandler() {
      this.dataLoading = false;
      this.redirectToIntegrations();
    },
    closeDrawerHandler() {
      this.showDrawer = false;
      this.redirectToIntegrations();
    },
  },
};
</script>
