export const MDM_TO_RECORD_TYPE = {
  account: 'companies',
  contact: 'people',
  lead: 'people',
  user: 'people',
};

// display names for user source person_name, person_email, person_phone mdm property fields
export const ACCOUNT_OWNER_DISPLAY = 'Account Owner';
export const ACCOUNT_OWNER_NAME_DISPLAY = 'Account Owner Name';
export const ACCOUNT_OWNER_EMAIL_DISPLAY = 'Account Owner Email';
export const ACCOUNT_OWNER_PHONE_DISPLAY = 'Account Owner Phone';
export const AO_DISPLAY = 'AO';

const ACCOUNT = 'account';
const CONTACT = 'contact';
const DEAL = 'deal';
const DEAL_CONTACT = 'deal_contact_role';
const LEAD = 'lead';
const USER = 'user';

// source mdm types
export const MDM_TYPES = {
  ACCOUNT,
  CONTACT,
  DEAL,
  DEAL_CONTACT,
  LEAD,
  USER,
};

// source field mdm properties
export const MDM_PROPERTIES = {
  [ACCOUNT]: {
    BILLING_COUNTRY: 'company_country',
    CREATED_AT: 'company_created_at',
    EMPLOYEES: 'company_employees',
    ID: 'company_key',
    INDUSTRY: 'company_industry',
    NAME: 'company_name',
    TYPE: 'company_type',
    WEBSITE: 'company_website',
  },
  [CONTACT]: {
    CREATED_AT: 'person_created_at',
    EMAIL: 'person_email',
    FIRST_NAME: 'person_first_name',
    LAST_ACTIVITY_AT: 'person_last_activity_at',
    LAST_NAME: 'person_last_name',
    PHONE: 'person_phone',
    NAME: 'person_name',
    TITLE: 'person_title',
  },
  [DEAL]: {
    AMOUNT: 'deal_amount',
    CLOSE_DATE: 'deal_close_date',
    IS_CLOSED: 'deal_is_closed',
    IS_WON: 'deal_is_won',
    LAST_ACTIVITY_AT: 'deal_last_activity_at',
    NAME: 'deal_name',
    OPEN_DATE: 'deal_open_date',
    STAGE: 'deal_stage',
  },
  [DEAL_CONTACT]: {
    IS_PRIMARY: 'deal_contact_role_is_primary',
    ROLE: 'deal_contact_role_name',
  },
  [LEAD]: {
    CREATED_AT: 'person_created_at',
    EMAIL: 'person_email',
    ID: 'person_key',
    NAME: 'person_name',
    PHONE: 'person_phone',
    TITLE: 'person_title',
  },
  [USER]: {
    EMAIL: 'person_email',
    FIRST_NAME: 'person_first_name',
    LAST_NAME: 'person_last_name',
    NAME: 'person_name',
    PHONE: 'person_phone',
    TITLE: 'person_title',
  },
};

// MS Dynamics does not have a field with mdm property deal is closed, need to go off column name
export const MS_DYNAMICS_IS_CLOSED_COLUMN = 'statecode';
