<template>
  <BittsLayout :is-page="true">
    <PageTitle title="Profile & Preferences" />
    <BittsLayout :is-page="true" class="mt-0 pt-0 px-0">
      <template #main-content>
        <div class="flex flex-col">
          <ConfigureUserSettings />
          <BittsContainer class="user-settings__notifications mb-80">
            <div class="user-settings__notifications-header">
              <div class="font-bold text-lg"> Notifications </div>
              <div class="user-settings__notifications-checkboxes">
                <BittsCheckbox
                  v-if="!loading"
                  data-testid="email-notif-checkbox"
                  :checked="areAnyEmailNotificationsEnabled === ALL"
                  :indeterminate="areAnyEmailNotificationsEnabled === SOME"
                  label="Enable all email notifications"
                  @input="(e) => onBulkCheckNotif(e, email)"
                />
                <BittsCheckbox
                  v-if="!loading"
                  data-testid="app-notif-checkbox"
                  :checked="areAnyInAppNotificationsEnabled === ALL"
                  :indeterminate="areAnyInAppNotificationsEnabled === SOME"
                  label="Enable all in app notifications"
                  @input="(e) => onBulkCheckNotif(e, inApp)"
                />
              </div>
            </div>
            <BittsDivider class="m-0" />
            <BittsLoading
              :is-loading="loading"
              :class="{ 'h-[600px]': loading }"
            >
              <div class="pl-24">
                <div
                  v-for="group in NOTIF_GROUPS"
                  :key="group.type"
                  class="flex flex-col items-stretch"
                >
                  <div class="flex items-center mb-16 mt-24">
                    <FontAwesomeIcon
                      :icon="['far', group.icon]"
                      :style="{
                        height: '16px',
                        width: '16px',
                        color: 'currentColor',
                      }"
                      class="mr-8 text-info-accent"
                    />
                    <div
                      class="text-info-text font-bold text-m"
                      :data-testid="`notification-group-${group.type}`"
                    >
                      {{ `${group.type} Notifications` }}
                    </div>
                  </div>
                  <div
                    v-for="(notif, index) in group.notifs"
                    :key="`${group.type}__${index}`"
                    class="flex flex-col items-stretch"
                    :data-testid="`notification-${notif.title.toLowerCase().split(' ').join('-')}`"
                  >
                    <span>
                      <div class="user-settings__notifications-notification">
                        <div class="flex flex-col items-start">
                          <div class="text-neutral-text-strong font-bold mb-4">
                            {{ notif.title }}
                          </div>
                          <div
                            class="text-neutral-text text-sm"
                            :data-testid="`notification-${notif.title.toLowerCase().split(' ').join('-')}-desc`"
                          >
                            {{ updatedNotifDesc(notif) }}
                          </div>
                        </div>
                        <div class="user-settings__notifications-checkboxes">
                          <BittsCheckbox
                            :default-checked="
                              isSettingEnabled(notif.eventType, email)
                            "
                            :checked="isSettingEnabled(notif.eventType, email)"
                            label="Email"
                            class="user-settings__email-checkbox"
                            @input="
                              onNotificationChanged(
                                $event,
                                notif.eventType,
                                email,
                              )
                            "
                          />
                          <BittsCheckbox
                            :class="{
                              invisible: eventTypesNoInApp.includes(
                                notif.eventType,
                              ),
                            }"
                            :default-checked="
                              isSettingEnabled(notif.eventType, inApp)
                            "
                            :checked="isSettingEnabled(notif.eventType, inApp)"
                            label="In App"
                            @input="
                              onNotificationChanged(
                                $event,
                                notif.eventType,
                                inApp,
                              )
                            "
                          />
                        </div>
                      </div>
                    </span>
                    <BittsDivider class="m-0 mb-16 self-stretch" />
                  </div>
                </div>
                <div class="flex justify-between items-center mb-16">
                  <div
                    :class="{
                      invisible: !hasUnsavedChanges,
                    }"
                    class="text-neutral-text-weak italic"
                  >
                    You have unsaved changes
                  </div>
                  <BittsButton
                    size="medium"
                    text="Save Changes"
                    class="mr-24"
                    @click="onSaveSettings"
                  />
                </div>
              </div>
            </BittsLoading>
          </BittsContainer>
        </div>
        <router-view-wrapper />
        <!-- User Info modal -->
      </template>
    </BittsLayout>
  </BittsLayout>
</template>

<script>
import {
  BittsButton,
  BittsCheckbox,
  BittsContainer,
  BittsDivider,
  BittsLayout,
  BittsLoading,
} from '@crossbeam/bitts';

import { useHead } from '@unhead/vue';
import { mapActions, mapState } from 'pinia';

import PageTitle from '@/components/PageTitle.vue';
import ConfigureUserSettings from '@/components/user/ConfigureUserSettings.vue';

import { useFlashesStore, useNotificationsStore } from '@/stores';

const ALL = 'ALL';
const SOME = 'SOME';
const NONE = 'NONE';

const EMAIL = 'email';
const IN_APP = 'application';

const EVENT_TYPES_NO_EMAIL = [];
const EVENT_TYPES_NO_IN_APP = ['proposal_reminder'];

const CONFIGURABLE_EVENT_TYPES_APP = [
  'connection_check_failed',
  'feed_processed',
  'push_connection_error',
  'data_shared',
  'share_request',
  'report_population_removed',
  'population_visible',
  'proposal_accepted',
  'list_sharing_updated',
  'list_members_updated',
  'list_note_updated',
  'invite_request_access',
];
const CONFIGURABLE_EVENT_TYPES_EMAIL = [
  ...CONFIGURABLE_EVENT_TYPES_APP,
  ...EVENT_TYPES_NO_IN_APP,
];

const NOTIF_GROUPS = [
  {
    type: 'Company',
    icon: 'building',
    notifs: [
      {
        eventType: 'connection_check_failed',
        desc: 'A data source connection needs to be fixed',
        title: 'Data Connection Error',
      },
      {
        eventType: 'feed_processed',
        desc: 'A new data source is ready to be used',
        title: 'Data Ready',
      },
      {
        eventType: 'push_connection_error',
        desc: 'A push connection needs to be fixed',
        title: 'Push Connection Error',
      },
      {
        eventType: 'invite_request_access',
        desc: 'A new user is requesting a seat in your Crossbeam account',
        title: 'Seat Requests',
      },
    ],
  },
  {
    type: 'Data',
    icon: 'chart-pie-simple',
    notifs: [
      {
        eventType: 'data_shared',
        desc: 'A partner shared new data with you',
        title: 'Data Shared',
      },
      {
        eventType: 'share_request',
        desc: 'A partner is requesting data from you',
        title: 'Data Share Request',
      },
      {
        eventType: 'report_population_removed',
        desc: 'A partner has changed their data sharing',
        title: 'Data Sharing Changed',
      },
      {
        eventType: 'population_visible',
        desc: 'A new partner Population is now available to you',
        title: 'Population Revealed',
      },
    ],
  },
  {
    type: 'Partnership',
    icon: 'user-group',
    notifs: [
      {
        eventType: 'proposal_accepted',
        desc: 'A company accepted your partnership request',
        title: 'Partnership Accepted',
      },
      {
        eventType: 'proposal_reminder',
        desc: 'Reminders about pending partnership requests',
        title: 'Partnership Reminder',
      },
      {
        eventType: 'proposal_received',
        desc: 'A company wants to partner with you',
        title: 'Partnership Request',
      },
    ],
  },
  {
    type: 'Collaboration',
    icon: 'message-lines',
    notifs: [
      {
        eventType: 'list_note_updated',
        desc: 'A partner or team member added or updated notes in a Shared List',
        title: 'Notes Updated in Shared List',
      },
      {
        eventType: 'list_sharing_updated',
        desc: 'A partner or team member shared or unshared a List with you',
        title: 'List Sharing',
      },
      {
        eventType: 'list_members_updated',
        desc: 'A partner or team member added new records to a Shared List',
        title: 'New Records Added to List',
      },
    ],
  },
];

export default {
  name: 'UserSettings',
  components: {
    PageTitle,
    BittsButton,
    BittsContainer,
    BittsCheckbox,
    BittsDivider,
    BittsLoading,
    BittsLayout,
    ConfigureUserSettings,
  },
  setup() {
    useHead({
      title: 'Preferences - Crossbeam',
    });
  },
  data() {
    return {
      ALL,
      NONE,
      SOME,
      currentNotificationSettings: [],
      email: EMAIL,
      eventTypesNoInApp: EVENT_TYPES_NO_IN_APP,
      hasUnsavedChanges: false,
      inApp: IN_APP,
      loading: false,
      NOTIF_GROUPS,
      pageLoading: false,
    };
  },
  computed: {
    ...mapState(useNotificationsStore, ['settings']),
    areAnyEmailNotificationsEnabled() {
      return this.areAnyNotificationsEnabled(
        EMAIL,
        CONFIGURABLE_EVENT_TYPES_EMAIL,
      );
    },
    areAnyInAppNotificationsEnabled() {
      return this.areAnyNotificationsEnabled(
        IN_APP,
        CONFIGURABLE_EVENT_TYPES_APP,
      );
    },
    enabledNotifications() {
      return this.currentNotificationSettings;
    },
  },
  async created() {
    this.loading = true;
    await this.refresh();
    this.loading = false;
  },
  methods: {
    ...mapActions(useFlashesStore, ['addSuccessFlash', 'addErrorFlash']),
    ...mapActions(useNotificationsStore, [
      'bulkUpdateSettings',
      'refreshNotificationsStore',
    ]),
    areAnyNotificationsEnabled(notifType, allNotifs) {
      let enabledNotifsCount = 0;
      let anyEnabled = false;
      this.enabledNotifications.forEach((notif) => {
        if (
          notif.targets &&
          allNotifs.includes(notif.event_type) &&
          notif.targets.includes(notifType)
        ) {
          anyEnabled = true;
          enabledNotifsCount++;
        }
      });
      if (enabledNotifsCount === allNotifs.length) return ALL;
      return anyEnabled ? SOME : NONE;
    },
    disableAllNotifications(notifType) {
      this.enabledNotifications.forEach((notif) => {
        this.onNotificationChanged(false, notif.event_type, notifType);
      });
    },
    enableAllNotifications(notifType) {
      let typesWithNoNotifications;
      switch (notifType) {
        case IN_APP:
          typesWithNoNotifications = EVENT_TYPES_NO_IN_APP;
          break;
        case EMAIL:
          typesWithNoNotifications = EVENT_TYPES_NO_EMAIL;
          break;
      }
      this.enabledNotifications.forEach((notif) => {
        if (
          !notif.targets.includes(notifType) &&
          !typesWithNoNotifications.includes(notif.event_type)
        ) {
          this.onNotificationChanged(true, notif.event_type, notifType);
        }
      });
    },
    isSettingEnabled(eventType, notifType) {
      const currentSetting = this.enabledNotifications.find(
        (setting) => setting.event_type === eventType,
      );
      if (currentSetting) {
        return (
          currentSetting.targets && currentSetting.targets.includes(notifType)
        );
      }
    },
    onNotificationChanged(checked, eventType, notifType) {
      const changedSetting = this.enabledNotifications.find(
        (setting) => setting.event_type === eventType,
      );
      if (checked) {
        changedSetting.targets.push(notifType);
      } else {
        changedSetting.targets = changedSetting.targets.filter(
          (target) => target !== notifType,
        );
      }
      if (eventType === 'report_population_removed') {
        // (TEMPORARY!) For 'Data Sharing Stopped', update the new and old event types.
        // The feature flag setting will determine which one they'll see over in the notifications views.
        // When feature flag is removed, we can just replace the old event type.
        const changedStopSharingSetting = this.enabledNotifications.find(
          (setting) =>
            setting.event_type === 'report_population_stopped_sharing',
        );

        if (checked) {
          changedStopSharingSetting.targets.push(notifType);
        } else {
          changedStopSharingSetting.targets =
            changedStopSharingSetting.targets.filter(
              (target) => target !== notifType,
            );
        }
      }
      this.hasUnsavedChanges = true;
    },
    async onSaveSettings() {
      this.loading = true;
      try {
        const items = this.enabledNotifications;
        const payload = { items };
        await this.bulkUpdateSettings(payload);
        this.addSuccessFlash({ message: 'Notification settings updated.' });
        await this.refresh();
      } catch (_err) {
        this.addErrorFlash({
          message: 'Something went wrong',
          description: 'Notifications settings not updated.',
        });
      } finally {
        this.loading = false;
      }
    },
    onBulkCheckNotif(checked, notifType) {
      if (checked) {
        this.enableAllNotifications(notifType);
      } else {
        this.disableAllNotifications(notifType);
      }
    },
    async refresh() {
      await this.refreshNotificationsStore();
      this.currentNotificationSettings = [...this.settings];
      this.hasUnsavedChanges = false;
    },
    updatedNotifDesc(notif) {
      return notif.eventType === 'data_shared'
        ? 'A partner shared new overlaps or Greenfield data with you'
        : notif.desc;
    },
  },
};
</script>

<style scoped lang="pcss">
.user-settings__bulk-notif {
  @apply flex items-center bg-neutral-background-weak p-16 rounded-bts-md;
}
.user-settings__bulk-notif-icon {
  @apply p-10 bg-neutral-background-disabled rounded-bts-md mr-16;
}
.user-settings__bulk-notif-text {
  @apply flex flex-col;
}
.user-settings__bulk-notif-title {
  @apply text-neutral-text-strong font-bold;
}
.user-settings__bulk-notif-subtext {
  @apply text-neutral-text text-sm;
}
.user-settings__notifications-checkboxes {
  @apply grid items-center;
  grid-template-columns: 3fr 2.5fr;
}
.user-settings__notifications-header {
  @apply p-24 pr-0;
}
.user-settings__notifications-notification {
  @apply mb-16;
}
.user-settings__notifications-notification,
.user-settings__notifications-header {
  @apply grid items-center justify-between mr-24 grid-cols-2;
}
</style>

<style lang="pcss">
.bitts-container.user-settings__notifications {
  @apply p-0;
}
.user-settings__email-checkbox {
  .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 20%;
  }
}
.user-settings__notifications {
  @apply w-full shadow-component;
  .bitts-card__title {
    @apply text-lg text-neutral-text-strong font-bold;
  }
  .c-bitts-divider {
    @apply m-0;
  }
}
</style>
